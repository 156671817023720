import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarPickingAndPacking from '../NavbarPickingAndPacking/NavbarPickingAndPacking';
import './InventarioBodega.css';
import { API_ROUTES } from '../../constants/apiRoutes';

const InventarioBodega = () => {
  const [inventario, setInventario] = useState([]);
  const [variaciones, setVariaciones] = useState({});
  const [nombreBodega, setNombreBodega] = useState('');
  const navigate = useNavigate();
  const { idSucursal, idBodega } = useParams();

  const fetchInventario = async () => {
    try {
      console.log(idSucursal);
      const response = await axios.get(API_ROUTES.INVENTARIO_BODEGA(idSucursal, idBodega));
      setInventario(response.data.inventarios);
      setVariaciones(response.data.variaciones);
      
      if (response.data.nombre_bodega) {
        setNombreBodega(response.data.nombre_bodega);
      }
    } catch (error) {
      console.error('Error al obtener el inventario:', error);
      Swal.fire('Error', 'No se pudo cargar el inventario', 'error');
    }
  };

  useEffect(() => {
    if (idSucursal, idBodega) {
      fetchInventario();
    }
  }, [idSucursal, idBodega]);

  return (
    <>
      <NavbarPickingAndPacking title="Gestión de Inventario de la Bodega" />
      <div className="inventario-bodega-container centered-container" style={{ marginLeft: '20%' }}>
        <h2 style={{ textAlign: 'center' }}>Inventario de la Bodega "{nombreBodega}"</h2>
        <button onClick={() => navigate(`/inventario/crear/${idSucursal}`)} className="add-button">
          Agregar Nuevo Producto
        </button>
        <table className="inventario-table" style={{ marginTop: '20px' }}>
          <thead>
            <tr>
              <th>ID Producto</th>
              <th>Producto</th>
              <th>Cantidad Disponible</th>
              <th>Precio Proveedor</th>
              <th>Precio Sugerido</th>
              <th>Variaciones</th> {/* Nueva columna para variaciones */}
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {inventario.length > 0 ? (
              inventario.map((producto) => {
                // Obtiene las variaciones correspondientes al producto
                // Suponiendo que `variaciones` es un array de objetos con la propiedad `id_producto`
                const productoVariaciones = variaciones.filter(variacion => variacion.id_producto === producto.id_producto);
                return (
                  <tr key={producto.id_producto}>
                    <td>{producto.id_producto}</td>
                    <td>{producto.nombre_producto}</td>
                    <td>{producto.cantidad_disponible}</td>
                    <td>{producto.precio_proveedor}</td>
                    <td>{producto.precio_sugerido}</td>
                    <td>
                        {productoVariaciones.length > 0 ? (
                            productoVariaciones.map((variacion, index) => (
                            <div key={variacion.id} style={{ borderBottom: index < productoVariaciones.length - 1 ? '1px solid #ccc' : 'none', padding: '5px 0' }}>
                                {`Codigo SKU: ${variacion.sku}, Talla: ${variacion.talla}`}
                                <br/>
                                {`Color: ${variacion.color}, Cantidad Disponible: ${variacion.cantidad_disponible}`}
                            </div>
                            ))
                        ) : (
                            'Sin variaciones'
                        )}
                    </td>
                    <td>
                      <button
                        onClick={() => navigate(`/inventario/editar/${producto.id_producto}`)}
                        className="edit-button"
                      >
                        Actualizar
                      </button>
                      <button
                        onClick={() => navigate(`/inventario/detalles/${producto.id_producto}`)}
                        className="details-button"
                      >
                        Detalles
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: 'center' }}>
                  No hay productos disponibles en esta bodega.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InventarioBodega;
