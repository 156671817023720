import React, { useState, useEffect } from 'react';
import NavbarMex from '../components/navbarMex';
import { useParams } from 'react-router-dom';
import sampleFile from '../Masivo/envio_masivo.xlsx';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { message, Spin } from 'antd';
import './EnvioMasivo.css';
import useFileUpload from './excel';

Modal.setAppElement('#root');

const EnvioMasivo = () => {
  const { id } = useParams(); 
  const [archivo, setArchivo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cotizacionModalIsOpen, setCotizacionModalIsOpen] = useState(false);
  const [cotizacion, setCotizacion] = useState(null);
  const [worksheet, setWorksheet] = useState(null);
  const [selectedTransportadoras, setSelectedTransportadoras] = useState({});
  const [pdfUrl, setPdfUrl] = useState(null);
  const [sucursalId, setSucursalId] = useState(null);
  const { uploadFile, loading, error, success } = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const [archivoNombre, setArchivoNombre] = useState('');

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (file && allowedTypes.includes(file.type)) {
      setArchivo(file);
      setArchivoNombre(file.name); // Actualizar el nombre del archivo
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const ws = workbook.Sheets[sheetName];
        setWorksheet(ws);
      };
      reader.readAsArrayBuffer(file);
    } else {
      message.error('Por favor seleccione un archivo Excel válido.');
    }
  };


  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenCotizacionModal = () => {
    setCotizacionModalIsOpen(true);
  };

  const handleCloseCotizacionModal = () => {
    setCotizacionModalIsOpen(false);
  };

  const handleSubmit = async () => {
    if (!archivo) {
      message.error('Por favor seleccione un archivo Excel.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('archivo', archivo);

    try {
      setSubmitting(true);
      const response = await uploadFile(formDataToSend);
      setCotizacion(response);
      setModalIsOpen(false);
      handleOpenCotizacionModal();
      message.success('Archivo cargado con éxito');
    } catch (err) {
      message.error(`Error: ${err.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleTransportadoraSelect = (cotizacionKey, transportadoraKey) => {
    setSelectedTransportadoras((prevSelected) => ({
      ...prevSelected,
      [cotizacionKey]: transportadoraKey,
    }));
  };

  useEffect(() => { 
    const fetchSucursalId = async () => {
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setSucursalId(data); // Actualiza el estado con el ID de la sucursal
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
        return null;
      }
    };
  
    fetchSucursalId();
  }, [id]);

  const handleConfirmSelection = async () => {
    const transportadoraCol = 'Q';
    Object.keys(selectedTransportadoras).forEach((cotizacionKey, index) => {
      const rowIndex = index + 1;
      const transportadoraCellAddress = { c: XLSX.utils.decode_col(transportadoraCol), r: rowIndex };
      const transportadoraCellRef = XLSX.utils.encode_cell(transportadoraCellAddress);
      worksheet[transportadoraCellRef] = { v: selectedTransportadoras[cotizacionKey], t: 's' };
    });

    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
    const newFile = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([newFile], { type: 'application/octet-stream' });

    const formDataToSend = new FormData();
    formDataToSend.append('archivo', blob, 'archivo_modificado.xlsx');

    try {
      setSubmitting(true);
      const response = await fetch(`https://integration.99envios.app/api/sucursal/importar_preenvio/${sucursalId}`, {
        method: 'POST',
        body: formDataToSend,
      });
      const data = await response.json();
      message.success('Archivo enviado con éxito');
      console.log('Success:', data);
      handleGeneratePDF(data);
    } catch (error) {
      message.error('Error al enviar el archivo');
      console.error('Error:', error);
    } finally {
      setSubmitting(false);
    }

    setCotizacionModalIsOpen(false);
  };

  const handleGeneratePDF = async (data) => {
    const preenvios = Object.keys(data).map(key => data[key].original.numeroPreenvio).filter(numeroPreenvio => numeroPreenvio);

    for (let i = 0; i < preenvios.length; i++) {
      const numeroPreenvio = preenvios[i];
      const transportadoraKey = selectedTransportadoras[Object.keys(cotizacion)[i]];
      const aplicaContrapago = worksheet[XLSX.utils.encode_cell({ c: 0, r: i + 1 })].v; // Columna A, fila i+1
      const AplicaContrapago = aplicaContrapago === "V" ? true : false;

      const pdfData = {
        transportadora: {
          pais: "colombia",
          nombre: transportadoraKey
        },
        guia: numeroPreenvio,
        AplicaContrapago: true,
        origenCreacion: 1,
      };

      try {
        setSubmitting(true);
        const pdfResponse = await fetch(`https://integration.99envios.app/api/sucursal/pdf/${sucursalId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(pdfData)
        });

        if (!pdfResponse.ok) {
          const pdfErrorData = await pdfResponse.json();
          const pdfErrorMessage = pdfErrorData.mensaje_completo ? pdfErrorData.mensaje_completo.join(' ') : pdfErrorData.mensaje;
          message.error(`Error al generar PDF: ${pdfErrorMessage}`);
          throw new Error('Error en la respuesta del servidor al generar PDF');
        }

        let base64String = await pdfResponse.text();  // Obtener la respuesta como texto directamente

        // Procesamiento de la cadena base64 eliminando espacios y ajustando caracteres
        const processedBase64String = base64String.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');

        if (processedBase64String) {
          try {
            const binaryString = window.atob(processedBase64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

            const blobUrl = URL.createObjectURL(pdfBlob);
            window.open(blobUrl);
          } catch (e) {
            console.error(e);
            message.error('Error al decodificar la cadena base64 del PDF');
            throw new Error('Error al decodificar la cadena base64 del PDF');
          }
        } else {
          message.error('No se pudo extraer la cadena base64 del PDF');
          throw new Error('No se pudo extraer la cadena base64 del PDF');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    }
  }, [pdfUrl]);

  return (
    <>
      <NavbarMex title="Envío Masivo" />
      <form
        className="envio-masivo"
        onSubmit={(e) => {
          e.preventDefault();
          handleOpenModal();
        }}
      >
        <h2>Envío Masivo</h2>
        <label>
          Archivo Excel:
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            required
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading || submitting ? <Spin /> : 'Cargar y Editar'}
        </button>
        <a href={sampleFile} download>
          <button style={{ marginTop: '1%', backgroundColor: "green" }} type="button">
            Descargar plantilla
          </button>
        </a>
        {error && <p className="error">Error: {error}</p>}
        {success && <p className="success">Archivo cargado con éxito</p>}
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Confirmar Envío"
        className="envio-masivo-modal-content"
        overlayClassName="envio-masivo-modal-overlay"
      >
        <div className="envio-masivo-modal-header">
          <h2>Confirmar Envío</h2>
        </div>
        <div className="envio-masivo-modal-body">
          <p>¿Está seguro que desea enviar el archivo <strong>{archivoNombre}</strong>?</p>
        </div>
        <div className="envio-masivo-modal-buttons">
          <button onClick={handleSubmit} disabled={submitting}>
            {submitting ? <Spin /> : 'Confirmar y Enviar'}
          </button>
          <button onClick={handleCloseModal} className="envio-masivo-close-button">Cerrar</button>
        </div>
      </Modal>


      {cotizacion && (
        <Modal
          isOpen={cotizacionModalIsOpen}
          onRequestClose={handleCloseCotizacionModal}
          contentLabel="Seleccionar Transportadora"
          className="envio-masivo-modal-content"
          overlayClassName="envio-masivo-modal-overlay"
        >
          <div className="envio-masivo-modal-header">
            <h2>Seleccionar Transportadora</h2>
          </div>
          <div className="envio-masivo-modal-body horizontal-scroll">
            <table className="envio-masivo-table">
              <thead>
                <tr>
                  <th>Envío</th>
                  {Object.keys(cotizacion[Object.keys(cotizacion)[0]]).map((transportadoraKey, index) => (
                    <th key={index}>{transportadoraKey}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(cotizacion).map((cotizacionKey, index) => (
                  <tr key={index}>
                    <td>{cotizacionKey}</td>
                    {Object.keys(cotizacion[cotizacionKey]).map((transportadoraKey) => {
                      const transportadora = cotizacion[cotizacionKey][transportadoraKey];
                      return (
                        <td
                          key={transportadoraKey}
                          className={`envio-masivo-transportadora-container ${selectedTransportadoras[cotizacionKey] === transportadoraKey ? 'selected' : ''}`}
                        >
                          <p>Valor: {transportadora.valor}</p>
                          <button onClick={() => handleTransportadoraSelect(cotizacionKey, transportadoraKey)}>
                            Seleccionar
                          </button>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="envio-masivo-modal-buttons" style={{ marginTop: "2%" }}>
            <button onClick={handleConfirmSelection} disabled={submitting}>
              {submitting ? <Spin /> : 'Confirmar Selección'}
            </button>
            <button onClick={handleCloseCotizacionModal} className="envio-masivo-close-button">Cerrar</button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EnvioMasivo;
