export const BASE_URL = 'https://api.99envios.app/api/';
export const BASE_URL_DEVELOP = 'http://127.0.0.1:8000/api/';
//export const BASE_URL_INTEGRATION  = 'https://integration.99envios.app/api/';
export const BASE_URL_INTEGRATION  = 'https://integration.99envios.top/api/';

export const API_ROUTES = {
  /* Routes Shopify  */
  VERIFY_SUCURSAL_SHOPIFY: (userId) => `${BASE_URL_DEVELOP}integration/shopify/v1/verificar-sucursal/${userId}`,
  INTEGRATION_SHOPIFY: (sucursalId) => `${BASE_URL_DEVELOP}integration/shopify/v1/integration/${sucursalId}`,
  SYNC_PRODUCT_SHOPIFY: `${BASE_URL_DEVELOP}integration/shopify/v1/sincronizarProducto`,
  GET_PRODUCT_SHOPIFY: (sucursalId) => `${BASE_URL_DEVELOP}integration/shopify/v1/productos/${sucursalId}`,

  /* Routes Inventrio  */
  //INVENTARIOS: (userId) => `${BASE_URL}inventarios/${userId}`,
  INVENTARIOS: (userId) => `${BASE_URL_DEVELOP}integration/shopify/v1/Productos`, 

/*src/constants/apiRoutes.js*/
//export const BASE_URL = 'https://api.99envios.app/api/';



  /* Routes Picking And Packing  */
  VERIFY_SUCURSAL: (userId) => `${BASE_URL}picking-and-packing/suscriptores/verificar-sucursal/${userId}`,
  BODEGA_SUCURSAL: (idSucursal) => `${BASE_URL}picking-and-packing/bodegas/sucursal/${idSucursal}`,
  GET_BODEGA_SUCURSAL: (idSucursal) => `${BASE_URL}picking-and-packing/bodegas/bodegas-sucursal/${idSucursal}`,
  REGISTER_BODEGA_SUCURSAL: `${BASE_URL}picking-and-packing/bodegas/registrar-sucursal`,
  BODEGA: (idBodega) => `${BASE_URL}picking-and-packing/bodegas/${idBodega}`,
  GET_BODEGA: `${BASE_URL}picking-and-packing/bodegas`,
  CREATE_BODEGA: `${BASE_URL}picking-and-packing/bodegas`,
  UPDATE_BODEGA: (idBodega) => `${BASE_URL}picking-and-packing/bodegas/${idBodega}`,
  INVENTARIO_BODEGA: (idSucursal, idBodega) => `${BASE_URL}picking-and-packing/bodegas/inventario-bodega/${idSucursal}/${idBodega}`,
  // Obtener una devolución específica
  DEVOLUCION: (id) => `${BASE_URL}/devoluciones/${id}`,
  CREATE_DEVOLUCION: `${BASE_URL}/devoluciones`,
  UPDATE_DEVOLUCION: (id) => `${BASE_URL}/devoluciones/${id}`,
  LIST_DEVOLUCIONES: `${BASE_URL}/devoluciones`,
  DELETE_DEVOLUCION: (id) => `${BASE_URL}/devoluciones/${id}`,
};
