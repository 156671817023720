import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Select, Row, Col, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import NavbarMex from '../components/navbarMex';
import './PanelUsuario.css';

const PanelUsuario = () => {
  const [form] = Form.useForm();
  const [logoUrl, setLogoUrl] = useState(null);

  // Esta función se ejecuta cuando el componente se monta y carga los datos desde el localStorage
  useEffect(() => {
    const usuarioId = localStorage.getItem('usuario_id');
    const email = localStorage.getItem('email');
    const rolId = localStorage.getItem('rol_id');

    // Pre-cargar el formulario con los datos del localStorage
    form.setFieldsValue({
      nombre: `Usuario ${usuarioId}`,  // Puedes personalizar este valor
      email: email,
      rol: rolId,
    });
  }, [form]);

  const handleFinish = (values) => {
    console.log('Formulario enviado: ', values);
    message.success('Información guardada exitosamente');
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Error: ', errorInfo);
    message.error('Error al guardar la información');
  };

  const handleLogoChange = (info) => {
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoUrl(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  return (
    <>
      <NavbarMex />
      <div className="panel-usuario-wrapper">
        <h2 className="panel-usuario-title">Registro de Usuario</h2>
        <div className="panel-usuario-logo-row">
          <Upload
            showUploadList={false}
            onChange={handleLogoChange}
            beforeUpload={() => false} // Para evitar la subida automática
          >
            <div className="panel-usuario-logo-upload">
              {logoUrl ? (
                <img src={logoUrl} alt="Logo" />
              ) : (
                <UploadOutlined style={{ fontSize: '32px', color: '#999' }} />
              )}
            </div>
          </Upload>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          className="panel-usuario-form"
        >
          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="nombre"
                label={<span className="panel-usuario-label">Nombre</span>}
                rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
              >
                <Input className="panel-usuario-input" placeholder="Nombre" />
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="apellido"
                label={<span className="panel-usuario-label">Apellido</span>}
                rules={[{ required: true, message: 'Por favor ingrese su apellido' }]}
              >
                <Input className="panel-usuario-input" placeholder="Apellido" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="email"
                label={<span className="panel-usuario-label">Email de Contacto</span>}
                rules={[{ required: true, type: 'email', message: 'Por favor ingrese un correo válido' }]}
              >
                <Input className="panel-usuario-input" placeholder="Correo Electrónico" />
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="telefono"
                label={<span className="panel-usuario-label">Teléfono</span>}
                rules={[{ required: true, message: 'Por favor ingrese su número de teléfono' }]}
              >
                <Input
                  addonBefore={
                    <Select defaultValue="57">
                      <Select.Option value="57">+57</Select.Option>
                      <Select.Option value="1">+1</Select.Option>
                    </Select>
                  }
                  className="panel-usuario-input"
                  placeholder="Número de teléfono"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="direccion"
            label={<span className="panel-usuario-label">Dirección</span>}
            rules={[{ required: true, message: 'Por favor ingrese su dirección' }]}
          >
            <Input.TextArea className="panel-usuario-textarea" placeholder="Dirección" />
          </Form.Item>

          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="cedulaEscaneada"
                label={<span className="panel-usuario-label">Cédula Escaneada</span>}
              >
                <Upload>
                  <Button icon={<UploadOutlined />} className="panel-usuario-upload-button">
                    Seleccionar archivo
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="rut"
                label={<span className="panel-usuario-label">RUT (Opcional)</span>}
              >
                <Upload>
                  <Button icon={<UploadOutlined />} className="panel-usuario-upload-button">
                    Seleccionar archivo
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <div className="panel-usuario-button-section">
            <Button type="primary" htmlType="submit" className="panel-usuario-submit-button">
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PanelUsuario;
