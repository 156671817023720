import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import NavbarMex from '../components/navbarMex';
import { message, Select } from 'antd';
import { useParams } from 'react-router-dom'; // Importa useParams
import './ShippingForm.css';
import { set } from 'date-fns';

const { Option } = Select;

const ShippingForm = () => {
  const { id } = useParams(); // Obtiene el id desde la URL
  const [cotizacionResult, setCotizacionResult] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [volumetricWeight, setVolumetricWeight] = useState(0);
  const [finalWeight, setFinalWeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTransportadora, setSelectedTransportadora] = useState(null);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [sucursalId, setSucursalId] = useState(null);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [formattedValue, setFormattedValue] = useState('');

  const [formDataGuia, setFormDataGuia] = useState({
    fecha: '',
    AplicaContrapago: false,
    Peso: '',
    Largo: '',
    Ancho: '',
    Alto: '',
    DiceContener: '',
    ValorDeclarado: '',
    tipoDocumento: '',
    numeroDocumento: '',
    nombre: '',
    primerApellido: '',
    segundoApellido: '',
    telefono: '',
    direccion: '',
    correo: '',
    idLocalidad: '',
    Observaciones: ''
  });

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    if (name === 'DiceContener') {
      setFormDataGuia({
        ...formDataGuia,
        [name]: updatedValue,
        Observaciones: updatedValue // Actualiza también Observaciones
      });
    } else {
      setFormDataGuia({
        ...formDataGuia,
        [name]: updatedValue,
      });
    }

    if (name === 'Peso' || name === 'ValorDeclarado') {
      const peso = name === 'Peso' ? parseFloat(updatedValue) : parseFloat(formDataGuia.Peso);
      const valorDeclarado = name === 'ValorDeclarado' ? parseFloat(updatedValue) : parseFloat(formDataGuia.ValorDeclarado);

      let valorDeclaradoMinimo;
      if (peso >= 1 && peso <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (peso >= 3 && peso <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (peso >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        setErrorMessage(`El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${peso} kg.`);
      } else {
        setErrorMessage('');
      }
    }
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  useEffect(() => {
    setFormData({
      destinationCity: '',
      declaredValue: '50000',
      deliveryType: 'direccion',
      shipmentType: 'paquete',
      grossWeight: '1',
      length: '10',
      width: '10',
      height: '10',
      contentDescription: '',
      observations: '',
      cashOnDelivery: false,
    });
  }, []);

  const [formData, setFormData] = useState({
    destinationCity: '',
    declaredValue: '50000',
    deliveryType: '',
    shipmentType: '',
    grossWeight: '',
    length: '',
    width: '',
    height: '',
    contentDescription: '',
    observations: '',
    cashOnDelivery: false,
  });

  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, ""));
  };

  const [formattedDeclaredValue, setFormattedDeclaredValue] = useState('');

  useEffect(() => {
    // Formatear el valor declarado inicial
    setFormattedDeclaredValue(formatCurrency(formData.declaredValue));
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'declaredValue') {
      // Eliminar todos los caracteres no numEricos
      const numericValue = value.replace(/[^0-9]/g, '');

      // Actualizar el estado con el valor numErico
      setFormData(prevState => ({
        ...prevState,
        [name]: numericValue ? parseInt(numericValue, 10) : 0
      }));

      // Actualizar el valor formateado
      setFormattedDeclaredValue(formatCurrency(numericValue ? parseInt(numericValue, 10) : 0));
    } else {
      // Para otros campos, mantener la lOgica existente
      const updatedValue = type === 'checkbox' ? checked : value;
      setFormData(prevState => ({
        ...prevState,
        [name]: updatedValue
      }));
    }

    if (name === 'deliveryType') {
      setFormDataGuia(prevState => ({
        ...prevState,
        numeroDocumento: ''
      }));
    }
  };

  useEffect(() => {
    if (formData.declaredValue) {
      const numValue = parseInt(formData.declaredValue, 10);
      if (!isNaN(numValue)) {
        const formattedBalance = numValue.toLocaleString('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        setFormattedValue(formattedBalance);
      } else {
        setFormattedValue('');
      }
    } else {
      setFormattedValue('');
    }
  }, [formData.declaredValue]);



  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'declaredValue') {
      const parsedValue = parseCurrency(value);
      setFormData({
        ...formData,
        declaredValue: parsedValue,
        declaredValueFormatted: formatCurrency(parsedValue)
      });
    }
  };

  useEffect(() => {
    const largo = parseFloat(formData.length) || 0;
    const ancho = parseFloat(formData.width) || 0;
    const alto = parseFloat(formData.height) || 0;
    const pesoVolumetrico = (largo * ancho * alto) / 6000;
    setVolumetricWeight(pesoVolumetrico.toFixed(2));
    console.log('Peso volumEtrico:', pesoVolumetrico);
  }, [formData.length, formData.width, formData.height]);

  useEffect(() => {
    const peso = parseFloat(formData.grossWeight);
    const PesoVolumetrico = parseFloat(volumetricWeight);
    const valorDeclarado = parseFloat(formData.declaredValue);
    let valorDeclaradoMinimo;

    if (peso > PesoVolumetrico) {
      if (peso >= 1 && peso <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (peso >= 3 && peso <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (peso >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        const errorMsg = `El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${peso} kg.`;
        setErrorMessage(errorMsg);

      } else {
        setErrorMessage('');
      }
    } else {
      if (PesoVolumetrico >= 1 && PesoVolumetrico <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (PesoVolumetrico >= 3 && PesoVolumetrico <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (PesoVolumetrico >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        const errorMsg = `El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${PesoVolumetrico} kg.`;
        setErrorMessage(errorMsg);

      } else {
        setErrorMessage('');
      }
    }
  }, [formData.grossWeight, formData.declaredValue, volumetricWeight, attemptedSubmit]);

  const pesoBruto = parseFloat(formDataGuia.Peso);
  const pesoVolumetrico = parseFloat(volumetricWeight);
  const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;
  console.log('Peso final:', pesoFinal);
  console.log('PesoVolumetrico:', pesoVolumetrico);

  const handleGuiaSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'nombre',
      'primerApellido',
      'telefono',
      'direccion',
      'correo',
      'DiceContener'
    ];

    // Añadir numeroDocumento a los campos requeridos solo si es CENTRO DE ACOPIO
    if (formData.deliveryType === 'centroAcopio') {
      requiredFields.push('numeroDocumento');
    }

    const missingFields = requiredFields.filter(field => !formDataGuia[field]);

    if (missingFields.length > 0) {
      message.error(`Los siguientes campos son obligatorios: ${missingFields.join(', ')}`);
      return;
    }

    const idLocalidad = formDataGuia.idLocalidad;
    if (!idLocalidad) {
      alert('El valor de ciudad no es válido');
      return;
    }

    const date = new Date(formDataGuia.fecha);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

    const guiaData = {
      IdTipoEntrega: formData.deliveryType === 'direccion' ? 1 : 2,
      IdServicio: 1,
      AplicaContrapago: formDataGuia.AplicaContrapago || false,
      peso: pesoFinal,
      largo: parseFloat(formDataGuia.Largo) || 1,
      ancho: parseFloat(formDataGuia.Ancho) || 1,
      alto: parseFloat(formDataGuia.Alto) || 1,
      diceContener: formDataGuia.DiceContener ? formDataGuia.DiceContener.toString() : " ",
      valorDeclarado: parseFloat(formDataGuia.ValorDeclarado) || 50000,
      fecha: formattedDate,
      Destinatario: {
        tipoDocumento: formDataGuia.tipoDocumento ? formDataGuia.tipoDocumento.toString() : "CC",
        numeroDocumento: formDataGuia.numeroDocumento ? formDataGuia.numeroDocumento.toString() : " ",
        nombre: formDataGuia.nombre ? formDataGuia.nombre.toString() : "  ",
        primerApellido: formDataGuia.primerApellido ? formDataGuia.primerApellido.toString() : " ",
        segundoApellido: formDataGuia.segundoApellido ? formDataGuia.segundoApellido.toString() : " ",
        telefono: formDataGuia.telefono ? formDataGuia.telefono.toString() : " ",
        direccion: formDataGuia.direccion ? formDataGuia.direccion.toString() : " ",
        correo: formDataGuia.correo ? formDataGuia.correo.toString() : " ",
        idLocalidad: formDataGuia.idLocalidad ? formDataGuia.idLocalidad.toString() : " "
      },
      Observaciones: formDataGuia.DiceContener ? formDataGuia.DiceContener.toString() : " ",
      transportadora: {
        pais: "colombia",
        nombre: selectedTransportadora,
        aplicaContrapago: formDataGuia.AplicaContrapago
      },
      origenCreacion: 1,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/preenvio/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify(guiaData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.mensaje_completo ? errorData.mensaje_completo.join(' ') : errorData.mensaje;
        alert(`Error: ${errorMessage}`);
        throw new Error('Error en la respuesta del servidor');
      }

      const data = await response.json();
      console.log(data);

      const numeroPreenvio = data.numeroPreenvio.toString();

      const pdfData = {
        transportadora: {
          pais: "colombia",
          nombre: selectedTransportadora
        },
        guia: numeroPreenvio,
        AplicaContrapago: formDataGuia.AplicaContrapago,
        origenCreacion: 1,
      };


      const pdfResponse = await fetch(`https://integration.99envios.app/api/sucursal/pdf/${sucursalId}`, { // Usa el id desde la URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pdfData)
      });

      if (!pdfResponse.ok) {
        const pdfErrorData = await pdfResponse.json();
        const pdfErrorMessage = pdfErrorData.mensaje_completo ? pdfErrorData.mensaje_completo.join(' ') : pdfErrorData.mensaje;
        alert(`Error al generar PDF: ${pdfErrorMessage}`);
        throw new Error('Error en la respuesta del servidor al generar PDF');
      }

      let base64String = await pdfResponse.text();

      base64String = base64String.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');

      if (base64String) {
        try {
          const binaryString = window.atob(base64String);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

          const blobUrl = URL.createObjectURL(pdfBlob);
          window.open(blobUrl);

          closeModal();
          document.getElementById('myForm').reset();
        } catch (e) {
          console.error(e);
          message.error('Error al decodificar la cadena base64 del PDF');
          throw new Error('Error al decodificar la cadena base64 del PDF');
        }
      } else {
        alert('No se pudo extraer la cadena base64 del PDF');
        throw new Error('No se pudo extraer la cadena base64 del PDF');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setSucursalId(data);
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
        return null;
      }
    };

    fetchSucursalId();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAttemptedSubmit(true);

    const pesoBruto = parseFloat(formData.grossWeight);
    const pesoVolumetrico = parseFloat(volumetricWeight);
    const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;

    if (errorMessage) {
      message.error(errorMessage);
      setLoading(false);
      return;
    }

    const cotizacionData = {
      destino: {
        nombre: "",
        codigo: formData.destinationCity,
      },
      origen: {
        nombre: "",
        codigo: "",
      },
      IdTipoEntrega: formData.deliveryType === 'direccion' ? 1 : 2,
      IdServicio: formData.shipmentType === 'normal' ? 1 : 2,
      peso: pesoFinal,
      largo: parseInt(formData.length, 10),
      ancho: parseInt(formData.width, 10),
      alto: parseInt(formData.height, 10),
      fecha: "28-06-2024",
      AplicaContrapago: formData.cashOnDelivery,
      valorDeclarado: formData.declaredValue,
    };

    try {
      console.log("succursalId", sucursalId);
      const response = await fetch(`https://integration.99envios.app/api/sucursal/cotizar/${sucursalId}`, { // Usa el id desde la URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cotizacionData),
      });

      if (!response.ok) {
        throw new Error('Error en la cotizaciOn');
      }

      const result = await response.json();
      console.log('CotizaciOn:', result);

      setCotizacionResult(result);
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al realizar la cotizaciOn');
    } finally {
      setLoading(false);
    }
  };

  const copyFormDataToGuia = () => {
    setFormDataGuia({
      ...formDataGuia,
      Peso: formData.grossWeight,
      Largo: formData.length,
      Ancho: formData.width,
      Alto: formData.height,
      DiceContener: formData.contentDescription,
      ValorDeclarado: formData.declaredValue,
      AplicaContrapago: formData.cashOnDelivery,
      idLocalidad: formData.destinationCity
    });
  };

  const handleSelectTransportadora = (transportadora) => {
    setSelectedTransportadora(transportadora);
    openModal();
  };

  const openModal = () => {
    const pesoBruto = parseFloat(formData.grossWeight);
    const pesoVolumetrico = parseFloat(volumetricWeight);
    const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;
    setFinalWeight(pesoFinal.toFixed(2));
    copyFormDataToGuia();
    setModalIsOpen(true);
  };

  return (
    <>
      <NavbarMex title="Guias" />
      <div className="shipping-form-main-container">
        <div className="shipping-form-container">
          <form onSubmit={handleSubmit} className="shipping-form" id="myForm">
            <h2>InformaciOn del destino</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="destinationCity">Ciudad destino</label>
                <Select
                  id="destinationCity"
                  name="destinationCity"
                  value={formData.destinationCity}
                  onChange={(value) => handleChange({ target: { name: 'destinationCity', value } })}
                  style={{
                    width: '100%',
                    padding: '3px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    fontSize: '14px',
                  }}
                  required
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <option value="" disabled>
                    Seleccione una ciudad
                  </option>
                  <option value="05045000">APARTADÓ - ANTIOQUIA</option>
                  <option value="05051000">ARBOLETES - ANTIOQUIA</option>
                  <option value="05055000">ARGELIA - ANTIOQUIA</option>
                  <option value="05059000">ARMENIA - ANTIOQUIA</option>
                  <option value="05079000">BARBOSA - ANTIOQUIA</option>
                  <option value="05086000">BELMIRA - ANTIOQUIA</option>
                  <option value="05088000">BELLO - ANTIOQUIA</option>
                  <option value="05091000">BETANIA - ANTIOQUIA</option>
                  <option value="05093000">BETULIA - ANTIOQUIA</option>
                  <option value="05101000">CIUDAD BOLÍVAR - ANTIOQUIA</option>
                  <option value="05107000">BRICEÑO - ANTIOQUIA</option>
                  <option value="05113000">BURITICÁ - ANTIOQUIA</option>
                  <option value="05120000">CÁCERES - ANTIOQUIA</option>
                  <option value="05125000">CAICEDO - ANTIOQUIA</option>
                  <option value="05129000">CALDAS - ANTIOQUIA</option>
                  <option value="05134000">CAMPAMENTO - ANTIOQUIA</option>
                  <option value="05138000">CAÑASGORDAS - ANTIOQUIA</option>
                  <option value="05142000">CARACOLÍ - ANTIOQUIA</option>
                  <option value="05145000">CARAMANTA - ANTIOQUIA</option>
                  <option value="05147000">CAREPA - ANTIOQUIA</option>
                  <option value="05148000">EL CARMEN DE VIBORAL - ANTIOQUIA</option>
                  <option value="05150000">CAROLINA - ANTIOQUIA</option>
                  <option value="05154000">CAUCASIA - ANTIOQUIA</option>
                  <option value="05172000">CHIGORODÓ - ANTIOQUIA</option>
                  <option value="05190000">CISNEROS - ANTIOQUIA</option>
                  <option value="05197000">COCORNÁ - ANTIOQUIA</option>
                  <option value="05206000">CONCEPCIÓN - ANTIOQUIA</option>
                  <option value="05209000">CONCORDIA - ANTIOQUIA</option>
                  <option value="05212000">COPACABANA - ANTIOQUIA</option>
                  <option value="05234000">DABEIBA - ANTIOQUIA</option>
                  <option value="05237000">DONMATÍAS - ANTIOQUIA</option>
                  <option value="05240000">EBÉJICO - ANTIOQUIA</option>
                  <option value="05250000">EL BAGRE - ANTIOQUIA</option>
                  <option value="05264000">ENTRERRIOS - ANTIOQUIA</option>
                  <option value="05266000">ENVIGADO - ANTIOQUIA</option>
                  <option value="05282000">FREDONIA - ANTIOQUIA</option>
                  <option value="05284000">FRONTINO - ANTIOQUIA</option>
                  <option value="05306000">GIRALDO - ANTIOQUIA</option>
                  <option value="05308000">GIRARDOTA - ANTIOQUIA</option>
                  <option value="05310000">GÓMEZ PLATA - ANTIOQUIA</option>
                  <option value="05313000">GRANADA - ANTIOQUIA</option>
                  <option value="05315000">GUADALUPE - ANTIOQUIA</option>
                  <option value="05318000">GUARNE - ANTIOQUIA</option>
                  <option value="05321000">GUATAPE - ANTIOQUIA</option>
                  <option value="05347000">HELICONIA - ANTIOQUIA</option>
                  <option value="05353000">HISPANIA - ANTIOQUIA</option>
                  <option value="05360000">ITAGUI - ANTIOQUIA</option>
                  <option value="05361000">ITUANGO - ANTIOQUIA</option>
                  <option value="05364000">JARDÍN - ANTIOQUIA</option>
                  <option value="05368000">JERICÓ - ANTIOQUIA</option>
                  <option value="05376000">LA CEJA - ANTIOQUIA</option>
                  <option value="05380000">LA ESTRELLA - ANTIOQUIA</option>
                  <option value="05390000">LA PINTADA - ANTIOQUIA</option>
                  <option value="05400000">LA UNIÓN - ANTIOQUIA</option>
                  <option value="05411000">LIBORINA - ANTIOQUIA</option>
                  <option value="05425000">MACEO - ANTIOQUIA</option>
                  <option value="05440000">MARINILLA - ANTIOQUIA</option>
                  <option value="05001000">MEDELLIN - ANTIOQUIA</option>
                  <option value="05467000">MONTEBELLO - ANTIOQUIA</option>
                  <option value="05475000">MURINDÓ - ANTIOQUIA</option>
                  <option value="05480000">MUTATÁ - ANTIOQUIA</option>
                  <option value="05483000">NARIÑO - ANTIOQUIA</option>
                  <option value="05490000">NECOCLÍ - ANTIOQUIA</option>
                  <option value="05495000">NECHÍ - ANTIOQUIA</option>
                  <option value="05501000">OLAYA - ANTIOQUIA</option>
                  <option value="05541000">PEÑOL - ANTIOQUIA</option>
                  <option value="05543000">PEQUE - ANTIOQUIA</option>
                  <option value="05576000">PUEBLORRICO - ANTIOQUIA</option>
                  <option value="05579000">PUERTO BERRÍO - ANTIOQUIA</option>
                  <option value="05585000">PUERTO NARE - ANTIOQUIA</option>
                  <option value="05591000">PUERTO TRIUNFO - ANTIOQUIA</option>
                  <option value="05604000">REMEDIOS - ANTIOQUIA</option>
                  <option value="05607000">RETIRO - ANTIOQUIA</option>
                  <option value="05615000">RIONEGRO - ANTIOQUIA</option>
                  <option value="05628000">SABANALARGA - ANTIOQUIA</option>
                  <option value="05631000">SABANETA - ANTIOQUIA</option>
                  <option value="05642000">SALGAR - ANTIOQUIA</option>
                  <option value="05647000">SAN ANDRÉS DE CUERQUÍA - ANTIOQUIA</option>
                  <option value="05649000">SAN CARLOS - ANTIOQUIA</option>
                  <option value="05652000">SAN FRANCISCO - ANTIOQUIA</option>
                  <option value="05656000">SAN JERÓNIMO - ANTIOQUIA</option>
                  <option value="05658000">SAN JOSÉ DE LA MONTAÑA - ANTIOQUIA</option>
                  <option value="05659000">SAN JUAN DE URABÁ - ANTIOQUIA</option>
                  <option value="05660000">SAN LUIS - ANTIOQUIA</option>
                  <option value="05664000">SAN PEDRO DE LOS MILAGROS - ANTIOQUIA</option>
                  <option value="05665000">SAN PEDRO DE URABA - ANTIOQUIA</option>
                  <option value="05667000">SAN RAFAEL - ANTIOQUIA</option>
                  <option value="05670000">SAN ROQUE - ANTIOQUIA</option>
                  <option value="05674000">SAN VICENTE - ANTIOQUIA</option>
                  <option value="05679000">SANTA BÁRBARA - ANTIOQUIA</option>
                  <option value="05686000">SANTA ROSA DE OSOS - ANTIOQUIA</option>
                  <option value="05690000">SANTO DOMINGO - ANTIOQUIA</option>
                  <option value="05697000">EL SANTUARIO - ANTIOQUIA</option>
                  <option value="05736000">SEGOVIA - ANTIOQUIA</option>
                  <option value="05756000">SONSON - ANTIOQUIA</option>
                  <option value="05761000">SOPETRÁN - ANTIOQUIA</option>
                  <option value="05789000">TÁMESIS - ANTIOQUIA</option>
                  <option value="05790000">TARAZÁ - ANTIOQUIA</option>
                  <option value="05792000">TARSO - ANTIOQUIA</option>
                  <option value="05809000">TITIRIBÍ - ANTIOQUIA</option>
                  <option value="05819000">TOLEDO - ANTIOQUIA</option>
                  <option value="05837000">TURBO - ANTIOQUIA</option>
                  <option value="05842000">URAMITA - ANTIOQUIA</option>
                  <option value="05847000">URRAO - ANTIOQUIA</option>
                  <option value="05854000">VALDIVIA - ANTIOQUIA</option>
                  <option value="05856000">VALPARAÍSO - ANTIOQUIA</option>
                  <option value="05858000">VEGACHÍ - ANTIOQUIA</option>
                  <option value="05861000">VENECIA - ANTIOQUIA</option>
                  <option value="05873000">VIGÍA DEL FUERTE - ANTIOQUIA</option>
                  <option value="05885000">YALÍ - ANTIOQUIA</option>
                  <option value="05887000">YARUMAL - ANTIOQUIA</option>
                  <option value="05890000">YOLOMBÓ - ANTIOQUIA</option>
                  <option value="05893000">YONDÓ - ANTIOQUIA</option>
                  <option value="05895000">ZARAGOZA - ANTIOQUIA</option>
                  <option value="81001000">ARAUCA - ARAUCA</option>
                  <option value="81065000">ARAUQUITA - ARAUCA</option>
                  <option value="81220000">CRAVO NORTE - ARAUCA</option>
                  <option value="81300000">FORTUL - ARAUCA</option>
                  <option value="81591000">PUERTO RONDÓN - ARAUCA</option>
                  <option value="81736000">SARAVENA - ARAUCA</option>
                  <option value="81794000">TAME - ARAUCA</option>
                  <option value="08001000">BARRANQUILLA - ATLÁNTICO</option>
                  <option value="08078000">BARANOA - ATLÁNTICO</option>
                  <option value="08137000">CAMPO DE LA CRUZ - ATLÁNTICO</option>
                  <option value="08141000">CANDELARIA - ATLÁNTICO</option>
                  <option value="08296000">GALAPA - ATLÁNTICO</option>
                  <option value="08372000">JUAN DE ACOSTA - ATLÁNTICO</option>
                  <option value="08421000">LURUACO - ATLÁNTICO</option>
                  <option value="08433000">MALAMBO - ATLÁNTICO</option>
                  <option value="08436000">MANATÍ - ATLÁNTICO</option>
                  <option value="08520000">PALMAR DE VARELA - ATLÁNTICO</option>
                  <option value="08549000">PIOJÓ - ATLÁNTICO</option>
                  <option value="08558000">POLONUEVO - ATLÁNTICO</option>
                  <option value="08560000">PONEDERA - ATLÁNTICO</option>
                  <option value="08573000">PUERTO COLOMBIA - ATLÁNTICO</option>
                  <option value="08606000">REPELÓN - ATLÁNTICO</option>
                  <option value="08634000">SABANAGRANDE - ATLÁNTICO</option>
                  <option value="08638000">SABANALARGA - ATLÁNTICO</option>
                  <option value="08675000">SANTA LUCÍA - ATLÁNTICO</option>
                  <option value="08685000">SANTO TOMÁS - ATLÁNTICO</option>
                  <option value="08758000">SOLEDAD - ATLÁNTICO</option>
                  <option value="08770000">SUAN - ATLÁNTICO</option>
                  <option value="08832000">TUBARÁ - ATLÁNTICO</option>
                  <option value="08849000">USIACURÍ - ATLÁNTICO</option>
                  <option value="11001000">BOGOTÁ D.C.</option>
                  <option value="13001000">CARTAGENA - BOLÍVAR</option>
                  <option value="13006000">ACHÍ - BOLÍVAR</option>
                  <option value="13030000">ALTOS DEL ROSARIO - BOLÍVAR</option>
                  <option value="13042000">ARENAL - BOLÍVAR</option>
                  <option value="13052000">ARJONA - BOLÍVAR</option>
                  <option value="13062000">ARROYOHONDO - BOLÍVAR</option>
                  <option value="13074000">BARRANCO DE LOBA - BOLÍVAR</option>
                  <option value="13140000">CALAMAR - BOLÍVAR</option>
                  <option value="13160000">CANTAGALLO - BOLÍVAR</option>
                  <option value="13188000">CICUCO - BOLÍVAR</option>
                  <option value="13212000">CÓRDOBA - BOLÍVAR</option>
                  <option value="13222000">CLEMENCIA - BOLÍVAR</option>
                  <option value="13244000">EL CARMEN DE BOLÍVAR - BOLÍVAR</option>
                  <option value="13248000">EL GUAMO - BOLÍVAR</option>
                  <option value="13268000">EL PEÑÓN - BOLÍVAR</option>
                  <option value="13300000">HATILLO DE LOBA - BOLÍVAR</option>
                  <option value="13430000">MAGANGUÉ - BOLÍVAR</option>
                  <option value="13433000">MAHATES - BOLÍVAR</option>
                  <option value="13440000">MARGARITA - BOLÍVAR</option>
                  <option value="13442000">MARÍA LA BAJA - BOLÍVAR</option>
                  <option value="13458000">MONTECRISTO - BOLÍVAR</option>
                  <option value="13468000">MOMPÓS - BOLÍVAR</option>
                  <option value="13473000">MORALES - BOLÍVAR</option>
                  <option value="13490000">NOROSÍ - BOLÍVAR</option>
                  <option value="13549000">PINILLOS - BOLÍVAR</option>
                  <option value="13580000">REGIDOR - BOLÍVAR</option>
                  <option value="13600000">RÍO VIEJO - BOLÍVAR</option>
                  <option value="13620000">SAN CRISTÓBAL - BOLÍVAR</option>
                  <option value="13647000">SAN ESTANISLAO - BOLÍVAR</option>
                  <option value="13650000">SAN FERNANDO - BOLÍVAR</option>
                  <option value="13654000">SAN JACINTO - BOLÍVAR</option>
                  <option value="13655000">SAN JACINTO DEL CAUCA - BOLÍVAR</option>
                  <option value="13657000">SAN JUAN NEPOMUCENO - BOLÍVAR</option>
                  <option value="13667000">SAN MARTÍN DE LOBA - BOLÍVAR</option>
                  <option value="13670000">SAN PABLO - BOLÍVAR</option>
                  <option value="13673000">SANTA CATALINA - BOLÍVAR</option>
                  <option value="13683000">SANTA ROSA - BOLÍVAR</option>
                  <option value="13688000">SANTA ROSA DEL SUR - BOLÍVAR</option>
                  <option value="13744000">SIMITÍ - BOLÍVAR</option>
                  <option value="13760000">SOPLAVIENTO - BOLÍVAR</option>
                  <option value="13780000">TALAIGUA NUEVO - BOLÍVAR</option>
                  <option value="13810000">TIQUISIO - BOLÍVAR</option>
                  <option value="13836000">TURBACO - BOLÍVAR</option>
                  <option value="13838000">TURBANÁ - BOLÍVAR</option>
                  <option value="13873000">VILLANUEVA - BOLÍVAR</option>
                  <option value="13894000">ZAMBRANO - BOLÍVAR</option>
                  <option value="15001000">TUNJA - BOYACÁ</option>
                  <option value="15022000">ALMEIDA - BOYACÁ</option>
                  <option value="15047000">AQUITANIA - BOYACÁ</option>
                  <option value="15051000">ARCABUCO - BOYACÁ</option>
                  <option value="15087000">BELÉN - BOYACÁ</option>
                  <option value="15090000">BERBEO - BOYACÁ</option>
                  <option value="15092000">BETÉITIVA - BOYACÁ</option>
                  <option value="15097000">BOAVITA - BOYACÁ</option>
                  <option value="15104000">BOYACÁ - BOYACÁ</option>
                  <option value="15106000">BRICEÑO - BOYACÁ</option>
                  <option value="15109000">BUENAVISTA - BOYACÁ</option>
                  <option value="15114000">BUSBANZÁ - BOYACÁ</option>
                  <option value="15131000">CALDAS - BOYACÁ</option>
                  <option value="15135000">CAMPOHERMOSO - BOYACÁ</option>
                  <option value="15162000">CERINZA - BOYACÁ</option>
                  <option value="15172000">CHINAVITA - BOYACÁ</option>
                  <option value="15176000">CHIQUINQUIRÁ - BOYACÁ</option>
                  <option value="15180000">CHISCAS - BOYACÁ</option>
                  <option value="15183000">CHITA - BOYACÁ</option>
                  <option value="15185000">CHITARAQUE - BOYACÁ</option>
                  <option value="15187000">CHIVATÁ - BOYACÁ</option>
                  <option value="15189000">CIÉNEGA - BOYACÁ</option>
                  <option value="15204000">CÓMBITA - BOYACÁ</option>
                  <option value="15212000">COPER - BOYACÁ</option>
                  <option value="15215000">CORRALES - BOYACÁ</option>
                  <option value="15218000">COVARACHÍA - BOYACÁ</option>
                  <option value="15223000">CUBARÁ - BOYACÁ</option>
                  <option value="15224000">CUCAITA - BOYACÁ</option>
                  <option value="15226000">CUÍTIVA - BOYACÁ</option>
                  <option value="15232000">CHÍQUIZA - BOYACÁ</option>
                  <option value="15236000">CHIVOR - BOYACÁ</option>
                  <option value="15238000">DUITAMA - BOYACÁ</option>
                  <option value="15244000">EL COCUY - BOYACÁ</option>
                  <option value="15248000">EL ESPINO - BOYACÁ</option>
                  <option value="15272000">FIRAVITOBA - BOYACÁ</option>
                  <option value="15276000">FLORESTA - BOYACÁ</option>
                  <option value="15293000">GACHANTIVÁ - BOYACÁ</option>
                  <option value="15296000">GAMEZA - BOYACÁ</option>
                  <option value="15299000">GARAGOA - BOYACÁ</option>
                  <option value="15317000">GUACAMAYAS - BOYACÁ</option>
                  <option value="15322000">GUATEQUE - BOYACÁ</option>
                  <option value="15325000">GUAYATÁ - BOYACÁ</option>
                  <option value="15332000">GÜICÁN - BOYACÁ</option>
                  <option value="15362000">IZA - BOYACÁ</option>
                  <option value="15367000">JENESANO - BOYACÁ</option>
                  <option value="15368000">JERICÓ - BOYACÁ</option>
                  <option value="15377000">LABRANZAGRANDE - BOYACÁ</option>
                  <option value="15380000">LA CAPILLA - BOYACÁ</option>
                  <option value="15401000">LA VICTORIA - BOYACÁ</option>
                  <option value="15403000">LA UVITA - BOYACÁ</option>
                  <option value="15407000">VILLA DE LEYVA - BOYACÁ</option>
                  <option value="15425000">MACANAL - BOYACÁ</option>
                  <option value="15442000">MARIPÍ - BOYACÁ</option>
                  <option value="15455000">MIRAFLORES - BOYACÁ</option>
                  <option value="15464000">MONGUA - BOYACÁ</option>
                  <option value="15466000">MONGUÍ - BOYACÁ</option>
                  <option value="15469000">MONIQUIRÁ - BOYACÁ</option>
                  <option value="15476000">MOTAVITA - BOYACÁ</option>
                  <option value="15480000">MUZO - BOYACÁ</option>
                  <option value="15491000">NOBSA - BOYACÁ</option>
                  <option value="15494000">NUEVO COLÓN - BOYACÁ</option>
                  <option value="15500000">OICATÁ - BOYACÁ</option>
                  <option value="15507000">OTANCHE - BOYACÁ</option>
                  <option value="15511000">PACHAVITA - BOYACÁ</option>
                  <option value="15514000">PÁEZ - BOYACÁ</option>
                  <option value="15516000">PAIPA - BOYACÁ</option>
                  <option value="15518000">PAJARITO - BOYACÁ</option>
                  <option value="15522000">PANQUEBA - BOYACÁ</option>
                  <option value="15531000">PAUNA - BOYACÁ</option>
                  <option value="15533000">PAYA - BOYACÁ</option>
                  <option value="15537000">PAZ DE RÍO - BOYACÁ</option>
                  <option value="15542000">PESCA - BOYACÁ</option>
                  <option value="15550000">PISBA - BOYACÁ</option>
                  <option value="15572000">PUERTO BOYACÁ - BOYACÁ</option>
                  <option value="15580000">QUÍPAMA - BOYACÁ</option>
                  <option value="15599000">RAMIRIQUÍ - BOYACÁ</option>
                  <option value="15600000">RÁQUIRA - BOYACÁ</option>
                  <option value="15621000">RONDÓN - BOYACÁ</option>
                  <option value="15632000">SABOYÁ - BOYACÁ</option>
                  <option value="15638000">SÁCHICA - BOYACÁ</option>
                  <option value="15646000">SAMACÁ - BOYACÁ</option>
                  <option value="15660000">SAN EDUARDO - BOYACÁ</option>
                  <option value="15664000">SAN JOSÉ DE PARE - BOYACÁ</option>
                  <option value="15667000">SAN LUIS DE GACENO - BOYACÁ</option>
                  <option value="15673000">SAN MATEO - BOYACÁ</option>
                  <option value="15676000">SAN MIGUEL DE SEMA - BOYACÁ</option>
                  <option value="15681000">SAN PABLO DE BORBUR - BOYACÁ</option>
                  <option value="15686000">SANTANA - BOYACÁ</option>
                  <option value="15690000">SANTA MARÍA - BOYACÁ</option>
                  <option value="15693000">SANTA ROSA DE VITERBO - BOYACÁ</option>
                  <option value="15696000">SANTA SOFÍA - BOYACÁ</option>
                  <option value="15720000">SATIVANORTE - BOYACÁ</option>
                  <option value="15723000">SATIVASUR - BOYACÁ</option>
                  <option value="15740000">SIACHOQUE - BOYACÁ</option>
                  <option value="15753000">SOATÁ - BOYACÁ</option>
                  <option value="15755000">SOCOTÁ - BOYACÁ</option>
                  <option value="15757000">SOCHA - BOYACÁ</option>
                  <option value="15759000">SOGAMOSO - BOYACÁ</option>
                  <option value="15761000">SOMONDOCO - BOYACÁ</option>
                  <option value="15762000">SORA - BOYACÁ</option>
                  <option value="15763000">SOTAQUIRÁ - BOYACÁ</option>
                  <option value="15764000">SORACÁ - BOYACÁ</option>
                  <option value="15774000">SUSACÓN - BOYACÁ</option>
                  <option value="15776000">SUTAMARCHÁN - BOYACÁ</option>
                  <option value="15778000">SUTATENZA - BOYACÁ</option>
                  <option value="15790000">TASCO - BOYACÁ</option>
                  <option value="15798000">TENZA - BOYACÁ</option>
                  <option value="15804000">TIBANÁ - BOYACÁ</option>
                  <option value="15806000">TIBASOSA - BOYACÁ</option>
                  <option value="15808000">TINJACÁ - BOYACÁ</option>
                  <option value="15810000">TIPACOQUE - BOYACÁ</option>
                  <option value="15814000">TOCA - BOYACÁ</option>
                  <option value="15816000">TOGÜÍ - BOYACÁ</option>
                  <option value="15820000">TÓPAGA - BOYACÁ</option>
                  <option value="15822000">TOTA - BOYACÁ</option>
                  <option value="15832000">TUNUNGUÁ - BOYACÁ</option>
                  <option value="15835000">TURMEQUÉ - BOYACÁ</option>
                  <option value="15837000">TUTA - BOYACÁ</option>
                  <option value="15839000">TUTAZÁ - BOYACÁ</option>
                  <option value="15842000">UMBITA - BOYACÁ</option>
                  <option value="15861000">VENTAQUEMADA - BOYACÁ</option>
                  <option value="15879000">VIRACACHÁ - BOYACÁ</option>
                  <option value="15897000">ZETAQUIRA - BOYACÁ</option>
                  <option value="17001000">MANIZALES - CALDAS</option>
                  <option value="17013000">AGUADAS - CALDAS</option>
                  <option value="17042000">ANSERMA - CALDAS</option>
                  <option value="17050000">ARANZAZU - CALDAS</option>
                  <option value="17088000">BELALCÁZAR - CALDAS</option>
                  <option value="17174000">CHINCHINÁ - CALDAS</option>
                  <option value="17272000">FILADELFIA - CALDAS</option>
                  <option value="17380000">LA DORADA - CALDAS</option>
                  <option value="17388000">LA MERCED - CALDAS</option>
                  <option value="17433000">MANZANARES - CALDAS</option>
                  <option value="17442000">MARMATO - CALDAS</option>
                  <option value="17444000">MARQUETALIA - CALDAS</option>
                  <option value="17446000">MARULANDA - CALDAS</option>
                  <option value="17486000">NEIRA - CALDAS</option>
                  <option value="17495000">NORCASIA - CALDAS</option>
                  <option value="17513000">PÁCORA - CALDAS</option>
                  <option value="17524000">PALESTINA - CALDAS</option>
                  <option value="17541000">PENSILVANIA - CALDAS</option>
                  <option value="17614000">RIOSUCIO - CALDAS</option>
                  <option value="17616000">RISARALDA - CALDAS</option>
                  <option value="17653000">SALAMINA - CALDAS</option>
                  <option value="17662000">SAMANÁ - CALDAS</option>
                  <option value="17665000">SAN JOSÉ - CALDAS</option>
                  <option value="17777000">SUPÍA - CALDAS</option>
                  <option value="17867000">VICTORIA - CALDAS</option>
                  <option value="17873000">VILLAMARÍA - CALDAS</option>
                  <option value="17877000">VITERBO - CALDAS</option>
                  <option value="18001000">FLORENCIA - CAQUETÁ</option>
                  <option value="18029000">ALBANIA - CAQUETÁ</option>
                  <option value="18094000">BELÉN DE LOS ANDAQUÍES - CAQUETÁ</option>
                  <option value="18150000">CARTAGENA DEL CHAIRÁ - CAQUETÁ</option>
                  <option value="18205000">CURILLO - CAQUETÁ</option>
                  <option value="18247000">EL DONCELLO - CAQUETÁ</option>
                  <option value="18256000">EL PAUJIL - CAQUETÁ</option>
                  <option value="18410000">LA MONTAÑITA - CAQUETÁ</option>
                  <option value="18460000">MILÁN - CAQUETÁ</option>
                  <option value="18479000">MORELIA - CAQUETÁ</option>
                  <option value="18592000">PUERTO RICO - CAQUETÁ</option>
                  <option value="18610000">SAN JOSÉ DEL FRAGUA - CAQUETÁ</option>
                  <option value="18753000">SAN VICENTE DEL CAGUÁN - CAQUETÁ</option>
                  <option value="18756000">SOLANO - CAQUETÁ</option>
                  <option value="18785000">SOLITA - CAQUETÁ</option>
                  <option value="18860000">VALPARAÍSO - CAQUETÁ</option>
                  <option value="85001000">YOPAL - CASANARE</option>
                  <option value="85010000">AGUAZUL - CASANARE</option>
                  <option value="85015000">CHAMEZA - CASANARE</option>
                  <option value="85125000">HATO COROZAL - CASANARE</option>
                  <option value="85136000">LA SALINA - CASANARE</option>
                  <option value="85139000">MANÍ - CASANARE</option>
                  <option value="85162000">MONTERREY - CASANARE</option>
                  <option value="85225000">NUNCHÍA - CASANARE</option>
                  <option value="85230000">OROCUÉ - CASANARE</option>
                  <option value="85250000">PAZ DE ARIPORO - CASANARE</option>
                  <option value="85263000">PORE - CASANARE</option>
                  <option value="85279000">RECETOR - CASANARE</option>
                  <option value="85300000">SABANALARGA - CASANARE</option>
                  <option value="85315000">SÁCAMA - CASANARE</option>
                  <option value="85325000">SAN LUIS DE PALENQUE - CASANARE</option>
                  <option value="85400000">TÁMARA - CASANARE</option>
                  <option value="85410000">TAURAMENA - CASANARE</option>
                  <option value="85430000">TRINIDAD - CASANARE</option>
                  <option value="85440000">VILLANUEVA - CASANARE</option>
                  <option value="19001000">POPAYÁN - CAUCA</option>
                  <option value="19022000">ALMAGUER - CAUCA</option>
                  <option value="19050000">ARGELIA - CAUCA</option>
                  <option value="19075000">BALBOA - CAUCA</option>
                  <option value="19100000">BOLÍVAR - CAUCA</option>
                  <option value="19110000">BUENOS AIRES - CAUCA</option>
                  <option value="19130000">CAJIBÍO - CAUCA</option>
                  <option value="19137000">CALDONO - CAUCA</option>
                  <option value="19142000">CALOTO - CAUCA</option>
                  <option value="19212000">CORINTO - CAUCA</option>
                  <option value="19256000">EL TAMBO - CAUCA</option>
                  <option value="19290000">FLORENCIA - CAUCA</option>
                  <option value="19300000">GUACHENÉ - CAUCA</option>
                  <option value="19318000">GUAPI - CAUCA</option>
                  <option value="19355000">INZÁ - CAUCA</option>
                  <option value="19364000">JAMBALÓ - CAUCA</option>
                  <option value="19392000">LA SIERRA - CAUCA</option>
                  <option value="19397000">LA VEGA - CAUCA</option>
                  <option value="19418000">LÓPEZ - CAUCA</option>
                  <option value="19450000">MERCADERES - CAUCA</option>
                  <option value="19455000">MIRANDA - CAUCA</option>
                  <option value="19473000">MORALES - CAUCA</option>
                  <option value="19513000">PADILLA - CAUCA</option>
                  <option value="19517000">PAEZ - CAUCA</option>
                  <option value="19532000">PATÍA - CAUCA</option>
                  <option value="19533000">PIAMONTE - CAUCA</option>
                  <option value="19548000">PIENDAMÓ - CAUCA</option>
                  <option value="19573000">PUERTO TEJADA - CAUCA</option>
                  <option value="19585000">PURACÉ - CAUCA</option>
                  <option value="19622000">ROSAS - CAUCA</option>
                  <option value="19693000">SAN SEBASTIÁN - CAUCA</option>
                  <option value="19698000">SANTANDER DE QUILICHAO - CAUCA</option>
                  <option value="19701000">SANTA ROSA - CAUCA</option>
                  <option value="19743000">SILVIA - CAUCA</option>
                  <option value="19760000">SOTARA - CAUCA</option>
                  <option value="19780000">SUÁREZ - CAUCA</option>
                  <option value="19785000">SUCRE - CAUCA</option>
                  <option value="19807000">TIMBÍO - CAUCA</option>
                  <option value="19809000">TIMBIQUÍ - CAUCA</option>
                  <option value="19821000">TORIBIO - CAUCA</option>
                  <option value="19824000">TOTORÓ - CAUCA</option>
                  <option value="19845000">VILLA RICA - CAUCA</option>
                  <option value="20001000">VALLEDUPAR - CESAR</option>
                  <option value="20011000">AGUACHICA - CESAR</option>
                  <option value="20013000">AGUSTÍN CODAZZI - CESAR</option>
                  <option value="20032000">ASTREA - CESAR</option>
                  <option value="20045000">BECERRIL - CESAR</option>
                  <option value="20060000">BOSCONIA - CESAR</option>
                  <option value="20175000">CHIMICHAGUA - CESAR</option>
                  <option value="20178000">CHIRIGUANÁ - CESAR</option>
                  <option value="20228000">CURUMANÍ - CESAR</option>
                  <option value="20238000">EL COPEY - CESAR</option>
                  <option value="20250000">EL PASO - CESAR</option>
                  <option value="20295000">GAMARRA - CESAR</option>
                  <option value="20310000">GONZÁLEZ - CESAR</option>
                  <option value="20383000">LA GLORIA - CESAR</option>
                  <option value="20400000">LA JAGUA DE IBIRICO - CESAR</option>
                  <option value="20443000">MANAURE - CESAR</option>
                  <option value="20517000">PAILITAS - CESAR</option>
                  <option value="20550000">PELAYA - CESAR</option>
                  <option value="20570000">PUEBLO BELLO - CESAR</option>
                  <option value="20614000">RÍO DE ORO - CESAR</option>
                  <option value="20621000">LA PAZ - CESAR</option>
                  <option value="20710000">SAN ALBERTO - CESAR</option>
                  <option value="20750000">SAN DIEGO - CESAR</option>
                  <option value="20770000">SAN MARTÍN - CESAR</option>
                  <option value="20787000">TAMALAMEQUE - CESAR</option>
                  <option value="27001000">QUIBDÓ - CHOCÓ</option>
                  <option value="27006000">ACANDÍ - CHOCÓ</option>
                  <option value="27025000">ALTO BAUDÓ - CHOCÓ</option>
                  <option value="27050000">ATRATO - CHOCÓ</option>
                  <option value="27073000">BAGADÓ - CHOCÓ</option>
                  <option value="27075000">BAHÍA SOLANO - CHOCÓ</option>
                  <option value="27077000">BAJO BAUDÓ - CHOCÓ</option>
                  <option value="27099000">BOJAYA - CHOCÓ</option>
                  <option value="27135000">EL CANTÓN DEL SAN PABLO - CHOCÓ</option>
                  <option value="27150000">CARMEN DEL DARIÉN - CHOCÓ</option>
                  <option value="27160000">CÉRTEGUI - CHOCÓ</option>
                  <option value="27205000">CONDOTO - CHOCÓ</option>
                  <option value="27245000">EL CARMEN DE ATRATO - CHOCÓ</option>
                  <option value="27250000">EL LITORAL DEL SAN JUAN - CHOCÓ</option>
                  <option value="27361000">ISTMINA - CHOCÓ</option>
                  <option value="27372000">JURADÓ - CHOCÓ</option>
                  <option value="27413000">LLORÓ - CHOCÓ</option>
                  <option value="27425000">MEDIO ATRATO - CHOCÓ</option>
                  <option value="27430000">MEDIO BAUDÓ - CHOCÓ</option>
                  <option value="27450000">MEDIO SAN JUAN - CHOCÓ</option>
                  <option value="27491000">NÓVITA - CHOCÓ</option>
                  <option value="27495000">NUQUÍ - CHOCÓ</option>
                  <option value="27580000">RÍO IRÓ - CHOCÓ</option>
                  <option value="27600000">RÍO QUITO - CHOCÓ</option>
                  <option value="27615000">RIOSUCIO - CHOCÓ</option>
                  <option value="27660000">SAN JOSÉ DEL PALMAR - CHOCÓ</option>
                  <option value="27745000">SIPÍ - CHOCÓ</option>
                  <option value="27787000">TADÓ - CHOCÓ</option>
                  <option value="27800000">UNGUÍA - CHOCÓ</option>
                  <option value="27810000">UNIÓN PANAMERICANA - CHOCÓ</option>
                  <option value="23001000">MONTERÍA - CÓRDOBA</option>
                  <option value="23068000">AYAPEL - CÓRDOBA</option>
                  <option value="23079000">BUENAVISTA - CÓRDOBA</option>
                  <option value="23090000">CANALETE - CÓRDOBA</option>
                  <option value="23162000">CERETÉ - CÓRDOBA</option>
                  <option value="23168000">CHIMÁ - CÓRDOBA</option>
                  <option value="23182000">CHINÚ - CÓRDOBA</option>
                  <option value="23189000">CIÉNAGA DE ORO - CÓRDOBA</option>
                  <option value="23300000">COTORRA - CÓRDOBA</option>
                  <option value="23350000">LA APARTADA - CÓRDOBA</option>
                  <option value="23417000">LORICA - CÓRDOBA</option>
                  <option value="23419000">LOS CÓRDOBAS - CÓRDOBA</option>
                  <option value="23464000">MOMIL - CÓRDOBA</option>
                  <option value="23466000">MONTELÍBANO - CÓRDOBA</option>
                  <option value="23500000">MOÑITOS - CÓRDOBA</option>
                  <option value="23555000">PLANETA RICA - CÓRDOBA</option>
                  <option value="23570000">PUEBLO NUEVO - CÓRDOBA</option>
                  <option value="23574000">PUERTO ESCONDIDO - CÓRDOBA</option>
                  <option value="23580000">PUERTO LIBERTADOR - CÓRDOBA</option>
                  <option value="23586000">PURÍSIMA - CÓRDOBA</option>
                  <option value="23660000">SAHAGÚN - CÓRDOBA</option>
                  <option value="23670000">SAN ANDRÉS SOTAVENTO - CÓRDOBA</option>
                  <option value="23672000">SAN ANTERO - CÓRDOBA</option>
                  <option value="23675000">SAN BERNARDO DEL VIENTO - CÓRDOBA</option>
                  <option value="23678000">SAN CARLOS - CÓRDOBA</option>
                  <option value="23682000">SAN JOSÉ DE URÉ - CÓRDOBA</option>
                  <option value="23686000">SAN PELAYO - CÓRDOBA</option>
                  <option value="23807000">TIERRALTA - CÓRDOBA</option>
                  <option value="23815000">TUCHÍN - CÓRDOBA</option>
                  <option value="23855000">VALENCIA - CÓRDOBA</option>
                  <option value="25001000">AGUA DE DIOS - CUNDINAMARCA</option>
                  <option value="25019000">ALBÁN - CUNDINAMARCA</option>
                  <option value="25035000">ANAPOIMA - CUNDINAMARCA</option>
                  <option value="25040000">ANOLAIMA - CUNDINAMARCA</option>
                  <option value="25053000">ARBELÁEZ - CUNDINAMARCA</option>
                  <option value="25086000">BELTRÁN - CUNDINAMARCA</option>
                  <option value="25095000">BITUIMA - CUNDINAMARCA</option>
                  <option value="25099000">BOJACÁ - CUNDINAMARCA</option>
                  <option value="25120000">CABRERA - CUNDINAMARCA</option>
                  <option value="25123000">CACHIPAY - CUNDINAMARCA</option>
                  <option value="25126000">CAJICÁ - CUNDINAMARCA</option>
                  <option value="25148000">CAPARRAPÍ - CUNDINAMARCA</option>
                  <option value="25151000">CAQUEZA - CUNDINAMARCA</option>
                  <option value="25154000">CARMEN DE CARUPA - CUNDINAMARCA</option>
                  <option value="25168000">CHAGUANÍ - CUNDINAMARCA</option>
                  <option value="25175000">CHÍA - CUNDINAMARCA</option>
                  <option value="25178000">CHIPAQUE - CUNDINAMARCA</option>
                  <option value="25181000">CHOACHÍ - CUNDINAMARCA</option>
                  <option value="25183000">CHOCONTÁ - CUNDINAMARCA</option>
                  <option value="25200000">COGUA - CUNDINAMARCA</option>
                  <option value="25214000">COTA - CUNDINAMARCA</option>
                  <option value="25224000">CUCUNUBÁ - CUNDINAMARCA</option>
                  <option value="25245000">EL COLEGIO - CUNDINAMARCA</option>
                  <option value="25258000">EL PEÑÓN - CUNDINAMARCA</option>
                  <option value="25260000">EL ROSAL - CUNDINAMARCA</option>
                  <option value="25269000">FACATATIVÁ - CUNDINAMARCA</option>
                  <option value="25279000">FOMEQUE - CUNDINAMARCA</option>
                  <option value="25281000">FOSCA - CUNDINAMARCA</option>
                  <option value="25286000">FUNZA - CUNDINAMARCA</option>
                  <option value="25288000">FÚQUENE - CUNDINAMARCA</option>
                  <option value="25290000">FUSAGASUGÁ - CUNDINAMARCA</option>
                  <option value="25293000">GACHALA - CUNDINAMARCA</option>
                  <option value="25295000">GACHANCIPÁ - CUNDINAMARCA</option>
                  <option value="25297000">GACHETÁ - CUNDINAMARCA</option>
                  <option value="25299000">GAMA - CUNDINAMARCA</option>
                  <option value="25307000">GIRARDOT - CUNDINAMARCA</option>
                  <option value="25312000">GRANADA - CUNDINAMARCA</option>
                  <option value="25317000">GUACHETÁ - CUNDINAMARCA</option>
                  <option value="25320000">GUADUAS - CUNDINAMARCA</option>
                  <option value="25322000">GUASCA - CUNDINAMARCA</option>
                  <option value="25324000">GUATAQUÍ - CUNDINAMARCA</option>
                  <option value="25326000">GUATAVITA - CUNDINAMARCA</option>
                  <option value="25328000">GUAYABAL DE SIQUIMA - CUNDINAMARCA</option>
                  <option value="25335000">GUAYABETAL - CUNDINAMARCA</option>
                  <option value="25339000">GUTIÉRREZ - CUNDINAMARCA</option>
                  <option value="25368000">JERUSALÉN - CUNDINAMARCA</option>
                  <option value="25372000">JUNÍN - CUNDINAMARCA</option>
                  <option value="25377000">LA CALERA - CUNDINAMARCA</option>
                  <option value="25386000">LA MESA - CUNDINAMARCA</option>
                  <option value="25394000">LA PALMA - CUNDINAMARCA</option>
                  <option value="25398000">LA PEÑA - CUNDINAMARCA</option>
                  <option value="25402000">LA VEGA - CUNDINAMARCA</option>
                  <option value="25407000">LENGUAZAQUE - CUNDINAMARCA</option>
                  <option value="25426000">MACHETA - CUNDINAMARCA</option>
                  <option value="25430000">MADRID - CUNDINAMARCA</option>
                  <option value="25436000">MANTA - CUNDINAMARCA</option>
                  <option value="25438000">MEDINA - CUNDINAMARCA</option>
                  <option value="25473000">MOSQUERA - CUNDINAMARCA</option>
                  <option value="25483000">NARIÑO - CUNDINAMARCA</option>
                  <option value="25486000">NEMOCÓN - CUNDINAMARCA</option>
                  <option value="25488000">NILO - CUNDINAMARCA</option>
                  <option value="25489000">NIMAIMA - CUNDINAMARCA</option>
                  <option value="25491000">NOCAIMA - CUNDINAMARCA</option>
                  <option value="25506000">VENECIA - CUNDINAMARCA</option>
                  <option value="25513000">PACHO - CUNDINAMARCA</option>
                  <option value="25518000">PAIME - CUNDINAMARCA</option>
                  <option value="25524000">PANDI - CUNDINAMARCA</option>
                  <option value="25530000">PARATEBUENO - CUNDINAMARCA</option>
                  <option value="25535000">PASCA - CUNDINAMARCA</option>
                  <option value="25572000">PUERTO SALGAR - CUNDINAMARCA</option>
                  <option value="25580000">PULÍ - CUNDINAMARCA</option>
                  <option value="25592000">QUEBRADANEGRA - CUNDINAMARCA</option>
                  <option value="25594000">QUETAME - CUNDINAMARCA</option>
                  <option value="25596000">QUIPILE - CUNDINAMARCA</option>
                  <option value="25599000">APULO - CUNDINAMARCA</option>
                  <option value="25612000">RICAURTE - CUNDINAMARCA</option>
                  <option value="25645000">SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
                  <option value="25649000">SAN BERNARDO - CUNDINAMARCA</option>
                  <option value="25653000">SAN CAYETANO - CUNDINAMARCA</option>
                  <option value="25658000">SAN FRANCISCO - CUNDINAMARCA</option>
                  <option value="25662000">SAN JUAN DE RÍO SECO - CUNDINAMARCA</option>
                  <option value="25718000">SASAIMA - CUNDINAMARCA</option>
                  <option value="25736000">SESQUILÉ - CUNDINAMARCA</option>
                  <option value="25740000">SIBATÉ - CUNDINAMARCA</option>
                  <option value="25743000">SILVANIA - CUNDINAMARCA</option>
                  <option value="25745000">SIMIJACA - CUNDINAMARCA</option>
                  <option value="25754000">SOACHA - CUNDINAMARCA</option>
                  <option value="25758000">SOPÓ - CUNDINAMARCA</option>
                  <option value="25769000">SUBACHOQUE - CUNDINAMARCA</option>
                  <option value="25772000">SUESCA - CUNDINAMARCA</option>
                  <option value="25777000">SUPATÁ - CUNDINAMARCA</option>
                  <option value="25779000">SUSA - CUNDINAMARCA</option>
                  <option value="25781000">SUTATAUSA - CUNDINAMARCA</option>
                  <option value="25785000">TABIO - CUNDINAMARCA</option>
                  <option value="25793000">TAUSA - CUNDINAMARCA</option>
                  <option value="25797000">TENA - CUNDINAMARCA</option>
                  <option value="25799000">TENJO - CUNDINAMARCA</option>
                  <option value="25805000">TIBACUY - CUNDINAMARCA</option>
                  <option value="25807000">TIBIRITA - CUNDINAMARCA</option>
                  <option value="25815000">TOCAIMA - CUNDINAMARCA</option>
                  <option value="25817000">TOCANCIPÁ - CUNDINAMARCA</option>
                  <option value="25823000">TOPAIPÍ - CUNDINAMARCA</option>
                  <option value="25839000">UBALÁ - CUNDINAMARCA</option>
                  <option value="25841000">UBAQUE - CUNDINAMARCA</option>
                  <option value="25843000">VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
                  <option value="25845000">UNE - CUNDINAMARCA</option>
                  <option value="25851000">ÚTICA - CUNDINAMARCA</option>
                  <option value="25862000">VERGARA - CUNDINAMARCA</option>
                  <option value="25867000">VIANÍ - CUNDINAMARCA</option>
                  <option value="25871000">VILLAGÓMEZ - CUNDINAMARCA</option>
                  <option value="25873000">VILLAPINZÓN - CUNDINAMARCA</option>
                  <option value="25875000">VILLETA - CUNDINAMARCA</option>
                  <option value="25878000">VIOTÁ - CUNDINAMARCA</option>
                  <option value="25885000">YACOPÍ - CUNDINAMARCA</option>
                  <option value="25898000">ZIPACÓN - CUNDINAMARCA</option>
                  <option value="25899000">ZIPAQUIRÁ - CUNDINAMARCA</option>
                  <option value="94001000">INÍRIDA - GUAINÍA</option>
                  <option value="94343000">BARRANCO MINAS - GUAINÍA</option>
                  <option value="94663000">MAPIRIPANA - GUAINÍA</option>
                  <option value="94883000">SAN FELIPE - GUAINÍA</option>
                  <option value="94884000">PUERTO COLOMBIA - GUAINÍA</option>
                  <option value="94885000">LA GUADALUPE - GUAINÍA</option>
                  <option value="94886000">CACAHUAL - GUAINÍA</option>
                  <option value="94887000">PANA PANA - GUAINÍA</option>
                  <option value="94888000">MORICHAL - GUAINÍA</option>
                  <option value="95001000">SAN JOSÉ DEL GUAVIARE - GUAVIARE</option>
                  <option value="95015000">CALAMAR - GUAVIARE</option>
                  <option value="95025000">EL RETORNO - GUAVIARE</option>
                  <option value="95200000">MIRAFLORES - GUAVIARE</option>
                  <option value="41001000">NEIVA - HUILA</option>
                  <option value="41006000">ACEVEDO - HUILA</option>
                  <option value="41013000">AGRADO - HUILA</option>
                  <option value="41016000">AIPE - HUILA</option>
                  <option value="41020000">ALGECIRAS - HUILA</option>
                  <option value="41026000">ALTAMIRA - HUILA</option>
                  <option value="41078000">BARAYA - HUILA</option>
                  <option value="41132000">CAMPOALEGRE - HUILA</option>
                  <option value="41206000">COLOMBIA - HUILA</option>
                  <option value="41244000">ELÍAS - HUILA</option>
                  <option value="41298000">GARZÓN - HUILA</option>
                  <option value="41306000">GIGANTE - HUILA</option>
                  <option value="41319000">GUADALUPE - HUILA</option>
                  <option value="41349000">HOBO - HUILA</option>
                  <option value="41357000">IQUIRA - HUILA</option>
                  <option value="41359000">ISNOS - HUILA</option>
                  <option value="41378000">LA ARGENTINA - HUILA</option>
                  <option value="41396000">LA PLATA - HUILA</option>
                  <option value="41483000">NÁTAGA - HUILA</option>
                  <option value="41503000">OPORAPA - HUILA</option>
                  <option value="41518000">PAICOL - HUILA</option>
                  <option value="41524000">PALERMO - HUILA</option>
                  <option value="41530000">PALESTINA - HUILA</option>
                  <option value="41548000">PITAL - HUILA</option>
                  <option value="41551000">PITALITO - HUILA</option>
                  <option value="41615000">RIVERA - HUILA</option>
                  <option value="41660000">SALADOBLANCO - HUILA</option>
                  <option value="41668000">SAN AGUSTÍN - HUILA</option>
                  <option value="41676000">SANTA MARÍA - HUILA</option>
                  <option value="41770000">SUAZA - HUILA</option>
                  <option value="41791000">TARQUI - HUILA</option>
                  <option value="41797000">TESALIA - HUILA</option>
                  <option value="41799000">TELLO - HUILA</option>
                  <option value="41801000">TERUEL - HUILA</option>
                  <option value="41807000">TIMANÁ - HUILA</option>
                  <option value="41872000">VILLAVIEJA - HUILA</option>
                  <option value="41885000">YAGUARÁ - HUILA</option>
                  <option value="44001000">RIOHACHA - LA GUAJIRA</option>
                  <option value="44035000">ALBANIA - LA GUAJIRA</option>
                  <option value="44078000">BARRANCAS - LA GUAJIRA</option>
                  <option value="44090000">DIBULLA - LA GUAJIRA</option>
                  <option value="44098000">DISTRACCIÓN - LA GUAJIRA</option>
                  <option value="44110000">EL MOLINO - LA GUAJIRA</option>
                  <option value="44279000">FONSECA - LA GUAJIRA</option>
                  <option value="44378000">HATONUEVO - LA GUAJIRA</option>
                  <option value="44420000">LA JAGUA DEL PILAR - LA GUAJIRA</option>
                  <option value="44430000">MAICAO - LA GUAJIRA</option>
                  <option value="44560000">MANAURE - LA GUAJIRA</option>
                  <option value="44650000">SAN JUAN DEL CESAR - LA GUAJIRA</option>
                  <option value="44847000">URIBIA - LA GUAJIRA</option>
                  <option value="44855000">URUMITA - LA GUAJIRA</option>
                  <option value="44874000">VILLANUEVA - LA GUAJIRA</option>
                  <option value="47001000">SANTA MARTA - MAGDALENA</option>
                  <option value="47030000">ALGARROBO - MAGDALENA</option>
                  <option value="47053000">ARACATACA - MAGDALENA</option>
                  <option value="47058000">ARIGUANÍ - MAGDALENA</option>
                  <option value="47161000">CERRO SAN ANTONIO - MAGDALENA</option>
                  <option value="47170000">CHIVOLO - MAGDALENA</option>
                  <option value="47189000">CIÉNAGA - MAGDALENA</option>
                  <option value="47205000">CONCORDIA - MAGDALENA</option>
                  <option value="47245000">EL BANCO - MAGDALENA</option>
                  <option value="47258000">EL PIÑON - MAGDALENA</option>
                  <option value="47268000">EL RETÉN - MAGDALENA</option>
                  <option value="47288000">FUNDACIÓN - MAGDALENA</option>
                  <option value="47318000">GUAMAL - MAGDALENA</option>
                  <option value="47460000">NUEVA GRANADA - MAGDALENA</option>
                  <option value="47541000">PEDRAZA - MAGDALENA</option>
                  <option value="47545000">PIJIÑO DEL CARMEN - MAGDALENA</option>
                  <option value="47551000">PIVIJAY - MAGDALENA</option>
                  <option value="47555000">PLATO - MAGDALENA</option>
                  <option value="47570000">PUEBLOVIEJO - MAGDALENA</option>
                  <option value="47605000">REMOLINO - MAGDALENA</option>
                  <option value="47660000">SABANAS DE SAN ANGEL - MAGDALENA</option>
                  <option value="47675000">SALAMINA - MAGDALENA</option>
                  <option value="47692000">SAN SEBASTIÁN DE BUENAVISTA - MAGDALENA</option>
                  <option value="47703000">SAN ZENÓN - MAGDALENA</option>
                  <option value="47707000">SANTA ANA - MAGDALENA</option>
                  <option value="47720000">SANTA BÁRBARA DE PINTO - MAGDALENA</option>
                  <option value="47745000">SITIONUEVO - MAGDALENA</option>
                  <option value="47798000">TENERIFE - MAGDALENA</option>
                  <option value="47960000">ZAPAYÁN - MAGDALENA</option>
                  <option value="47980000">ZONA BANANERA - MAGDALENA</option>
                  <option value="50001000">VILLAVICENCIO - META</option>
                  <option value="50006000">ACACÍAS - META</option>
                  <option value="50110000">BARRANCA DE UPÍA - META</option>
                  <option value="50124000">CABUYARO - META</option>
                  <option value="50150000">CASTILLA LA NUEVA - META</option>
                  <option value="50223000">CUBARRAL - META</option>
                  <option value="50226000">CUMARAL - META</option>
                  <option value="50245000">EL CALVARIO - META</option>
                  <option value="50251000">EL CASTILLO - META</option>
                  <option value="50270000">EL DORADO - META</option>
                  <option value="50287000">FUENTE DE ORO - META</option>
                  <option value="50313000">GRANADA - META</option>
                  <option value="50318000">GUAMAL - META</option>
                  <option value="50325000">MAPIRIPÁN - META</option>
                  <option value="50330000">MESETAS - META</option>
                  <option value="50350000">LA MACARENA - META</option>
                  <option value="50370000">URIBE - META</option>
                  <option value="50400000">LEJANÍAS - META</option>
                  <option value="50450000">PUERTO CONCORDIA - META</option>
                  <option value="50568000">PUERTO GAITÁN - META</option>
                  <option value="50573000">PUERTO LÓPEZ - META</option>
                  <option value="50577000">PUERTO LLERAS - META</option>
                  <option value="50590000">PUERTO RICO - META</option>
                  <option value="50606000">RESTREPO - META</option>
                  <option value="50680000">SAN CARLOS DE GUAROA - META</option>
                  <option value="50683000">SAN JUAN DE ARAMA - META</option>
                  <option value="50686000">SAN JUANITO - META</option>
                  <option value="50689000">SAN MARTÍN - META</option>
                  <option value="50711000">VISTAHERMOSA - META</option>
                  <option value="52001000">PASTO - NARIÑO</option>
                  <option value="52019000">ALBÁN - NARIÑO</option>
                  <option value="52022000">ALDANA - NARIÑO</option>
                  <option value="52036000">ANCUYÁ - NARIÑO</option>
                  <option value="52051000">ARBOLEDA - NARIÑO</option>
                  <option value="52079000">BARBACOAS - NARIÑO</option>
                  <option value="52083000">BELÉN - NARIÑO</option>
                  <option value="52110000">BUESACO - NARIÑO</option>
                  <option value="52203000">COLÓN - NARIÑO</option>
                  <option value="52207000">CONSACA - NARIÑO</option>
                  <option value="52210000">CONTADERO - NARIÑO</option>
                  <option value="52215000">CÓRDOBA - NARIÑO</option>
                  <option value="52224000">CUASPUD - NARIÑO</option>
                  <option value="52227000">CUMBAL - NARIÑO</option>
                  <option value="52233000">CUMBITARA - NARIÑO</option>
                  <option value="52240000">CHACHAGÜÍ - NARIÑO</option>
                  <option value="52250000">EL CHARCO - NARIÑO</option>
                  <option value="52254000">EL PEÑOL - NARIÑO</option>
                  <option value="52256000">EL ROSARIO - NARIÑO</option>
                  <option value="52258000">EL TABLÓN DE GÓMEZ - NARIÑO</option>
                  <option value="52260000">EL TAMBO - NARIÑO</option>
                  <option value="52287000">FUNES - NARIÑO</option>
                  <option value="52317000">GUACHUCAL - NARIÑO</option>
                  <option value="52320000">GUAITARILLA - NARIÑO</option>
                  <option value="52323000">GUALMATÁN - NARIÑO</option>
                  <option value="52352000">ILES - NARIÑO</option>
                  <option value="52354000">IMUÉS - NARIÑO</option>
                  <option value="52356000">IPIALES - NARIÑO</option>
                  <option value="52378000">LA CRUZ - NARIÑO</option>
                  <option value="52381000">LA FLORIDA - NARIÑO</option>
                  <option value="52385000">LA LLANADA - NARIÑO</option>
                  <option value="52390000">LA TOLA - NARIÑO</option>
                  <option value="52399000">LA UNIÓN - NARIÑO</option>
                  <option value="52405000">LEIVA - NARIÑO</option>
                  <option value="52411000">LINARES - NARIÑO</option>
                  <option value="52418000">LOS ANDES - NARIÑO</option>
                  <option value="52427000">MAGÜI - NARIÑO</option>
                  <option value="52435000">MALLAMA - NARIÑO</option>
                  <option value="52473000">MOSQUERA - NARIÑO</option>
                  <option value="52480000">NARIÑO - NARIÑO</option>
                  <option value="52490000">OLAYA HERRERA - NARIÑO</option>
                  <option value="52506000">OSPINA - NARIÑO</option>
                  <option value="52520000">FRANCISCO PIZARRO - NARIÑO</option>
                  <option value="52540000">POLICARPA - NARIÑO</option>
                  <option value="52560000">POTOSÍ - NARIÑO</option>
                  <option value="52565000">PROVIDENCIA - NARIÑO</option>
                  <option value="52573000">PUERRES - NARIÑO</option>
                  <option value="52585000">PUPIALES - NARIÑO</option>
                  <option value="52612000">RICAURTE - NARIÑO</option>
                  <option value="52621000">ROBERTO PAYÁN - NARIÑO</option>
                  <option value="52678000">SAMANIEGO - NARIÑO</option>
                  <option value="52683000">SANDONÁ - NARIÑO</option>
                  <option value="52685000">SAN BERNARDO - NARIÑO</option>
                  <option value="52687000">SAN LORENZO - NARIÑO</option>
                  <option value="52693000">SAN PABLO - NARIÑO</option>
                  <option value="52694000">SAN PEDRO DE CARTAGO - NARIÑO</option>
                  <option value="52696000">SANTA BÁRBARA - NARIÑO</option>
                  <option value="52699000">SANTACRUZ - NARIÑO</option>
                  <option value="52720000">SAPUYES - NARIÑO</option>
                  <option value="52786000">TAMINANGO - NARIÑO</option>
                  <option value="52788000">TANGUA - NARIÑO</option>
                  <option value="52835000">SAN ANDRES DE TUMACO - NARIÑO</option>
                  <option value="52838000">TÚQUERRES - NARIÑO</option>
                  <option value="52885000">YACUANQUER - NARIÑO</option>
                  <option value="54001000">CÚCUTA - NORTE DE SANTANDER</option>
                  <option value="54003000">ABREGO - NORTE DE SANTANDER</option>
                  <option value="54051000">ARBOLEDAS - NORTE DE SANTANDER</option>
                  <option value="54099000">BOCHALEMA - NORTE DE SANTANDER</option>
                  <option value="54109000">BUCARASICA - NORTE DE SANTANDER</option>
                  <option value="54125000">CÁCOTA - NORTE DE SANTANDER</option>
                  <option value="54128000">CACHIRÁ - NORTE DE SANTANDER</option>
                  <option value="54172000">CHINÁCOTA - NORTE DE SANTANDER</option>
                  <option value="54174000">CHITAGÁ - NORTE DE SANTANDER</option>
                  <option value="54206000">CONVENCIÓN - NORTE DE SANTANDER</option>
                  <option value="54223000">CUCUTILLA - NORTE DE SANTANDER</option>
                  <option value="54239000">DURANIA - NORTE DE SANTANDER</option>
                  <option value="54245000">EL CARMEN - NORTE DE SANTANDER</option>
                  <option value="54250000">EL TARRA - NORTE DE SANTANDER</option>
                  <option value="54261000">EL ZULIA - NORTE DE SANTANDER</option>
                  <option value="54313000">GRAMALOTE - NORTE DE SANTANDER</option>
                  <option value="54344000">HACARÍ - NORTE DE SANTANDER</option>
                  <option value="54347000">HERRÁN - NORTE DE SANTANDER</option>
                  <option value="54377000">LABATECA - NORTE DE SANTANDER</option>
                  <option value="54385000">LA ESPERANZA - NORTE DE SANTANDER</option>
                  <option value="54398000">LA PLAYA - NORTE DE SANTANDER</option>
                  <option value="54405000">LOS PATIOS - NORTE DE SANTANDER</option>
                  <option value="54418000">LOURDES - NORTE DE SANTANDER</option>
                  <option value="54480000">MUTISCUA - NORTE DE SANTANDER</option>
                  <option value="54498000">OCAÑA - NORTE DE SANTANDER</option>
                  <option value="54518000">PAMPLONA - NORTE DE SANTANDER</option>
                  <option value="54520000">PAMPLONITA - NORTE DE SANTANDER</option>
                  <option value="54553000">PUERTO SANTANDER - NORTE DE SANTANDER</option>
                  <option value="54599000">RAGONVALIA - NORTE DE SANTANDER</option>
                  <option value="54660000">SALAZAR - NORTE DE SANTANDER</option>
                  <option value="54670000">SAN CALIXTO - NORTE DE SANTANDER</option>
                  <option value="54673000">SAN CAYETANO - NORTE DE SANTANDER</option>
                  <option value="54680000">SANTIAGO - NORTE DE SANTANDER</option>
                  <option value="54720000">SARDINATA - NORTE DE SANTANDER</option>
                  <option value="54743000">SILOS - NORTE DE SANTANDER</option>
                  <option value="54800000">TEORAMA - NORTE DE SANTANDER</option>
                  <option value="54810000">TIBÚ - NORTE DE SANTANDER</option>
                  <option value="54820000">TOLEDO - NORTE DE SANTANDER</option>
                  <option value="54871000">VILLA CARO - NORTE DE SANTANDER</option>
                  <option value="54874000">VILLA DEL ROSARIO - NORTE DE SANTANDER</option>
                  <option value="86001000">MOCOA - PUTUMAYO</option>
                  <option value="86219000">COLÓN - PUTUMAYO</option>
                  <option value="86320000">ORITO - PUTUMAYO</option>
                  <option value="86568000">PUERTO ASÍS - PUTUMAYO</option>
                  <option value="86569000">PUERTO CAICEDO - PUTUMAYO</option>
                  <option value="86571000">PUERTO GUZMÁN - PUTUMAYO</option>
                  <option value="86573000">PUERTO LEGUÍZAMO - PUTUMAYO</option>
                  <option value="86749000">SIBUNDOY - PUTUMAYO</option>
                  <option value="86755000">SAN FRANCISCO - PUTUMAYO</option>
                  <option value="86757000">SAN MIGUEL - PUTUMAYO</option>
                  <option value="86760000">SANTIAGO - PUTUMAYO</option>
                  <option value="86865000">VALLE DEL GUAMUEZ - PUTUMAYO</option>
                  <option value="86885000">VILLAGARZÓN - PUTUMAYO</option>
                  <option value="63001000">ARMENIA - QUINDÍO</option>
                  <option value="63111000">BUENAVISTA - QUINDÍO</option>
                  <option value="63130000">CALARCA - QUINDÍO</option>
                  <option value="63190000">CIRCASIA - QUINDÍO</option>
                  <option value="63212000">CÓRDOBA - QUINDÍO</option>
                  <option value="63272000">FILANDIA - QUINDÍO</option>
                  <option value="63302000">GÉNOVA - QUINDÍO</option>
                  <option value="63401000">LA TEBAIDA - QUINDÍO</option>
                  <option value="63470000">MONTENEGRO - QUINDÍO</option>
                  <option value="63548000">PIJAO - QUINDÍO</option>
                  <option value="63594000">QUIMBAYA - QUINDÍO</option>
                  <option value="63690000">SALENTO - QUINDÍO</option>
                  <option value="66001000">PEREIRA - RISARALDA</option>
                  <option value="66045000">APÍA - RISARALDA</option>
                  <option value="66075000">BALBOA - RISARALDA</option>
                  <option value="66088000">BELÉN DE UMBRÍA - RISARALDA</option>
                  <option value="66170000">DOSQUEBRADAS - RISARALDA</option>
                  <option value="66318000">GUÁTICA - RISARALDA</option>
                  <option value="66383000">LA CELIA - RISARALDA</option>
                  <option value="66400000">LA VIRGINIA - RISARALDA</option>
                  <option value="66440000">MARSELLA - RISARALDA</option>
                  <option value="66456000">MISTRATÓ - RISARALDA</option>
                  <option value="66572000">PUEBLO RICO - RISARALDA</option>
                  <option value="66594000">QUINCHÍA - RISARALDA</option>
                  <option value="66682000">SANTA ROSA DE CABAL - RISARALDA</option>
                  <option value="66687000">SANTUARIO - RISARALDA</option>
                  <option value="88001000">SAN ANDRÉS - SAN ANDRÉS</option>
                  <option value="88564000">PROVIDENCIA - SAN ANDRÉS</option>
                  <option value="68001000">BUCARAMANGA - SANTANDER</option>
                  <option value="68013000">AGUADA - SANTANDER</option>
                  <option value="68020000">ALBANIA - SANTANDER</option>
                  <option value="68051000">ARATOCA - SANTANDER</option>
                  <option value="68077000">BARBOSA - SANTANDER</option>
                  <option value="68079000">BARICHARA - SANTANDER</option>
                  <option value="68081000">BARRANCABERMEJA - SANTANDER</option>
                  <option value="68092000">BETULIA - SANTANDER</option>
                  <option value="68101000">BOLÍVAR - SANTANDER</option>
                  <option value="68121000">CABRERA - SANTANDER</option>
                  <option value="68132000">CALIFORNIA - SANTANDER</option>
                  <option value="68147000">CAPITANEJO - SANTANDER</option>
                  <option value="68152000">CARCASÍ - SANTANDER</option>
                  <option value="68160000">CEPITÁ - SANTANDER</option>
                  <option value="68162000">CERRITO - SANTANDER</option>
                  <option value="68167000">CHARALÁ - SANTANDER</option>
                  <option value="68169000">CHARTA - SANTANDER</option>
                  <option value="68176000">CHIMA - SANTANDER</option>
                  <option value="68179000">CHIPATÁ - SANTANDER</option>
                  <option value="68190000">CIMITARRA - SANTANDER</option>
                  <option value="68207000">CONCEPCIÓN - SANTANDER</option>
                  <option value="68209000">CONFINES - SANTANDER</option>
                  <option value="68211000">CONTRATACIÓN - SANTANDER</option>
                  <option value="68217000">COROMORO - SANTANDER</option>
                  <option value="68229000">CURITÍ - SANTANDER</option>
                  <option value="68235000">EL CARMEN DE CHUCURÍ - SANTANDER</option>
                  <option value="68245000">EL GUACAMAYO - SANTANDER</option>
                  <option value="68250000">EL PEÑÓN - SANTANDER</option>
                  <option value="68255000">EL PLAYÓN - SANTANDER</option>
                  <option value="68264000">ENCINO - SANTANDER</option>
                  <option value="68266000">ENCISO - SANTANDER</option>
                  <option value="68271000">FLORIÁN - SANTANDER</option>
                  <option value="68276000">FLORIDABLANCA - SANTANDER</option>
                  <option value="68296000">GALÁN - SANTANDER</option>
                  <option value="68298000">GAMBITA - SANTANDER</option>
                  <option value="68307000">GIRÓN - SANTANDER</option>
                  <option value="68318000">GUACA - SANTANDER</option>
                  <option value="68320000">GUADALUPE - SANTANDER</option>
                  <option value="68322000">GUAPOTÁ - SANTANDER</option>
                  <option value="68324000">GUAVATÁ - SANTANDER</option>
                  <option value="68327000">GÜEPSA - SANTANDER</option>
                  <option value="68344000">HATO - SANTANDER</option>
                  <option value="68368000">JESÚS MARÍA - SANTANDER</option>
                  <option value="68370000">JORDÁN - SANTANDER</option>
                  <option value="68377000">LA BELLEZA - SANTANDER</option>
                  <option value="68385000">LANDÁZURI - SANTANDER</option>
                  <option value="68397000">LA PAZ - SANTANDER</option>
                  <option value="68406000">LEBRIJA - SANTANDER</option>
                  <option value="68418000">LOS SANTOS - SANTANDER</option>
                  <option value="68425000">MACARAVITA - SANTANDER</option>
                  <option value="68432000">MÁLAGA - SANTANDER</option>
                  <option value="68444000">MATANZA - SANTANDER</option>
                  <option value="68464000">MOGOTES - SANTANDER</option>
                  <option value="68468000">MOLAGAVITA - SANTANDER</option>
                  <option value="68498000">OCAMONTE - SANTANDER</option>
                  <option value="68500000">OIBA - SANTANDER</option>
                  <option value="68502000">ONZAGA - SANTANDER</option>
                  <option value="68522000">PALMAR - SANTANDER</option>
                  <option value="68524000">PALMAS DEL SOCORRO - SANTANDER</option>
                  <option value="68533000">PÁRAMO - SANTANDER</option>
                  <option value="68547000">PIEDECUESTA - SANTANDER</option>
                  <option value="68549000">PINCHOTE - SANTANDER</option>
                  <option value="68572000">PUENTE NACIONAL - SANTANDER</option>
                  <option value="68573000">PUERTO PARRA - SANTANDER</option>
                  <option value="68575000">PUERTO WILCHES - SANTANDER</option>
                  <option value="68615000">RIONEGRO - SANTANDER</option>
                  <option value="68655000">SABANA DE TORRES - SANTANDER</option>
                  <option value="68669000">SAN ANDRÉS - SANTANDER</option>
                  <option value="68673000">SAN BENITO - SANTANDER</option>
                  <option value="68679000">SAN GIL - SANTANDER</option>
                  <option value="68682000">SAN JOAQUÍN - SANTANDER</option>
                  <option value="68684000">SAN JOSÉ DE MIRANDA - SANTANDER</option>
                  <option value="68686000">SAN MIGUEL - SANTANDER</option>
                  <option value="68689000">SAN VICENTE DE CHUCURÍ - SANTANDER</option>
                  <option value="68705000">SANTA BÁRBARA - SANTANDER</option>
                  <option value="68720000">SANTA HELENA DEL OPÓN - SANTANDER</option>
                  <option value="68745000">SIMACOTA - SANTANDER</option>
                  <option value="68755000">SOCORRO - SANTANDER</option>
                  <option value="68770000">SUAITA - SANTANDER</option>
                  <option value="68773000">SUCRE - SANTANDER</option>
                  <option value="68780000">SURATÁ - SANTANDER</option>
                  <option value="68820000">TONA - SANTANDER</option>
                  <option value="68855000">VALLE DE SAN JOSÉ - SANTANDER</option>
                  <option value="68861000">VÉLEZ - SANTANDER</option>
                  <option value="68867000">VETAS - SANTANDER</option>
                  <option value="68872000">VILLANUEVA - SANTANDER</option>
                  <option value="68895000">ZAPATOCA - SANTANDER</option>
                  <option value="70001000">SINCELEJO - SUCRE</option>
                  <option value="70110000">BUENAVISTA - SUCRE</option>
                  <option value="70124000">CAIMITO - SUCRE</option>
                  <option value="70204000">COLOSO - SUCRE</option>
                  <option value="70215000">COROZAL - SUCRE</option>
                  <option value="70221000">COVEÑAS - SUCRE</option>
                  <option value="70230000">CHALÁN - SUCRE</option>
                  <option value="70233000">EL ROBLE - SUCRE</option>
                  <option value="70235000">GALERAS - SUCRE</option>
                  <option value="70265000">GUARANDA - SUCRE</option>
                  <option value="70400000">LA UNIÓN - SUCRE</option>
                  <option value="70418000">LOS PALMITOS - SUCRE</option>
                  <option value="70429000">MAJAGUAL - SUCRE</option>
                  <option value="70473000">MORROA - SUCRE</option>
                  <option value="70508000">OVEJAS - SUCRE</option>
                  <option value="70523000">PALMITO - SUCRE</option>
                  <option value="70670000">SAMPUÉS - SUCRE</option>
                  <option value="70678000">SAN BENITO ABAD - SUCRE</option>
                  <option value="70702000">SAN JUAN DE BETULIA - SUCRE</option>
                  <option value="70708000">SAN MARCOS - SUCRE</option>
                  <option value="70713000">SAN ONOFRE - SUCRE</option>
                  <option value="70717000">SAN PEDRO - SUCRE</option>
                  <option value="70742000">SAN LUIS DE SINCÉ - SUCRE</option>
                  <option value="70771000">SUCRE - SUCRE</option>
                  <option value="70820000">SANTIAGO DE TOLÚ - SUCRE</option>
                  <option value="70823000">TOLÚ VIEJO - SUCRE</option>
                  <option value="73001000">IBAGUÉ - TOLIMA</option>
                  <option value="73024000">ALPUJARRA - TOLIMA</option>
                  <option value="73026000">ALVARADO - TOLIMA</option>
                  <option value="73030000">AMBALEMA - TOLIMA</option>
                  <option value="73043000">ANZOÁTEGUI - TOLIMA</option>
                  <option value="73055000">ARMERO - TOLIMA</option>
                  <option value="73067000">ATACO - TOLIMA</option>
                  <option value="73124000">CAJAMARCA - TOLIMA</option>
                  <option value="73148000">CARMEN DE APICALÁ - TOLIMA</option>
                  <option value="73152000">CASABIANCA - TOLIMA</option>
                  <option value="73168000">CHAPARRAL - TOLIMA</option>
                  <option value="73200000">COELLO - TOLIMA</option>
                  <option value="73217000">COYAIMA - TOLIMA</option>
                  <option value="73226000">CUNDAY - TOLIMA</option>
                  <option value="73236000">DOLORES - TOLIMA</option>
                  <option value="73268000">ESPINAL - TOLIMA</option>
                  <option value="73270000">FALAN - TOLIMA</option>
                  <option value="73275000">FLANDES - TOLIMA</option>
                  <option value="73283000">FRESNO - TOLIMA</option>
                  <option value="73319000">GUAMO - TOLIMA</option>
                  <option value="73347000">HERVEO - TOLIMA</option>
                  <option value="73349000">HONDA - TOLIMA</option>
                  <option value="73352000">ICONONZO - TOLIMA</option>
                  <option value="73408000">LÉRIDA - TOLIMA</option>
                  <option value="73411000">LÍBANO - TOLIMA</option>
                  <option value="73443000">SAN SEBASTIÁN DE MARIQUITA - TOLIMA</option>
                  <option value="73449000">MELGAR - TOLIMA</option>
                  <option value="73461000">MURILLO - TOLIMA</option>
                  <option value="73483000">NATAGAIMA - TOLIMA</option>
                  <option value="73504000">ORTEGA - TOLIMA</option>
                  <option value="73520000">PALOCABILDO - TOLIMA</option>
                  <option value="73547000">PIEDRAS - TOLIMA</option>
                  <option value="73555000">PLANADAS - TOLIMA</option>
                  <option value="73563000">PRADO - TOLIMA</option>
                  <option value="73585000">PURIFICACIÓN - TOLIMA</option>
                  <option value="73616000">RIOBLANCO - TOLIMA</option>
                  <option value="73622000">RONCESVALLES - TOLIMA</option>
                  <option value="73624000">ROVIRA - TOLIMA</option>
                  <option value="73671000">SALDAÑA - TOLIMA</option>
                  <option value="73675000">SAN ANTONIO - TOLIMA</option>
                  <option value="73678000">SAN LUIS - TOLIMA</option>
                  <option value="73686000">SANTA ISABEL - TOLIMA</option>
                  <option value="73770000">SUÁREZ - TOLIMA</option>
                  <option value="73854000">VALLE DE SAN JUAN - TOLIMA</option>
                  <option value="73861000">VENADILLO - TOLIMA</option>
                  <option value="73870000">VILLAHERMOSA - TOLIMA</option>
                  <option value="73873000">VILLARRICA - TOLIMA</option>
                  <option value="76001000">CALI - VALLE DEL CAUCA</option>
                  <option value="76020000">ALCALÁ - VALLE DEL CAUCA</option>
                  <option value="76036000">ANDALUCÍA - VALLE DEL CAUCA</option>
                  <option value="76041000">ANSERMANUEVO - VALLE DEL CAUCA</option>
                  <option value="76054000">ARGELIA - VALLE DEL CAUCA</option>
                  <option value="76100000">BOLÍVAR - VALLE DEL CAUCA</option>
                  <option value="76109000">BUENAVENTURA - VALLE DEL CAUCA</option>
                  <option value="76111000">GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
                  <option value="76113000">BUGALAGRANDE - VALLE DEL CAUCA</option>
                  <option value="76122000">CAICEDONIA - VALLE DEL CAUCA</option>
                  <option value="76126000">CALIMA - VALLE DEL CAUCA</option>
                  <option value="76130000">CANDELARIA - VALLE DEL CAUCA</option>
                  <option value="76147000">CARTAGO - VALLE DEL CAUCA</option>
                  <option value="76233000">DAGUA - VALLE DEL CAUCA</option>
                  <option value="76243000">EL ÁGUILA - VALLE DEL CAUCA</option>
                  <option value="76246000">EL CAIRO - VALLE DEL CAUCA</option>
                  <option value="76248000">EL CERRITO - VALLE DEL CAUCA</option>
                  <option value="76250000">EL DOVIO - VALLE DEL CAUCA</option>
                  <option value="76275000">FLORIDA - VALLE DEL CAUCA</option>
                  <option value="76306000">GINEBRA - VALLE DEL CAUCA</option>
                  <option value="76318000">GUACARÍ - VALLE DEL CAUCA</option>
                  <option value="76364000">JAMUNDÍ - VALLE DEL CAUCA</option>
                  <option value="76377000">LA CUMBRE - VALLE DEL CAUCA</option>
                  <option value="76400000">LA UNIÓN - VALLE DEL CAUCA</option>
                  <option value="76403000">LA VICTORIA - VALLE DEL CAUCA</option>
                  <option value="76497000">OBANDO - VALLE DEL CAUCA</option>
                  <option value="76520000">PALMIRA - VALLE DEL CAUCA</option>
                  <option value="76563000">PRADERA - VALLE DEL CAUCA</option>
                  <option value="76606000">RESTREPO - VALLE DEL CAUCA</option>
                  <option value="76616000">RIOFRÍO - VALLE DEL CAUCA</option>
                  <option value="76622000">ROLDANILLO - VALLE DEL CAUCA</option>
                  <option value="76670000">SAN PEDRO - VALLE DEL CAUCA</option>
                  <option value="76736000">SEVILLA - VALLE DEL CAUCA</option>
                  <option value="76823000">TORO - VALLE DEL CAUCA</option>
                  <option value="76828000">TRUJILLO - VALLE DEL CAUCA</option>
                  <option value="76834000">TULUÁ - VALLE DEL CAUCA</option>
                  <option value="76845000">ULLOA - VALLE DEL CAUCA</option>
                  <option value="76863000">VERSALLES - VALLE DEL CAUCA</option>
                  <option value="76869000">VIJES - VALLE DEL CAUCA</option>
                  <option value="76890000">YOTOCO - VALLE DEL CAUCA</option>
                  <option value="76892000">YUMBO - VALLE DEL CAUCA</option>
                  <option value="76895000">ZARZAL - VALLE DEL CAUCA</option>
                  <option value="97001000">MITÚ - VAUPÉS</option>
                  <option value="97161000">CARURU - VAUPÉS</option>
                  <option value="97511000">PACOA - VAUPÉS</option>
                  <option value="97666000">TARAIRA - VAUPÉS</option>
                  <option value="97777000">PAPUNAUA - VAUPÉS</option>
                  <option value="97889000">YAVARATÉ - VAUPÉS</option>
                  <option value="99001000">PUERTO CARREÑO - VICHADA</option>
                  <option value="99524000">LA PRIMAVERA - VICHADA</option>
                  <option value="99624000">SANTA ROSALÍA - VICHADA</option>
                  <option value="99773000">CUMARIBO - VICHADA</option>
                  <option value="1000001">Ciudad de México</option>
                  <option value="91263000">EL ENCANTO - AMAZONAS</option>
                  <option value="91405000">LA CHORRERA - AMAZONAS</option>
                  <option value="91407000">LA PEDRERA - AMAZONAS</option>
                  <option value="91001000">LETICIA - AMAZONAS</option>
                  <option value="91430000">LA VICTORIA PACOA - AMAZONAS</option>
                  <option value="91460000">MIRITI - PARANÁ - AMAZONAS</option>
                  <option value="91530000">PUERTO ALEGRIA - AMAZONAS</option>
                  <option value="91536000">PUERTO ARICA - AMAZONAS</option>
                  <option value="91540000">PUERTO NARIÑO - AMAZONAS</option>
                  <option value="91669000">PUERTO SANTANDER - AMAZONAS</option>
                  <option value="91798000">TARAPACÁ - AMAZONAS</option>
                  <option value="05002000">ABEJORRAL - ANTIOQUIA</option>
                  <option value="05004000">ABRIAQUÍ - ANTIOQUIA</option>
                  <option value="05021000">ALEJANDRÍA - ANTIOQUIA</option>
                  <option value="05030000">AMAGÁ - ANTIOQUIA</option>
                  <option value="05031000">AMALFI - ANTIOQUIA</option>
                  <option value="05034000">ANDES - ANTIOQUIA</option>
                  <option value="05036000">ANGELÓPOLIS - ANTIOQUIA</option>
                  <option value="05038000">ANGOSTURA - ANTIOQUIA</option>
                  <option value="05040000">ANORÍ - ANTIOQUIA</option>
                  <option value="05042000">SANTAFÉ DE ANTIOQUIA - ANTIOQUIA</option>
                  <option value="05044000">ANZA - ANTIOQUIA</option>
                </Select>
              </div>
              <div className="form-group">
                <label htmlFor="declaredValue">Valor declarado</label>
                <input
                  type="text"
                  id="declaredValue"
                  name="declaredValue"
                  value={formattedDeclaredValue}
                  onChange={handleChange}
                  required
                  className="input-field"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="deliveryType">Tipo de Entrega</label>
                <select
                  id="deliveryType"
                  name="deliveryType"
                  value={formData.deliveryType}
                  onChange={handleChange}
                  required
                  className="input-field"
                >
                  <option value="" disabled>
                    Seleccione un tipo de entrega
                  </option>
                  <option value="direccion">ENTREGA EN DIRECCION</option>
                  <option value="centroAcopio">ENTREGA EN OFICINA</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="shipmentType">Tipo de Envío</label>
                <select
                  id="shipmentType"
                  name="shipmentType"
                  value={formData.shipmentType}
                  onChange={handleChange}
                  required
                  className="input-field"
                >
                  <option value="" disabled>
                    Seleccione un tipo de envío
                  </option>
                  <option value="paquete">PAQUETE</option>
                  <option value="documento">DOCUMENTO</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="grossWeight">Peso Bruto</label>
                <input
                  type="number"
                  id="grossWeight"
                  name="grossWeight"
                  value={formData.grossWeight}
                  onChange={handleChange}
                  placeholder="Ingrese el peso bruto"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="length">Largo</label>
                <input
                  type="number"
                  id="length"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  placeholder="Ingrese el largo"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="width">Ancho</label>
                <input
                  type="number"
                  id="width"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  placeholder="Ingrese el ancho"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="height">Alto</label>
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  placeholder="Ingrese el alto"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="volumetricWeight">Peso Volumétrico</label>
                <input
                  type="number"
                  id="volumetricWeight"
                  name="volumetricWeight"
                  value={volumetricWeight}
                  readOnly
                  className="input-field"
                />
              </div>
            </div>
            <div class="form-group checkbox-wrapper">
              <div class="checkbox-row">
                <label class="checkbox-label" style={{ width: '220px' }}>
                  <input
                    type="checkbox"
                    id="cashOnDelivery"
                    name="cashOnDelivery"
                    checked={formData.cashOnDelivery}
                    onChange={handleChange}
                    class="custom-checkbox"

                  />
                  <span class="checkbox-custom"></span>
                  <span class="checkbox-text">Pago Contra Entrega</span>
                </label>
                {/*<label class="checkbox-label">
                  <input
                    type="checkbox"
                    id="needsPickup"
                    name="needsPickup"
                    checked={formData.needsPickup}
                    onChange={handleChange}
                    class="custom-checkbox"
                  />
                  <span class="checkbox-custom"></span>
                  <span class="checkbox-text">Necesita Recolección</span>
                </label>*/}
              </div>
            </div>
            <button type="submit" className="submit-button" style={{ marginLeft: '0px' }} disabled={!!errorMessage}>Cotizar</button>
            {loading && <div className="loading-spinner">Loading...</div>}
          </form>
        </div>

        {cotizacionResult && (
          <div className="cotizacion-result-container">
            <h2>Costos y Fecha de Entrega</h2>
            <table className="result-table">
              <thead>
                <tr>
                  <th>Transportadora</th>
                  <th>Total Flete</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cotizacionResult.interrapidisimo && (
                  <tr>
                    <td><img src="/Images/inter.png" alt="Interrapidisimo" /></td>
                    <td>${(cotizacionResult.interrapidisimo.valor + cotizacionResult.interrapidisimo.sobreflete + cotizacionResult.interrapidisimo.valor_contrapago)?.toLocaleString() ?? 'N/A'}</td>
                    <td><button onClick={() => handleSelectTransportadora('interrapidisimo')}>Seleccionar</button></td>
                  </tr>
                )}
                {cotizacionResult.tcc && (
                  <tr>
                    <td><img src="/Images/tcc.svg" alt="TCC" /></td>
                    <td>${(cotizacionResult.tcc.valor + cotizacionResult.tcc.sobreflete + cotizacionResult.tcc.valor_contrapago)?.toLocaleString() ?? 'N/A'}</td>
                    <td><button onClick={() => handleSelectTransportadora('tcc')}>Seleccionar</button></td>
                  </tr>
                )}
                {cotizacionResult.servientrega && (
                  <tr>
                    <td><img src="/Images/servi.png" alt="Servientrega" /></td>
                    <td>${(cotizacionResult.servientrega.valor + cotizacionResult.servientrega.sobreflete + cotizacionResult.servientrega.valor_contrapago)?.toLocaleString() ?? 'N/A'}</td>
                    <td><button onClick={() => handleSelectTransportadora('servientrega')}>Seleccionar</button></td>
                  </tr>
                )}
                {cotizacionResult.coordinadora && (
                  <tr>
                    <td><img src="/Images/coordi.png" alt="Coordinadora" /></td>
                    <td>${(cotizacionResult.coordinadora.valor + cotizacionResult.coordinadora.sobreflete + (cotizacionResult.coordinadora.valor_contrapago || 0))?.toLocaleString() ?? 'N/A'}</td>
                    <td><button onClick={() => handleSelectTransportadora('coordinadora')}>Seleccionar</button></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-overlay">
        <div className="modal-header">
          <button type="button" className="close-button" onClick={closeModal}>
            &times;
          </button>
          <h2>Completar Información para Generar la Guía</h2>
        </div>
        <form>
          {formData.deliveryType === 'centroAcopio' && (
            <div className="modal-form-row">
              <div className="modal-form-group">
                <label htmlFor="numeroDocumento" className="required-label">Número de Documento (Destinatario) <span className="required-asterisk">*</span></label>
                <input
                  type="text"
                  id="numeroDocumento"
                  name="numeroDocumento"
                  value={formDataGuia.numeroDocumento}
                  onChange={handleInputChange}
                  required
                  className="input-field"
                />
              </div>
            </div>
          )}

          <div className="modal-form-row">
            <div className="modal-form-group">
              <label htmlFor="nombre" className="required-label">Nombre (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formDataGuia.nombre}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
            <div className="modal-form-group">
              <label htmlFor="primerApellido" className="required-label">Primer Apellido (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="primerApellido"
                name="primerApellido"
                value={formDataGuia.primerApellido}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-row">
            <div className="modal-form-group">
              <label htmlFor="telefono" className="required-label">Teléfono (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="telefono"
                name="telefono"
                value={formDataGuia.telefono}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-row">
            <div className="modal-form-group">
              <label htmlFor="direccion" className="required-label">Dirección de entrega (Lo más detallada posible, indicando si es Casa/Torre/Apto/Barrio) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="direccion"
                name="direccion"
                value={formDataGuia.direccion}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
            <div className="modal-form-group">
              <label htmlFor="correo" className="required-label">Correo Electrónico (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formDataGuia.correo}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-group">
            <label htmlFor="DiceContener" className="required-label">Dice contener: <span className="required-asterisk">*</span></label>
            <input
              type="text"
              id="DiceContener"
              name="DiceContener"
              value={formDataGuia.DiceContener}
              onChange={handleInputChange}
              required
              className="input-field"
            />
          </div>

          <button type="button" className="generate-button" onClick={handleGuiaSubmit}>Generar Guía</button>
        </form>
      </Modal>

    </>
  );
};

export default ShippingForm;
