import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import './DevolucionForm.css';
import NavbarDropshipping from '../../dropShipingInterface/navbarDropshipping/navbarDropshipping';
import { API_ROUTES } from '../../constants/apiRoutes';

const DevolucionForm = () => {
  const { idDevolucion } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: '',
    id_preenvio: '',
    id_destinatario: '',
    origen: '',
    id_sucursal: '',
    destino: '',
    motivo_devolucion: '',
    fecha_devolucion: '',
    created_at: '',
    updated_at: ''
  });

  useEffect(() => {
    if (idDevolucion) {
      const fetchDevolucion = async () => {
        try {
          const response = await axios.get(API_ROUTES.DEVOLUCION(idDevolucion));
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar la devolución:', error);
          Swal.fire('Error', 'No se pudo cargar la devolución', 'error');
        }
      };
      fetchDevolucion();
    }
  }, [idDevolucion]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (idDevolucion) {
        await axios.put(API_ROUTES.UPDATE_DEVOLUCION(idDevolucion), formData);
        Swal.fire('Éxito', 'Los cambios se han guardado correctamente', 'success');
      } else {
        await axios.post(API_ROUTES.CREATE_DEVOLUCION, formData);
        Swal.fire('Éxito', 'La devolución ha sido creada correctamente', 'success');
      }
      navigate('/devoluciones'); // Asumiendo que existe una ruta para listar devoluciones
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      Swal.fire('Error', 'No se pudo guardar los cambios', 'error');
    }
  };

  return (
    <>
      <NavbarDropshipping title="Gestión de Devoluciones" />
      <div className="form-container">
        <h2>{idDevolucion ? 'Actualizar Devolución' : 'Crear Nueva Devolución'}</h2>
        <form onSubmit={handleSubmit}>
          {idDevolucion && (
            <div className="form-group">
              <label>ID:</label>
              <input type="text" name="id" value={formData.id} disabled />
            </div>
          )}
          <div className="form-group">
            <label>ID Preenvío:</label>
            <input
              type="text"
              name="id_preenvio"
              value={formData.id_preenvio}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>ID Destinatario:</label>
            <input
              type="text"
              name="id_destinatario"
              value={formData.id_destinatario}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Origen:</label>
            <input
              type="text"
              name="origen"
              value={formData.origen}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>ID Sucursal:</label>
            <input
              type="text"
              name="id_sucursal"
              value={formData.id_sucursal}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Destino:</label>
            <input
              type="text"
              name="destino"
              value={formData.destino}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Motivo de Devolución:</label>
            <textarea
              name="motivo_devolucion"
              value={formData.motivo_devolucion}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Fecha de Devolución:</label>
            <input
              type="date"
              name="fecha_devolucion"
              value={formData.fecha_devolucion}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="save-button">
              {idDevolucion ? 'Guardar Cambios' : 'Crear Devolución'}
            </button>
            <button
              type="button"
              className="back-button"
              onClick={() => navigate('/dropshippingGlobal')}
            >
              Volver
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DevolucionForm;