import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import './BodegaForm.css';
import NavbarPickingAndPacking from '../NavbarPickingAndPacking/NavbarPickingAndPacking';
import { API_ROUTES } from '../../constants/apiRoutes';

const BodegaForm = () => {
  const { idBodega, idSucursal } = useParams(); // Obtenemos idBodega y idSucursal de la URL
  const navigate = useNavigate();

  // Estado para el formulario de la bodega
  const [formData, setFormData] = useState({
    id_bodega: '',
    nombre_bodega: '',
    ubicacion: '',
    capacidad_total: '',
    capacidad_disponible: '', // Capacidad disponible se calcula
    encargado_bodega: '',
    stock_actual: '',
    estado_bodega: 1,
  });

  // Cargar los datos de la bodega si estamos en modo edición
  useEffect(() => {
    if (idBodega) {
      // Función para obtener los datos de la bodega de la API
      const fetchBodega = async () => {
        try {
          const response = await axios.get(API_ROUTES.BODEGA(idBodega));
          const {
            id_bodega,
            nombre_bodega,
            ubicacion,
            capacidad_total,
            capacidad_disponible,
            encargado_bodega,
            stock_actual,
            estado_bodega
          } = response.data;

          // Solo asignamos los campos que queremos mostrar
          setFormData({
            id_bodega,
            nombre_bodega,
            ubicacion,
            capacidad_total,
            capacidad_disponible,
            encargado_bodega,
            stock_actual,
            estado_bodega: estado_bodega ? 1 : 0, // Convertimos a 1 o 0
          });
        } catch (error) {
          console.error('Error al cargar la bodega:', error);
          Swal.fire('Error', 'No se pudo cargar la bodega', 'error');
        }
      };
      fetchBodega(); // Llamada a la función de carga
    }
  }, [idBodega]);

  // Función para manejar los cambios en los campos editables
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: name === 'estado_bodega' ? parseInt(value, 10) : value };

      // Calcular la capacidad disponible solo si estamos creando una nueva bodega
      if (!idBodega && (name === 'capacidad_total' || name === 'stock_actual')) {
        const capacidad_total = parseInt(updatedData.capacidad_total, 10) || 0;
        const stock_actual = parseInt(updatedData.stock_actual, 10) || 0;
        updatedData.capacidad_disponible = capacidad_total - stock_actual;
      }

      return updatedData;
    });
  };

  // Función para guardar los cambios o crear una nueva bodega
  const handleSubmit = async (e) => {
    console.log();
    e.preventDefault();
    try {
      if (idBodega) {
        await axios.put(API_ROUTES.UPDATE_BODEGA(idBodega), {
          nombre_bodega: formData.nombre_bodega,
          capacidad_total: formData.capacidad_total,
          estado_bodega: formData.estado_bodega,
        });
        Swal.fire('Éxito', 'Los cambios se han guardado correctamente', 'success');
      } else {
        // Creación de nueva bodega
        await axios.post(API_ROUTES.CREATE_BODEGA, {
          nombre_bodega: formData.nombre_bodega,
          ubicacion: formData.ubicacion,
          capacidad_total: formData.capacidad_total,
          capacidad_disponible: formData.capacidad_disponible,
          encargado_bodega: formData.encargado_bodega,
          stock_actual: formData.stock_actual,
          estado_bodega: formData.estado_bodega,
        });
        Swal.fire('Éxito', 'La bodega ha sido creada correctamente', 'success');
      }
      navigate('/bodegas/' + formData.id_sucursal); // Redirigir a la lista de bodegas
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      Swal.fire('Error', 'No se pudo guardar los cambios', 'error');
    }
  };

  return (
    <>
      <NavbarPickingAndPacking title="Gestión de Bodegas" />
      <div className="form-container">
        <h2>{idBodega ? 'Actualizar Datos de Bodega' : 'Agregar Nueva Bodega'}</h2> {/* Título dinámico */}
        <form onSubmit={handleSubmit}>
          {idBodega && ( // Solo mostrar ID Bodega en modo edición
            <div className="form-group">
              <label>ID Bodega:</label>
              <input
                type="text"
                name="id_bodega"
                value={formData.id_bodega}
                disabled
              />
            </div>
          )}
          <div className="form-group">
            <label>Nombre Bodega:</label>
            <input
              type="text"
              name="nombre_bodega"
              value={formData.nombre_bodega}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Ubicación:</label>
            <input
              type="text"
              name="ubicacion"
              value={formData.ubicacion}
              onChange={handleInputChange}
              disabled={idBodega ? true : false}
            />
          </div>
          <div className="form-group">
            <label>Capacidad Total:</label>
            <input
              type="number"
              name="capacidad_total"
              value={formData.capacidad_total}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Capacidad Disponible:</label>
            <input
              type="number"
              name="capacidad_disponible"
              value={formData.capacidad_disponible}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Encargado de la Bodega:</label>
            <input
              type="text"
              name="encargado_bodega"
              value={formData.encargado_bodega}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Stock Actual:</label>
            <input
              type="number"
              name="stock_actual"
              value={formData.stock_actual}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Estado Bodega:</label>
            <select
              name="estado_bodega"
              value={formData.estado_bodega}
              onChange={handleInputChange}
            >
              <option value={1}>Activo</option>
              <option value={0}>Inactivo</option>
            </select>
          </div>
          <div className="form-actions">
            <button type="submit" className="save-button">
              {idBodega ? 'Guardar Cambios' : 'Agregar Bodega'}
            </button>
            <button
              type="button"
              className="back-button"
              onClick={() => navigate(`/bodegas/${formData.id_sucursal}`)}
            >
              Volver
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BodegaForm;
