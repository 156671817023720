import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2';
import './NavbarPickingAndPacking.css';
import { API_ROUTES } from '../../constants/apiRoutes';
import { API_MESSAGES } from '../../constants/messages';

const NavbarPickingAndPacking = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [userId, setUserId] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [formData, setFormData] = useState({ id_sucursal: null }); // Inicializar id_sucursal
  const navigate = useNavigate(); // Usar useNavigate para la navegación

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
      verifySucursal(storedId); // Verificar estado de suscripción al cargar
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  const verifySucursal = async (storedId) => {
    try {
      const response = await axios.get(API_ROUTES.VERIFY_SUCURSAL(storedId));
      const data = response.data;

      setFormData(prev => ({ ...prev, id_sucursal: data.codigo_sucursal }));

      if (data.message === API_MESSAGES.NO_SUSCRIPTOR_FOUND) {
        setIsSubscribed(false);
        Swal.fire({
          icon: 'warning',
          title: data.message,
          text: `Sucursal: ${data.sucursal.nombre_sucursal} (${data.codigo_sucursal})`,
        });
        navigate(`/bodegas/${data.codigo_sucursal}`);
      } else {
        setIsSubscribed(true);
      }
    } catch (error) {
      console.error('Error verifying sucursal:', error);
    }
  };

  return (
    <>
      <div className="NavbarPickingAndPacking-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="NavbarPickingAndPacking-logo">
          <p style={{ marginLeft: "250px" }}>{title}</p>
        </div>
        <div className="NavbarPickingAndPacking-userInfo">
          <p>Hola, Operador</p>
          <p className="NavbarPickingAndPacking-panelLink">Picking & Packing Panel</p>
          <div className="NavbarPickingAndPacking-userAvatar">
            <img src="/Images/avatar-operator.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`NavbarPickingAndPacking-container ${isMenuOpen ? 'show' : 'hide'}`}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="NavbarPickingAndPacking-title">
          <img src="/Images/download.png" alt="Download" className="NavbarPickingAndPacking-logo" />
        </div>
        <div className="NavbarPickingAndPacking-menu">
          <ul>
            <li>
              <NavLink to="/picking-packing" className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons">dashboard</span>Picking & Packing
              </NavLink>
            </li>
            <li>
              <NavLink to="/bodegas/registrar-bodega-sucursal" className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons">warehouse</span> Registrar Bodega
              </NavLink>
            </li>
            <li>
              <NavLink to={`/bodegas/${formData.id_sucursal}`} className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons">warehouse</span> Bodegas
              </NavLink>
            </li>
            <li>
              <NavLink to={`/bodegas/crear`} className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons">add_box</span>Crear Bodega
              </NavLink>
            </li>
            <li>
              <NavLink to={`/bodegas/gestion/${formData.id_sucursal}`} className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons">manage_accounts</span>Gestionar Bodega
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className={({ isActive }) => isActive ? "NavbarPickingAndPacking-active" : ""}>
                <span className="material-icons" onClick={handleLogout}>exit_to_app</span>Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavbarPickingAndPacking;
