import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarPickingAndPacking from '../NavbarPickingAndPacking/NavbarPickingAndPacking';
import { API_ROUTES } from '../../constants/apiRoutes';
import { API_MESSAGES } from '../../constants/messages';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../utils/errorHandler';

const BodegaRegister = () => {
  const [sucursal, setSucursal] = useState(null);
  const [bodegas, setBodegas] = useState([]);
  const [selectedBodega, setSelectedBodega] = useState('');

  const navigate = useNavigate();

  const verificarSucursalUsuario = async () => {
    const userId = localStorage.getItem('usuario_id');
    if (!userId) {
      console.error('No se encontró el ID de usuario en localStorage');
      return;
    }

    try {
      const response = await axios.get(API_ROUTES.VERIFY_SUCURSAL(userId));
      const data = response.data;

      if (data.message === API_MESSAGES.NO_SUSCRIPTOR_FOUND) {
        navigate('/PickingAndPacking');
      } else {
        setSucursal(data);
      }
    } catch (error) {
      console.error('Error al obtener los datos de la sucursal:', error);
    }
  };

  const obtenerBodegasActivas = async () => {
    try {
      const response = await axios.get(API_ROUTES.GET_BODEGA);
      const bodegasActivas = response.data.filter(bodega => bodega.estado_bodega === 1);
      setBodegas(bodegasActivas);
    } catch (error) {
      console.error('Error al obtener las bodegas activas:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await verificarSucursalUsuario();
      await obtenerBodegasActivas();
    };
    fetchData();
  }, []);

  const handleBodegaChange = (e) => {
    setSelectedBodega(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSucursal((prevSucursal) => ({
      ...prevSucursal,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const id_bodega = bodegas.find(bodega => bodega.nombre_bodega === selectedBodega)?.id_bodega;
    const codigo_sucursal = sucursal.codigo_sucursal;

    if (!id_bodega || !codigo_sucursal) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Faltan datos para enviar.',
      });
      return;
    }

    try {
      const response = await axios.post(API_ROUTES.REGISTER_BODEGA_SUCURSAL, {
        id_bodega,
        codigo_sucursal,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso',
          text: `Bodega registrada correctamente.`,
        });
        navigate(`/bodegas/${codigo_sucursal}`);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo registrar la bodega.',
        });
      }
    } catch (error) {
        const errorMessage = getErrorMessage(error, 'Hubo un problema al registrar la bodega.');
        console.error('Error al registrar la bodega:', errorMessage);
    
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
        });
    }
  };

  return (
    <>
      <NavbarPickingAndPacking title="Registro de Bodega" />
      <div className="bodega-selection-container centered-container">
        <h2 style={{ marginLeft: '20%' }}>Selecciona una Bodega</h2>
        {sucursal && (
          <div>
            <p><strong>Sucursal:</strong> {sucursal.nombre_sucursal} (ID: {sucursal.codigo_sucursal})</p>
          </div>
        )}
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label>Selecciona Bodega:</label>
            <select value={selectedBodega} onChange={handleBodegaChange} required>
              <option value="">Selecciona una bodega</option>
              {bodegas.map((bodega) => (
                <option key={bodega.id_bodega} value={bodega.nombre_bodega}>
                  {bodega.nombre_bodega} - {bodega.ubicacion}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Código de Sucursal:</label>
            <input
              type="text"
              name="codigo_sucursal" 
              value={sucursal?.codigo_sucursal || ''}
              onChange={handleInputChange}
              readOnly
            />
          </div>
          <button type="submit" className="submit-button" style={{ backgroundColor: 'blue' }}>
            Confirmar Selección
          </button>
        </form>
      </div>
    </>
  );
};

export default BodegaRegister;
