import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarPickingAndPacking from '../NavbarPickingAndPacking/NavbarPickingAndPacking';
import './Bodegas.css';
import '../PickingAndPacking.css';
import { API_ROUTES } from '../../constants/apiRoutes';

const BodegasCrud = () => {
  const [bodegas, setBodegas] = useState([]);
  const navigate = useNavigate();
  const { idSucursal } = useParams();

  // Opción 1: Extraer los detalles de la bodega directamente desde la respuesta anidada
  const fetchBodegas = async () => {
    try {
      // Obtener la lista de bodegas asociadas a la sucursal
      const response = await axios.get(API_ROUTES.GET_BODEGA_SUCURSAL(idSucursal));

      // Extraer los detalles de las bodegas anidadas en la propiedad 'bodega'
      const bodegasDetails = response.data.map((item) => item.bodega);

      // Actualizar el estado con los detalles de todas las bodegas
      setBodegas(bodegasDetails);
    } catch (error) {
      console.error('Error al listar las bodegas:', error);
      Swal.fire('Error', 'No se pudieron cargar las bodegas', 'error');
    }
  };

  useEffect(() => {
    if (idSucursal) {
      fetchBodegas();
    }
  }, [idSucursal]);

  return (
    <>
      <NavbarPickingAndPacking title="Gestión de Bodegas" />
      <div className="bodegas-crud-container centered-container">
        <table className="bodegas-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Ubicación</th>
              <th>Capacidad Disponible</th>
              <th>Capacidad Total</th>
              <th>Inventario Disponible</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {bodegas.map((bodega) => (
              <tr key={bodega.id_bodega}>
                <td>{bodega.id_bodega}</td>
                <td>{bodega.nombre_bodega}</td>
                <td>{bodega.ubicacion}</td>
                <td>{bodega.capacidad_disponible}</td>
                <td>{bodega.capacidad_total}</td>
                <td>{bodega.stock_actual}</td>
                <td>{bodega.estado_bodega ? 'Activa' : 'Inactiva'}</td>
                <td>
                  <button
                    onClick={() => navigate(`/bodegas/gestion/${idSucursal}/${bodega.id_bodega}`)}
                    className="gestionar-button"
                  >
                    Gestionar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BodegasCrud;
