import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './NavbarSales.css';

const NavbarSales = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };
  const nombre = localStorage.getItem('nombre');
  const rol_id = localStorage.getItem('rol_id');

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-tittle">
          <p>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, {nombre}</p>
          <p className="panel-link">99Panel</p>
          <div className="user-avatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
            <li>
              <NavLink to="/dashboardSales" activeClassName="active">
                <span className="material-icons">dashboard</span>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={`/PagoVendedores`} activeClassName="active">
                <span className="material-icons"> payments </span>Pagos
              </NavLink>
            </li>
            <li>
              <NavLink to={`/datosSucursales`} activeClassName="active">
                <span className="material-icons"> fact_check </span>Datos Sucursales
              </NavLink>
            </li>
            <li>
              <NavLink to={`/sucursalesComisionadas`} activeClassName="active">
                <span className="material-icons"> price_check </span>Sucursales Comisionadas
              </NavLink>
            </li>
            
            <li>
              <NavLink to="/" onClick={handleLogout} activeClassName="active">
                <span className="material-icons">exit_to_app</span>Cerrar sesión
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavbarSales;