import React, { useState } from 'react';
import { Button, Modal, Form, Input, message, Select, Upload, Row, Col, Checkbox } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import './agregarProductoShopify.css';

const { Option } = Select;

const AgregarProductoShopify = () => {
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [isStoreConfigModalVisible, setIsStoreConfigModalVisible] = useState(false);
    const [newProductData, setNewProductData] = useState({
        id_categoria: '',
        nombre_producto: '',
        descripcion_producto: '',
        cantidad_disponible: '',
        precio_proveedor: '',
        precio_sugerido: '',
        sku: '',
        imagenes: [],
        variaciones: [],
        estado_variacion: 0,
        peso_producto: '',
        storeConfig: {
            apiVersion: '',
            apiKey: '',
            password: '',
            sharedSecret: '',
            shopName: ''
        }
    });

    const [form] = Form.useForm();
    const [storeConfigForm] = Form.useForm();

    const resetNewProductData = () => {
        setNewProductData({
            id_categoria: '',
            nombre_producto: '',
            descripcion_producto: '',
            cantidad_disponible: '',
            precio_proveedor: '',
            precio_sugerido: '',
            sku: '',
            imagenes: [],
            variaciones: [],
            estado_variacion: 0,
            peso_producto: ''
        });
    };

    const resetForm = () => {
        form.resetFields();
        resetNewProductData();
    };

    const handleCancel = () => {
        setIsNewProductModalVisible(false);
        resetNewProductData();
    };

    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProductData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = ({ fileList }) => {
        setNewProductData(prev => ({
            ...prev,
            imagenes: fileList.map(file => {
                if (file.originFileObj) {
                    return {
                        ...file,
                        status: 'done',
                        url: URL.createObjectURL(file.originFileObj)
                    };
                }
                return file;
            })
        }));
    };

    const handleVariationsChange = (field, value, index) => {
        const updatedVariations = [...newProductData.variaciones];
        updatedVariations[index][field] = value;
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleAddVariation = () => {
        setNewProductData(prev => ({
            ...prev,
            variaciones: [...prev.variaciones, { talla: '', color: '', cantidad_disponible: '', sku: '' }]
        }));
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = newProductData.variaciones.filter((_, i) => i !== index);
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleNewProductSave = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();

            Object.keys(values).forEach(key => {
                if (key !== 'imagenes' && key !== 'variaciones') {
                    formData.append(key, values[key]);
                }
            });

            if (newProductData.imagenes.length > 0) {
                newProductData.imagenes.forEach((file, index) => {
                    if (file.originFileObj) {
                        formData.append(`imagenes[${index}]`, file.originFileObj);
                    }
                });
            }

            if (newProductData.estado_variacion === 1) {
                newProductData.variaciones.forEach((variation, index) => {
                    Object.keys(variation).forEach(key => {
                        formData.append(`variaciones[${index}][${key}]`, variation[key]);
                    });
                });
            }

            const id = localStorage.getItem('usuario_id');
            const url = `http://127.0.0.1:8000/api/integration/shopify/v1/productos/1`; //id  = 1  
            //const url = `http://127.0.0.1:8000/api/integration/shopify/v1/productos${id_usersucursal}`;
            //const url = `https://api.99envios.app/api/inventarios/${id}`;
            await axios.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            message.success('Nuevo producto creado con éxito');
            setIsNewProductModalVisible(false);

            resetForm();

            setIsNewProductModalVisible(true);
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al crear el producto: ' + error.message);
        }
    };

    const handleCheckboxChange = (e) => {
        setNewProductData(prev => ({
            ...prev,
            estado_variacion: e.target.checked ? 1 : 0
        }));
    };

    const handleStoreConfigChange = (field, value) => {
        setNewProductData(prev => ({
            ...prev,
            storeConfig: {
                ...prev.storeConfig,
                [field]: value
            }
        }));
    };

    const handleStoreConfigSave = async () => {
        try {
            const values = await storeConfigForm.validateFields();
            setNewProductData(prev => ({
                ...prev,
                storeConfig: values
            }));
            setIsStoreConfigModalVisible(false);
            message.success('Configuración de la tienda guardada');
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al guardar la configuración de la tienda');
        }
    };

    return (
        <>
            <Button type="primary" onClick={() => { setIsNewProductModalVisible(true); resetNewProductData(); }}
                    style={{ 
                        width: '160px', 
                        height: '44px', 
                        marginBottom: '10px', 
                        backgroundColor: '#6F31F5',
                        borderColor: '#6F31F5' 
                    }}
                >
                Agregar Producto
            </Button>
            <Modal
                title="Agregar nuevo producto Shopify"
                visible={isNewProductModalVisible}
                onOk={handleNewProductSave}
                onCancel={handleCancel}
                centered
                width={1100}
                height={1400}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleNewProductSave}>
                        Agregar
                    </Button>,
                ]}
            >
                <div className="agregar-producto-shopify-ant-modal-body">
                    <Form form={form} layout="vertical" style={{ width: '100%' }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Categoría del producto" name="id_categoria">
                                    <Select
                                        onChange={(value) => handleNewProductChange({ target: { name: 'id_categoria', value } })}
                                        value={newProductData.id_categoria}
                                        placeholder="Escoge una categoría"
                                    >
                                        <option value="1">Bisuteria</option>
                                        <option value="2">Ropa Deportiva</option>
                                        <option value="3">Vaporizadores</option>
                                        <option value="4">Mascotas</option>
                                        <option value="5">Moda</option>
                                        <option value="6">Tecnologia</option>
                                        <option value="7">Cocina</option>
                                        <option value="8">Belleza</option>
                                        <option value="9">Salud</option>
                                        <option value="10">Hogar</option>
                                        <option value="11">Natural Home</option>
                                        <option value="12">Deportes</option>
                                        <option value="13">Sex Shop</option>
                                        <option value="14">Bebé</option>
                                        <option value="15">Aseo</option>
                                        <option value="16">Bienestar</option>
                                        <option value="17">Camping</option>
                                        <option value="18">Pesca</option>
                                        <option value="19">Defensa Personal</option>
                                        <option value="20">Vehiculos</option>
                                        <option value="21">Jugueteria</option>
                                        <option value="22">Otros</option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Nombre del producto" name="nombre_producto">
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="Nombre del producto"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Descripción del producto" name="descripcion_producto">
                                    <Input.TextArea
                                        onChange={handleNewProductChange}
                                        placeholder="Descripción"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cantidad disponible para enviar" name="cantidad_disponible">
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Cantidad"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Proveedor" name="precio_proveedor">
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio Proveedor"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="SKU" name="sku">
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="SKU"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Sugerido" name="precio_sugerido">
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio sugerido"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Peso" name="peso_producto">
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Peso"
                                        value={newProductData.peso_producto}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item label="Fotografía del producto" name="imagenes">
                                    <Upload
                                        listType="picture-card"
                                        fileList={newProductData.imagenes}
                                        onChange={handleFileChange}
                                        beforeUpload={() => false}
                                        multiple={true}
                                    >
                                        {newProductData.imagenes.length >= 8 ? null : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Button onClick={() => setIsStoreConfigModalVisible(true)}>
                                    Configuración de la tienda
                                </Button>
                            </Col>
                        </Row>
                        
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item name="estado_variacion" valuePropName="checked" initialValue={newProductData.estado_variacion} noStyle>
                                    <Checkbox
                                        onChange={handleCheckboxChange}
                                    >
                                        Añadir variaciones
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        {newProductData.estado_variacion && (
                            <>
                                {newProductData.variaciones.map((variation, index) => (
                                    <Row gutter={[16, 16]} key={index}>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Talla"
                                                name={['variaciones', index, 'talla']}
                                                initialValue={variation.talla}
                                            >
                                                <Select
                                                    value={variation.color}
                                                    onChange={(value) => handleVariationsChange('talla', value, index)}
                                                    placeholder="Selecciona una talla"
                                                    style={{ width: '80px' }}
                                                >
                                                    <Option value="3M">3M</Option>
                                                    <Option value="6M">6M</Option>
                                                    <Option value="9M">9M</Option>
                                                    <Option value="12M">12M</Option>
                                                    <Option value="18M">18M</Option>
                                                    <Option value="24M">24M</Option>
                                                    <Option value="XS">XS</Option>
                                                    <Option value="S">S</Option>
                                                    <Option value="M">M</Option>
                                                    <Option value="L">L</Option>
                                                    <Option value="XL">XL</Option>
                                                    <Option value="XXL">XXL</Option>
                                                    <Option value="XXXL">XXXL</Option>
                                                    <Option value="4XL">4XL</Option>
                                                    <Option value="5XL">5XL</Option>
                                                    <Option value="6XL">6XL</Option>
                                                    <Option value="6">6</Option>
                                                    <Option value="7">7</Option>
                                                    <Option value="8">8</Option>
                                                    <Option value="10">10</Option>
                                                    <Option value="12">12</Option>
                                                    <Option value="14">14</Option>
                                                    <Option value="16">16</Option>
                                                    <Option value="18">18</Option>
                                                    <Option value="19">19</Option>
                                                    <Option value="20">20</Option>
                                                    <Option value="21">21</Option>
                                                    <Option value="22">22</Option>
                                                    <Option value="23">23</Option>
                                                    <Option value="24">24</Option>
                                                    <Option value="25">25</Option>
                                                    <Option value="26">26</Option>
                                                    <Option value="27">27</Option>
                                                    <Option value="28">28</Option>
                                                    <Option value="29">29</Option>
                                                    <Option value="30">30</Option>
                                                    <Option value="31">31</Option>
                                                    <Option value="32">32</Option>
                                                    <Option value="33">33</Option>
                                                    <Option value="34">34</Option>
                                                    <Option value="35">35</Option>
                                                    <Option value="36">36</Option>
                                                    <Option value="37">37</Option>
                                                    <Option value="38">38</Option>
                                                    <Option value="39">39</Option>
                                                    <Option value="40">40</Option>
                                                    <Option value="41">41</Option>
                                                    <Option value="42">42</Option>
                                                    <Option value="43">43</Option>
                                                    <Option value="44">44</Option>
                                                    <Option value="45">45</Option>
                                                    <Option value="46">46</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Color"
                                                name={['variaciones', index, 'color']}
                                                initialValue={variation.talla}
                                            >
                                                <Select
                                                    value={variation.talla}
                                                    onChange={(value) => handleVariationsChange('color', value, index)}
                                                    placeholder="Selecciona un color"
                                                    style={{ width: '120px' }}
                                                >
                                                    <Option value="Negro">Negro</Option>
                                                    <Option value="Blanco">Blanco</Option>
                                                    <Option value="Gris">Gris</Option>
                                                    <Option value="Azul">Azul</Option>
                                                    <Option value="Rojo">Rojo</Option>
                                                    <Option value="Verde">Verde</Option>
                                                    <Option value="Amarillo">Amarillo</Option>
                                                    <Option value="Marrón">Marrón</Option>
                                                    <Option value="Rosa">Rosa</Option>
                                                    <Option value="Naranja">Naranja</Option>
                                                    <Option value="Violeta">Violeta</Option>
                                                    <Option value="Beige">Beige</Option>
                                                    <Option value="Celeste">Celeste</Option>
                                                    <Option value="Turquesa">Turquesa</Option>
                                                    <Option value="Dorado">Dorado</Option>
                                                    <Option value="Plateado">Plateado</Option>
                                                    <Option value="Coral">Coral</Option>
                                                    <Option value="Borgoña">Borgoña</Option>
                                                    <Option value="Lila">Lila</Option>
                                                    <Option value="Fucsia">Fucsia</Option>
                                                    <Option value="Oliva">Oliva</Option>
                                                    <Option value="Caqui">Caqui</Option>
                                                    <Option value="Índigo">Índigo</Option>
                                                    <Option value="Aqua">Aqua</Option>
                                                    <Option value="Lavanda">Lavanda</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Precio Sugerido" name="precio_sugerido">
                                                <Input
                                                    type="number"
                                                    onChange={handleNewProductChange}
                                                    placeholder="Precio sugerido"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Cantidad Disponible"
                                                name={['variaciones', index, 'cantidad_disponible']}
                                                initialValue={variation.cantidad_disponible}
                                            >
                                                <Input
                                                    type="number"
                                                    value={variation.cantidad_disponible}
                                                    onChange={(e) => handleVariationsChange('cantidad_disponible', e.target.value, index)}
                                                    placeholder="Cantidad"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="SKU"
                                                name={['variaciones', index, 'sku']}
                                                initialValue={variation.sku}
                                            >
                                                <Input
                                                    value={variation.sku}
                                                    onChange={(e) => handleVariationsChange('sku', e.target.value, index)}
                                                    placeholder="SKU"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                type="danger"
                                                icon={<MinusCircleOutlined />}
                                                onClick={() => handleRemoveVariation(index)}
                                            >
                                                Eliminar
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={handleAddVariation}
                                    icon={<PlusOutlined />}
                                    style={{ width: '100%', marginTop: '16px' }}
                                >
                                    Añadir variación
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            </Modal>
            <Modal title="Configuración de la tienda" visible={isStoreConfigModalVisible} onOk={handleStoreConfigSave} onCancel={() => setIsStoreConfigModalVisible(false)} centered >
                <Form form={storeConfigForm} layout="vertical">
                    <Form.Item label="Versión de la API" name="apiVersion">
                    <Input onChange={(e) => handleStoreConfigChange('apiVersion', e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Clave de la API" name="apiKey">
                    <Input onChange={(e) => handleStoreConfigChange('apiKey', e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Contraseña" name="password">
                    <Input.Password onChange={(e) => handleStoreConfigChange('password', e.target.value)} />
                    </Form.Item>
                    <Form.Item label="clave secreta compartida" name="sharedSecret">
                    <Input.Password onChange={(e) => handleStoreConfigChange('sharedSecret', e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Nombre de la tienda" name="shopName">
                    <Input onChange={(e) => handleStoreConfigChange('shopName', e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AgregarProductoShopify;