import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarPickingAndPacking from './NavbarPickingAndPacking/NavbarPickingAndPacking';
import './PickingAndPacking.css';
import { API_ROUTES } from '../constants/apiRoutes';
import { useNavigate } from 'react-router-dom';
import { API_MESSAGES } from '../constants/messages'; 

const PickingAndPacking = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [bodegas, setBodegas] = useState([]);
  const [formData, setFormData] = useState({
    id_sucursal: '',
    nombre_suscriptor: '',
    email: localStorage.getItem('email') || '',
    telefono: '',
    direccion: '',
    ciudad: ''
  });

  const navigate = useNavigate();

  const verificarSucursalUsuario = async () => {
    const userId = localStorage.getItem('usuario_id');
    if (!userId) {
      console.error('No se encontró el ID de usuario en localStorage');
      return;
    }

    try {
      const response = await axios.get(API_ROUTES.VERIFY_SUCURSAL(userId)); 
      const data = response.data;

      if (data.message === API_MESSAGES.NO_SUSCRIPTOR_FOUND) {
        setIsSubscribed(false);
        setFormData({
          id_sucursal: data.sucursal.codigo_sucursal || '',
          nombre_suscriptor: data.sucursal.nombre || '',
          email: localStorage.getItem('email') || '',
          telefono: data.sucursal.telefono || '',
          direccion: data.sucursal.direccion || '',
          ciudad: data.sucursal.id_ciudad || ''
        });

        Swal.fire({
          icon: 'warning',
          title: data.message,
          text: `Sucursal: ${data.sucursal.nombre_sucursal} (${data.codigo_sucursal})`,
        });
      } else {
        navigate(`/bodegas/${data.codigo_sucursal}`);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    verificarSucursalUsuario();
  }, []);

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/picking-and-packing/suscriptores', formData);
      Swal.fire({
        icon: 'success',
        title: 'Suscriptor registrado con éxito',
        text: `El suscriptor ${formData.nombre_suscriptor} ha sido registrado correctamente.`,
      });

      navigate(`/bodegas/${formData.id_sucursal}`);
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al registrar el suscriptor.',
      });
    }
  };

  const handleDisableBodega = async (bodegaId) => {
    try {
      const response = await axios.post(`http://127.0.0.1:8000/api/picking-and-packing/bodegas/disable/${bodegaId}`);

      Swal.fire({
        icon: 'success',
        title: 'Bodega deshabilitada',
        text: `La bodega ha sido deshabilitada correctamente.`,
      });

      setBodegas(bodegas.filter(bodega => bodega.id !== bodegaId));
    } catch (error) {
      console.error('Error al deshabilitar la bodega:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al deshabilitar la bodega.',
      });
    }
  };

  if (isSubscribed === null) {
    return (
      <>
        <NavbarPickingAndPacking title="Cargando..." />
        <div className="loading-container">
          <p>Cargando...</p>
        </div>
      </>
    );
  }

  if (isSubscribed) {
    return (
      <>
        <NavbarPickingAndPacking title="Lista de Bodegas" />
        <div className="bodegas-container centered-container">
          <h2>Lista de Bodegas</h2>
          <ul>
            {bodegas.map((bodega, index) => (
              <li key={index}>
                {bodega.nombre}
                <button
                  onClick={() => handleDisableBodega(bodega.id)}
                  className="disable-button"
                  style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white' }}
                >
                  Deshabilitar
                </button>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }

  return (
    <>
      <NavbarPickingAndPacking title="Registro de Suscriptor" />
      <div className="picking-and-packing-container centered-container">
        <h2>Formulario de Registro de Suscriptor</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label>ID Sucursal:</label>
            <input
              type="text"
              name="id_sucursal"
              value={formData.id_sucursal}
              onChange={(e) => setFormData({ ...formData, id_sucursal: e.target.value })}
              required
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Nombre del Suscriptor:</label>
            <input
              type="text"
              name="nombre_suscriptor"
              value={formData.nombre_suscriptor}
              onChange={(e) => setFormData({ ...formData, nombre_suscriptor: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Correo Electrónico:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Teléfono:</label>
            <input
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={(e) => setFormData({ ...formData, telefono: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Dirección:</label>
            <input
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={(e) => setFormData({ ...formData, direccion: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Ciudad:</label>
            <input
              type="text"
              name="ciudad"
              value={formData.ciudad}
              onChange={(e) => setFormData({ ...formData, ciudad: e.target.value })}
              required
            />
          </div>
          <button type="submit" className="submit-button" style={{ backgroundColor: 'blue' }}>
            Registrar
          </button>
        </form>
      </div>
    </>
  );
};

export default PickingAndPacking;
