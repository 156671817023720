import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Table, Radio } from 'antd';
import NavbarMex from '../components/navbarMex';
import './PaymentsModule.css';

const PaymentsModule = () => {
   const [availableBalance, setAvailableBalance] = useState(null);
   const [withdrawalAmount, setWithdrawalAmount] = useState('');
   const [withdrawals, setWithdrawals] = useState([]);
   const [banks, setBanks] = useState([]);
   const [selectedBankId, setSelectedBankId] = useState(null);
   const [error, setError] = useState('');
   const [sucursal] = useState(localStorage.getItem('usuario_id'));
   const [sucursalId, setSucursalId] = useState(null);
   const [showBankForm, setShowBankForm] = useState(false);
   const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
   const [isWarningModalVisible, setIsWarningModalVisible] = useState(false); // Modal de advertencia

   const [bankData, setBankData] = useState({
      codigo_banco: '',
      tipo_identificacion: '',
      numero_identificacion: '',
      tipo_cuenta: 2,
      numero_cuenta: ''
   });
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [lastThreeWithdrawalsSum, setLastThreeWithdrawalsSum] = useState(0); // Estado para la suma de los últimos 3 retiros

   useEffect(() => {
      fetchAvailableBalance();
   }, []);

   useEffect(() => {
      const fetchSucursalId = async () => {
         try {
            const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${sucursal}`);
            if (!response.ok) {
               throw new Error('Error fetching sucursal ID');
            }
            const data = await response.json();
            console.log('Sucursal ID:', data);
            setSucursalId(data); // Actualiza el estado con el ID de la sucursal
         } catch (error) {
            console.error('Error fetching sucursal ID:', error);
            return null;
         }
      };

      fetchSucursalId();
   }, [sucursal]);

   const fetchAvailableBalance = async () => {
      try {
        const user_id = localStorage.getItem('usuario_id');
        const token = localStorage.getItem('token'); // Obtener el token del localStorage
    
        const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': token ? `Bearer ${token}` : '',  // Añadir el token al encabezado si existe
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) throw new Error('Failed to fetch balance');
        
        const data = await response.json();
        
        setAvailableBalance(data.saldo_disponible);
        setWithdrawals(data.retiros || []); // Asegura que withdrawals sea siempre un array
        setBanks(data.bancos || []); // Asegura que banks sea siempre un array
        calculateLastThreeWithdrawalsSum(data.retiros || []);
        
      } catch (err) {
        setError('Error al obtener el saldo disponible');
        console.error(err);
      }
    };
    

   // Función para calcular la suma de los últimos tres retiros
   const calculateLastThreeWithdrawalsSum = (withdrawals) => {
      const lastThree = withdrawals.slice(-3); // Obtiene los últimos 3 retiros
      const sum = lastThree.reduce((acc, withdrawal) => acc + parseFloat(withdrawal.Monto), 0);
      setLastThreeWithdrawalsSum(sum);
   };

   const formatBalance = (balance) => {
      if (balance === null) return 'Cargando...';
      const absBalance = Math.abs(balance);
      const formattedBalance = absBalance.toLocaleString('es-CO', {
         style: 'currency',
         currency: 'COP',
         minimumFractionDigits: 0,
         maximumFractionDigits: 0
      });
      return balance < 0 ? `-${formattedBalance}` : formattedBalance;
   };

   const handleWithdrawal = (e) => {
      e.preventDefault();
      if (!selectedBankId) {
         setIsWarningModalVisible(true); // Mostrar modal de advertencia si no se ha seleccionado una cuenta
         return;
      }

      if (!withdrawalAmount || isNaN(withdrawalAmount) || withdrawalAmount <= 0) {
         setError('Por favor, ingrese una cantidad válida para retirar');
         return;
      }

      setIsConfirmModalVisible(true); // Mostrar modal de confirmación si todo está correcto
   };

   const confirmWithdrawal = async () => {
      try {
         const token = localStorage.getItem('token');
         const user_id = localStorage.getItem('usuario_id');
         const response = await fetch(`https://99envios.app/api/online/retirar-saldo/${user_id}`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ monto_retiro: parseFloat(withdrawalAmount) }),
         });

         if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al procesar el retiro');
         }

         const data = await response.json();

         setAvailableBalance(data.nuevo_saldo);
         setWithdrawals([
            {
               date: new Date().toISOString().split('T')[0],
               amount: withdrawalAmount,
               status: 'PROCESADO',
            },
            ...withdrawals,
         ]);

         setWithdrawalAmount('');
         setError('');
         fetchAvailableBalance(); // Actualiza la lista de retiros
         setIsConfirmModalVisible(false); // Ocultar el modal de confirmación
      } catch (err) {
         setError(err.message || 'Error al procesar el retiro');
         console.error(err);
         setIsConfirmModalVisible(false); // Ocultar el modal de confirmación en caso de error
      }
   };

   const handleBankDataChange = (e) => {
      const { name, value } = e.target;

      // Si el campo es tipo_cuenta, convertir el valor a número
      const processedValue = name === 'tipo_cuenta' ? parseInt(value, 10) : value;

      setBankData({
         ...bankData,
         [name]: processedValue,
      });
   };

   const handleBankDataSubmit = async () => {
      try {
         const token = localStorage.getItem('token');
         const response = await fetch(`https://99envios.app/api/online/bank-sucursal`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
               codigo_sucursal: sucursalId,
               codigo_banco: bankData.codigo_banco,
               numero_cuenta: bankData.numero_cuenta,
               tipo_cuenta: bankData.tipo_cuenta,
               numero_identificacion: bankData.numero_identificacion,
            }),
         });

         if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al registrar los datos bancarios');
         }

         const data = await response.json();
         message.success('Datos bancarios registrados exitosamente');
         setIsModalVisible(false); // Ocultar el modal después de registrar
      } catch (err) {
         message.error(err.message || 'Error al registrar los datos bancarios');
         console.error(err);
      }
   };

   const showModal = () => {
      setIsModalVisible(true);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
   };

   const onSelectBank = (id) => {
      setSelectedBankId(id);
   };

   const columns = [
      {
         title: 'Banco',
         dataIndex: 'nombre_banco',
         key: 'nombre_banco',
      },
      {
         title: 'Número de Cuenta',
         dataIndex: 'numero_cuenta',
         key: 'numero_cuenta',
      },
      {
         title: 'Seleccionar',
         key: 'select',
         render: (text, record) => (
            <Radio
               checked={selectedBankId === record.id_banco}
               onChange={() => onSelectBank(record.id_banco)}
            />
         ),
      },
   ];

   return (
      <>
         <NavbarMex title="Retiros" />
         <div className="payments-module">
            <h1 className="module-title">Módulo de Pagos</h1>
            <Button className='botonPagosCentrado' type="primary" onClick={showModal} style={{ backgroundColor: "#5b6bdf" }}>
               Añadir Datos Bancarios
            </Button>
            <div className="card-container">
               <div className="card total-available">
                  <i className="material-icons">account_balance</i>
                  <div className="card-content">
                     <p>Total Disponible</p>
                     <h2>{formatBalance(availableBalance)}</h2>
                  </div>
               </div>
               <div className="card withdrawals">
                  <i className="material-icons">favorite</i>
                  <div className="card-content">
                     <p>Suma de los Últimos 3 Retiros</p>
                     <h2>{formatBalance(lastThreeWithdrawalsSum)}</h2>
                     <p className="info-text">
                        ¡Emocionantes noticias! A partir de ahora, tienen tres retiros mensuales gratuitos. Y, además, hemos reducido el costo de los retiros adicionales a $3,000. ¡Más beneficios para ustedes!
                     </p>
                  </div>
               </div>
            </div>
            <form className="withdrawal-form" onSubmit={handleWithdrawal}>
               <label htmlFor="amount">Cantidad a Retirar</label>
               <input
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="0"
                  value={withdrawalAmount}
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
               />
               <button type="submit">Enviar</button>
            </form>
            {error && <div className="error-message">{error}</div>}

            <div className="bank-list">
               <h3>Bancos Asociados</h3>
               <Table
                  dataSource={banks}
                  columns={columns}
                  rowKey="id_banco"
                  pagination={false}
                  className="table-container"
               />
            </div>

            <div className="info-text">
               Nos encargamos de tus envíos, y queremos asegurarnos de que recibas tu pago sin problemas. Asegúrate de proporcionar tu información bancaria para que podamos hacerlo realidad.
            </div>

            <Modal
               title="Añadir Datos Bancarios"
               visible={isModalVisible}
               onOk={handleBankDataSubmit}
               onCancel={handleCancel}
               okText="Registrar"
               cancelText="Cancelar"
            >
               <form className="bank-form">
                  <label htmlFor="nombre_banco">Nombre banco</label>
                  <select name="codigo_banco" id="nombre_banco" value={bankData.codigo_banco} onChange={handleBankDataChange}>
                     <option value="" disabled>Seleccione su banco</option>
                     <option value="001">Banco de Bogotá</option>
                     <option value="002">Banco Popular</option>
                     <option value="006">Banco Itau</option>
                     <option value="007">Bancolombia</option>
                     <option value="009">Citibank</option>
                     <option value="012">Banco GNB Sudameris</option>
                     <option value="013">BBVA Colombia</option>
                     <option value="019">Scotiabank Colpatria</option>
                     <option value="023">Banco de Occidente</option>
                     <option value="031">Bancoldex</option>
                     <option value="032">Banco Caja Social BCSC</option>
                     <option value="040">Banco Agrario</option>
                     <option value="041">JP Morgan corporación Financión</option>
                     <option value="042">BNP Paribas</option>
                     <option value="047">Banco Mundo Mujer</option>
                     <option value="051">Davivienda</option>
                     <option value="052">Banco AVVillas</option>
                     <option value="053">Banco W S.A.</option>
                     <option value="059">Bancamia S.A</option>
                     <option value="060">Banco Pichincha</option>
                     <option value="061">Bancoomeva</option>
                     <option value="062">Banco Fallabella</option>
                     <option value="065">Santander</option>
                     <option value="066">Banco Cooperativo Coopcentral</option>
                     <option value="069">Banco Serfinanza</option>
                     <option value="070">Lulo Bank</option>
                     <option value="071">Banco JP Morgan Colombia S.A.</option>
                     <option value="292">Confiar</option>
                     <option value="303">Banco Union SA</option>
                     <option value="370">Coltefinanaciera</option>
                     <option value="558">Ban100</option>
                     <option value="560">Pibank</option>
                     <option value="637">Iris</option>
                     <option value="803">Powwi</option>
                     <option value="804">Ualá</option>
                     <option value="805">Banco BTG Pactual</option>
                     <option value="811">Rappipay</option>
                     <option value="812">Coink</option>
                  </select>

                  <label htmlFor="tipo_identificacion">Tipo de identificación:</label>
                  <select id="tipo_identificacion" name="tipo_identificacion" value={bankData.tipo_identificacion} onChange={handleBankDataChange} required>
                     <option value="03">CC</option>
                     <option value="04">CE</option>
                     <option value="02">RUT</option>
                     <option value="01">NIT</option>
                  </select>

                  <label htmlFor="numero_identificacion">Identificación del titular:</label>
                  <input type="text" id="numero_identificacion" name="numero_identificacion" value={bankData.numero_identificacion} onChange={handleBankDataChange} placeholder="Identificación" required />

                  <label htmlFor="tipo_cuenta">Tipo de Cuenta</label>
                  <select name="tipo_cuenta" id="tipo_cuenta" value={bankData.tipo_cuenta} onChange={handleBankDataChange}>
                     <option value={2}>Ahorros</option>
                     <option value={4}>Corriente</option>
                  </select>

                  <label htmlFor="numero_cuenta">Número de cuenta</label>
                  <input type="number" id="numero_cuenta" name="numero_cuenta" value={bankData.numero_cuenta} onChange={handleBankDataChange} required />
               </form>
            </Modal>

            <Modal
               title="Confirmar Retiro"
               visible={isConfirmModalVisible}
               onOk={confirmWithdrawal}
               onCancel={() => setIsConfirmModalVisible(false)}
               okText="Confirmar"
               cancelText="Cancelar"
            >
               <p>¿Estás seguro de que deseas retirar {formatBalance(parseFloat(withdrawalAmount))}?</p>
            </Modal>

            <Modal
               title="Advertencia"
               visible={isWarningModalVisible}
               onOk={() => setIsWarningModalVisible(false)}
               onCancel={() => setIsWarningModalVisible(false)}
               okText="Entendido"
            >
               <p>Por favor, seleccione una cuenta bancaria antes de realizar un retiro.</p>
            </Modal>

            <div className="withdrawals-table">
               <h3>Retiros realizados</h3>
               <table>
                  <thead>
                     <tr>
                        <th>Fecha</th>
                        <th>Valor Solicitado</th>
                        <th>Estado</th>
                     </tr>
                  </thead>
                  <tbody>
                     {withdrawals.map((withdrawal, index) => (
                        <tr key={index}>
                           <td>{withdrawal.Fecha}</td>
                           <td>{formatBalance(parseFloat(withdrawal.Monto))}</td>
                           <td>{withdrawal.tipo_transaccion === 'retiro' ? 'PROCESADO' : 'PENDIENTE'}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </>
   );
};

export default PaymentsModule;
