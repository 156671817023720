import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import './dashboardAdmin.css';

const DashboardAdminNew = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.99envios.app/api/online/homeadmin');
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <NavbarAdminMex title={"Dashboard"}/>
      <div className="dashboard-containerAD">
        {dashboardData ? (
          <div className="cards-containerAD">
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD local-shipping-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px'}}> people </span>
                </div>
                <h3>Sucursales</h3>
                <p className="main-number">{dashboardData.cantidad_sucursales}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD check-circle-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px'}}> balance </span>
                </div>
                <h3>Pagos</h3>
                <p className="main-number">{dashboardData.cantidad_paiments}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD report-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px'}}> thumb_up </span>
                </div>
                <h3>Preenvíos del Mes</h3>
                <p className="main-number">{dashboardData.cantidad_preenvios_mes}</p>
              </div>
            </div>
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD track-changes-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px'}}> thumb_down_alt </span>
                </div>
                <h3>Estados del Mes</h3>
                <table className="card-table-DM">
                  <tbody>
                    {dashboardData.cantidad_estados_mes.map((estado, index) => (
                      <tr key={index}>
                        <td>{estado.estado}</td>
                        <td className="number-cell-DM">{estado.cantidad}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD public-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px'}}> reply_all </span>
                </div>
                <h3>Devoluciones por Ciudad</h3>
                <table className="card-table-DM">
                  <tbody>
                    {dashboardData.cantidad_devolucion_ciudad_mes.length > 0 ? (
                  dashboardData.cantidad_devolucion_ciudad_mes.map((devolucion, index) => (
                      <tr key={index}>
                        <td>{devolucion.ciudad_nombre}</td>
                        <td className="number-cell-DM">{devolucion.cantidad}</td>
                      </tr>
                  ))
                ) : (
                  <p className="main-number">No hay devoluciones registradas este mes</p>
                )}
                  </tbody>
                </table>                
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </>
  );
}

export default DashboardAdminNew;
