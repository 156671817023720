import React from "react";
import { Layout, Alert, Card, Typography, Button, Carousel } from "antd";
import NavbarMex from "../components/navbarMex";
import { useNavigate } from "react-router-dom";
import "./inicioUsuario.css";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const nombre = localStorage.getItem('nombre'); 

const InicioUsuario = () => {
  const navigate = useNavigate();
  
  const slides = [
    { url: "/Images/slide-inicio-imagen1.jpg", alt: "Imagen 1" },
    { url: "/Images/slide-inicio-imagen2.jpg", alt: "Imagen 2" },
    { url: "/Images/slide-inicio-imagen3.jpg", alt: "Imagen 3" }
  ];

  return (
    <>
      <NavbarMex title={"Inicio"} />
      <Layout className="inicio-usuario-dashboard-layout">
        <Content className="inicio-usuario-dashboard-content">
            <Title level={2}>¡Hola, {nombre}!</Title>

          <Alert
            message={
              <div className="inicio-usuario-custom-alert">
                <div className="inicio-usuario-alert-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                </div>
                <span>
                  El paro de camioneros ha sido levantado y las actividades de transporte están retomando la normalidad. Estamos gestionando que los pedidos de 99envios reciban prioridad en la entrega.
                </span>
              </div>
            }
            type="info"
            showIcon={false}
            className="inicio-usuario-custom-alert-wrapper"
          />

          <Title level={3}>Informe y Métricas de tus Productos</Title>

          <div className="inicio-usuario-card-container">
            <Card className="inicio-usuario-custom-card">
              <Title level={3}>Cómo elegir productos para vender</Title>
              <Paragraph>
                Seleccionar productos exitosos para vender implica investigar el mercado, identificar tendencias y elegir productos con alta demanda y baja competencia. Es clave enfocarse en productos que resuelvan problemas o mejoren la vida de los clientes.
              </Paragraph>
            </Card>

            <Card className="inicio-usuario-custom-card">
              <Title level={3}>¿Qué son las métricas?</Title>
              <Paragraph>
                Las métricas te permiten medir el desempeño de tus productos y decisiones comerciales. Analizar datos clave como ventas, tráfico y conversión te ayuda a optimizar tu estrategia y maximizar las ganancias.
              </Paragraph>
              <Button type="primary" className="inicio-usuario-redirect-button" onClick={() => navigate("/dashboardMex")}>
                Ir al Dashboard
              </Button>
            </Card>
          </div>
        <Carousel autoplay className="inicio-usuario-carousel">
            {slides.map((slide, index) => (
            <div key={index}>
                <div className="inicio-usuario-carousel-slide">
                <img src={slide.url} alt={slide.alt} />
                </div>
            </div>
            ))}
        </Carousel>
        </Content>
      </Layout>
    </>
  );
};

export default InicioUsuario;