import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarMex from '../newInterface/components/navbarMex';
import AgregarProductoShopify from './shopifyInventario/agregarProductoShopify';
import ListarProductos from './shopifyInventario/listarProductos';
import './Shopify.css';
import { API_ROUTES } from '../constants/apiRoutes';
import { useNavigate } from 'react-router-dom';

const ShopSubscription = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [formData, setFormData] = useState({
    apiKey: '',
    password: '',
    sharedSecret: '',
    apiVersion: '',
    shopName: '',
    codigo_sucursal: '',
    userId: '' // Added userId to formData
  });

  const navigate = useNavigate();

  const verificarSucursalUsuario = async () => {
    const userId = localStorage.getItem('usuario_id');
    if (!userId) {
      console.error('No se encontró el ID de usuario en localStorage');
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      userId: userId,
    }));

    try {
      const response = await axios.get(API_ROUTES.VERIFY_SUCURSAL_SHOPIFY(userId));
      const data = response.data;

      if (data.message === 'El suscriptor está activo.') {
        setIsSubscribed(true);

        setFormData((prevFormData) => ({
          ...prevFormData,
          sucursalId: data.codigo_sucursal
        }));

      } else {
        setIsSubscribed(false);

        setFormData((prevFormData) => ({
          ...prevFormData,
          codigo_sucursal: data.codigo_sucursal,
        }));

        Swal.fire({
          icon: 'warning',
          title: 'Sucursal no suscrita',
          text: 'Complete el formulario para suscribir la sucursal.',
        });
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    verificarSucursalUsuario();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_ROUTES.INTEGRATION_SHOPIFY(formData.codigo_sucursal), formData);
      Swal.fire({
        icon: 'success',
        title: 'Suscripción exitosa',
        text: 'La sucursal ha sido suscrita correctamente.',
      });
      navigate(`/shopify`);
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al registrar la suscripción.',
      });
    }
  };

  if (isSubscribed) {
    return (
      <>
        <NavbarMex title="Artículos Publicados" />
        <div className="articulos-container centered-container">
          <div className="agregar-producto-shopify-table-container">
            <AgregarProductoShopify />
            <ListarProductos userId={formData.userId} />
          </div>
          <h2>Artículos Publicados</h2>
        </div>
      </>
    );
  }

  return (
    <>
      <NavbarMex title="Integración a tienda" />
      <div className="shopify-container centered-container">
        <h2>Formulario de Integración a tienda</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label>Código Sucursal:</label>
            <input
              type="text"
              name="codigo_sucursal"
              value={formData.codigo_sucursal}
              onChange={(e) => setFormData({ ...formData, codigo_sucursal: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>API Key:</label>
            <input
              type="text"
              name="apiKey"
              value={formData.apiKey}
              onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shared Secret:</label>
            <input
              type="text"
              name="sharedSecret"
              value={formData.sharedSecret}
              onChange={(e) => setFormData({ ...formData, sharedSecret: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>API Version:</label>
            <input
              type="text"
              name="apiVersion"
              value={formData.apiVersion}
              onChange={(e) => setFormData({ ...formData, apiVersion: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shop Name:</label>
            <input
              type="text"
              name="shopName"
              value={formData.shopName}
              onChange={(e) => setFormData({ ...formData, shopName: e.target.value })}
              required
            />
          </div>
          <div className="button-container">
            <button type="submit" className="submit-button">
              Registrar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ShopSubscription;
