import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';
import './TableComponent.css';

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const usuario_id = localStorage.getItem('usuario_id');
  const navigate = useNavigate(); 

  useEffect(() => {
    axios.get(`https://api.99envios.app/api/online/envios_completos/${usuario_id}`)
      .then(response => {
        const transformedData = response.data.map(item => ({
          codigo: item.codigo_sucursal,
          fecha_envio: item.fecha_envio,
          numero_guia: item.numero_de_guia,
          producto: item.Producto,
          destinatario: item.nombre_destinatario,
          direccion: item.direccion_destinatario,
          telefono: item.telefono_destinatario,
          ciudad: item.ciudad_destino,
          valor: item.valor_comercial,
          estado: item.estado_del_envio,
          sucursal: item.nombre_sucursal,
          fecha_actualizacion: item.fecha_actualizacion,
          transportadora: item.numero_de_guia,
        }));
        setData(transformedData);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, [usuario_id]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    Object.values(item).some(val => 
      val && val.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "data.xlsx");
  };

  return (
    <>
      <NavbarMex title="Envios Completos" />
      <div className="table-containerEC">
        {/* Botón para redirigir a 'Históricos Cartera' */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button onClick={() => navigate('/historico-envios')} className="historicos-button">
            Históricos Envios
          </button>
        </div>

        <div className="table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="search-input"
          />
          <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
        </div>

        <div className="table-wrapper">
          <table className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('codigo')}>Código</th>
                <th onClick={() => handleSort('fecha_envio')}>Fecha envío</th>
                <th onClick={() => handleSort('numero_guia')}>Número de guía</th>
                <th onClick={() => handleSort('producto')}>Producto</th>
                <th onClick={() => handleSort('destinatario')}>Destinatario</th>
                <th onClick={() => handleSort('direccion')}>Dirección</th>
                <th onClick={() => handleSort('telefono')}>Teléfono</th>
                <th onClick={() => handleSort('ciudad')}>Ciudad</th>
                <th onClick={() => handleSort('valor')}>Valor</th>
                <th onClick={() => handleSort('estado')}>Estado</th>
                <th onClick={() => handleSort('sucursal')}>Sucursal</th>
                <th onClick={() => handleSort('fecha_actualizacion')}>Fecha Actualización</th>
                <th onClick={() => handleSort('transportadora')}>Numero de Guia</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.codigo}</td>
                  <td>{item.fecha_envio}</td>
                  <td>{item.numero_guia}</td>
                  <td>{item.producto}</td>
                  <td>{item.destinatario}</td>
                  <td>{item.direccion}</td>
                  <td>{item.telefono}</td>
                  <td>{item.ciudad}</td>
                  <td>{item.valor}</td>
                  <td>{item.estado || "No entregado"}</td>
                  <td>{item.sucursal}</td>
                  <td>{item.fecha_actualizacion}</td>
                  <td>{item.transportadora}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default TableComponent;
