import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';

const HistoricoCartera = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [codigoSucursal, setCodigoSucursal] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSucursalLoading, setIsSucursalLoading] = useState(true);

  const usuario_id = localStorage.getItem('usuario_id');

  // Función para obtener la fecha en formato YYYY-MM-DD
  const getFormattedDate = (date) => {
    return date.toISOString().split('T')[0]; // Formatear a YYYY-MM-DD
  };

  useEffect(() => {
    // Obtener la fecha actual
    const today = new Date();

    // Obtener la fecha de hace 7 días
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Formatear las fechas a YYYY-MM-DD
    setFechaInicio(getFormattedDate(sevenDaysAgo));
    setFechaFin(getFormattedDate(today));
  }, []);

  useEffect(() => {
    const fetchSucursalId = async () => {
      setIsSucursalLoading(true);
      try {
        console.log("Fetching sucursal ID...");
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setCodigoSucursal(data);
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
      } finally {
        setIsSucursalLoading(false);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  const fetchData = useCallback(() => {
    console.log('Fetching data...');
    if (codigoSucursal && fechaInicio && fechaFin) {
      setLoading(true);
      axios
        .get(`https://99envios.online/api/v1/historico-cartera-completa`, {
          params: {
            codigo_sucursal: codigoSucursal,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
          },
        })
        .then((response) => {
          console.log('Data:', response.data);
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
          setLoading(false);
        });
    } else {
      console.log('Datos incompletos para la búsqueda', {
        codigoSucursal,
        fechaInicio,
        fechaFin,
      });
    }
  }, [codigoSucursal, fechaInicio, fechaFin]);

  useEffect(() => {
    console.log('Effect running. Values:', { codigoSucursal, fechaInicio, fechaFin, isSucursalLoading });
    if (!isSucursalLoading && codigoSucursal && fechaInicio && fechaFin) {
      console.log('Calling fetchData...');
      fetchData();
    } else {
      console.log('Missing data or still loading sucursal, not calling fetchData');
    }
  }, [isSucursalLoading, codigoSucursal, fechaInicio, fechaFin, fetchData]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  // Filtrar en todas las columnas
  const filteredData = sortedData.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "data.xlsx");
  };

  return (
    <>
      <NavbarMex title="Historicos Cartera Completa" />
      <div className="table-containerCA" style={{ maxWidth: "85%", marginLeft: '17%', marginTop: '2%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <div className="table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            style={{ maxWidth: '50px', marginRight: '10px' }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>

          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="search-input"
            style={{ marginRight: '10px', maxWidth: '200px' }}
          />

          <input
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            className="date-input"
            style={{ marginRight: '10px', maxWidth: '200px' }}
          />
          <input
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            className="date-input"
            style={{ marginRight: '10px', maxWidth: '200px' }}
          />

          <button
           onClick={exportToExcel} 
           className="export-button"
            style={{ marginTop: '-15px', marginRight: '10px' }}
           >Exportar a Excel</button>
        </div>

        {loading ? (
          <div>Cargando datos...</div>
        ) : (
          <div className="table-wrapper">
            <table className="custom-table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('Codigo')}>Código</th>
                  <th onClick={() => handleSort('Numero de guia')}>Número de Guía</th>
                  <th onClick={() => handleSort('Valor Comercial')}>Valor Comercial</th>
                  <th onClick={() => handleSort('Valor Flete')}>Valor Flete</th>
                  <th onClick={() => handleSort('Valor Consignado')}>Valor Consignado</th>
                  <th onClick={() => handleSort('Fecha de Corte')}>Fecha de Corte</th>
                  <th onClick={() => handleSort('Estado Envio')}>Estado del Envío</th>
                  <th onClick={() => handleSort('Nombre Sucursal')}>Sucursal</th>
                  <th onClick={() => handleSort('Destinatario')}>Destinatario</th>
                  <th onClick={() => handleSort('Direccion')}>Dirección</th>
                  <th onClick={() => handleSort('Telefono')}>Teléfono</th>
                  <th onClick={() => handleSort('Ciudad')}>Ciudad</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.length > 0 ? (
                  currentEntries.map((item, index) => (
                    <tr key={index}>
                      <td>{item["Codigo"]}</td>
                      <td>{item["Numero de guia"]}</td>
                      <td>{item["Valor Comercial"]}</td>
                      <td>{item["Valor Flete"]}</td>
                      <td>{item["Valor Consignado"]}</td>
                      <td>{item["Fecha de Corte"]}</td>
                      <td>{item["Estado Envio"] || 'N/A'}</td> {/* Manejar valor vacío */}
                      <td>{item["Nombre Sucursal"]}</td>
                      <td>{item["Destinatario"]}</td>
                      <td>{item["Direccion"]}</td>
                      <td>{item["Telefono"]}</td>
                      <td>{item["Ciudad"]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12">No hay datos disponibles</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default HistoricoCartera;
