import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarMex from '../components/navbarMex';
import sampleFile from './envio_masivo.xlsx';
import * as XLSX from 'xlsx';
import { Table, Button, Upload, Select, Row, Col, Spin, Form, message, Alert } from 'antd'; // Importar Alert para el mensaje
import { UploadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import 'antd/dist/antd.css';
import './ExcelUploader.css';

const { Option } = Select;

const ExcelUploader = () => {
  const { id } = useParams(); // Obtener el número desde la URL
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cotizacionResults, setCotizacionResults] = useState({});
  const [selectedTransportadora, setSelectedTransportadora] = useState('');
  const [sucursalId, setSucursalId] = useState('');
  const [allCotizacionesDone, setAllCotizacionesDone] = useState(false);

  const transportadoras = ["interrapidisimo", "tcc", "servientrega", "coordinadora"];

  const handleFileUpload = ({ file }) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setData(jsonData);
      setAllCotizacionesDone(false); // Reset cotizaciones done state
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  useEffect(() => { 
    const fetchSucursalId = async () => {
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setSucursalId(data); // Actualiza el estado con el ID de la sucursal
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
        return null;
      }
    };
  
    fetchSucursalId();
  }, [id]);

  const handleCotizar = async () => {
    setLoading(true);
    const results = {};
  
    for (const formData of data) {
      if (!formData['numero documento']) {
        message.error(`El campo "numero documento" es obligatorio para el destinatario con nombre ${formData.nombre}`);
        continue; // Saltar si falta el número de documento
      }
  
      const diceContenerValue = formData['dice contener'] ? formData['dice contener'].toString() : '';
      if (!diceContenerValue) {
        message.error(`El campo "Dice Contener" es obligatorio para el destinatario con documento ${formData['numero documento']}`);
        continue; // Pasar al siguiente registro si falta el campo obligatorio
      }
  
      const pesoFinal = formData.Peso;
      const valorDeclaradoLimpio = parseInt(formData['valor declarado'], 10);
  
      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: formData['codigo destino'],
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoFinal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: formData.AplicaContrapago,
        valorDeclarado: valorDeclaradoLimpio,
        transportadora: {
          pais: "colombia",
          nombre: selectedTransportadora,
        },
        origenCreacion: 1,
      };
  
      try {
        const response = await fetch(`https://integration.99envios.app/api/sucursal/cotizar_individual/${sucursalId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(cotizacionData),
        });
  
        if (!response.ok) {
          throw new Error('Error en la cotización');
        }
  
        const result = await response.json();
        results[formData['numero documento']] = result; // Almacenar resultado para este documento
        console.log(`Cotización exitosa para documento: ${formData['numero documento']}`, result);
      } catch (error) {
        console.error(`Error al cotizar el documento ${formData['numero documento']}:`, error);
        message.error(`Hubo un error al cotizar el documento ${formData['numero documento']}`);
      }
    }
  
    setCotizacionResults(results);
    setLoading(false);
  
    const allDone = data.every(item => results[item['numero documento']]);
    setAllCotizacionesDone(allDone);
  };

  const requestPdf = async (pdfData, retries = 1) => {
    try {
      const pdfResponse = await fetch(`https://integration.99envios.app/api/sucursal/pdf/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pdfData)
      });

      if (!pdfResponse.ok) {
        const pdfErrorData = await pdfResponse.json();
        const pdfErrorMessage = pdfErrorData.mensaje_completo ? pdfErrorData.mensaje_completo.join(' ') : pdfErrorData.mensaje;
        message.error(`Error al generar PDF: ${pdfErrorMessage}`);
        throw new Error('Error en la respuesta del servidor al generar PDF');
      }

      let base64String = await pdfResponse.text();  // Obtener la respuesta como texto directamente

      base64String = base64String.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');

      if (base64String) {
        try {
          const binaryString = window.atob(base64String);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

          return pdfBlob;
        } catch (e) {
          console.error(e);
          message.error('Error al decodificar la cadena base64 del PDF');
          throw new Error('Error al decodificar la cadena base64 del PDF');
        }
      } else {
        message.error('No se pudo extraer la cadena base64 del PDF');
        throw new Error('No se pudo extraer la cadena base64 del PDF');
      }
    } catch (error) {
      console.error('Error:', error);
      if (retries > 0) {
        message.warning('Reintentando la generación del PDF...');
        return await requestPdf(pdfData, retries - 1);
      } else {
        throw new Error('Error persistente en la generación del PDF');
      }
    }
  };

  const handlePreenvio = async (formDataGuia, index) => {
    const cotizacion = cotizacionResults[formDataGuia['numero documento']];
    if (!cotizacion) {
      message.error(`No se ha cotizado el envío para el destinatario con documento ${formDataGuia['numero documento']}`);
      return;
    }
  
    const pesoFinal = formDataGuia.Peso;
    const valorDeclaradoLimpio = parseFloat(formDataGuia['valor declarado']) || 50000;
    const formattedDate = "28-06-2024";
  
    const diceContenerValue = formDataGuia['dice contener'] ? formDataGuia['dice contener'].toString() : '';
  
    if (!diceContenerValue) {
      message.error(`El campo "Dice Contener" es obligatorio para el destinatario con documento ${formDataGuia['numero documento']}`);
      return;
    }
  
    const guiaData = {
      IdTipoEntrega: 1,
      IdServicio: 1,
      AplicaContrapago: formDataGuia.AplicaContrapago || false,
      peso: pesoFinal,
      largo: parseFloat(formDataGuia.Largo) || 1,
      ancho: parseFloat(formDataGuia.Ancho) || 1,
      alto: parseFloat(formDataGuia.Alto) || 1,
      diceContener: diceContenerValue,
      valorDeclarado: valorDeclaradoLimpio,
      fecha: formattedDate,
      Destinatario: {
        tipoDocumento: formDataGuia.tipoDocumento ? formDataGuia.tipoDocumento.toString() : "CC",
        numeroDocumento: formDataGuia['numero documento'] ? formDataGuia['numero documento'].toString() : `ID-${index}`,
        nombre: formDataGuia.nombre ? formDataGuia.nombre.toString() : "",
        primerApellido: formDataGuia.primerApellido ? formDataGuia.primerApellido.toString() : "",
        segundoApellido: formDataGuia.segundoApellido ? formDataGuia.segundoApellido.toString() : "",
        telefono: formDataGuia.telefono ? formDataGuia.telefono.toString() : "",
        direccion: formDataGuia.direccion ? formDataGuia.direccion.toString() : "",
        correo: formDataGuia.correo ? formDataGuia.correo.toString() : "",
        idLocalidad: formDataGuia['codigo destino'] ? formDataGuia['codigo destino'].toString() : ""

      },
      Observaciones: formDataGuia.Observaciones ? formDataGuia.Observaciones.toString() : "",
      transportadora: {
        pais: "colombia",
        nombre: selectedTransportadora,
        aplicaContrapago: formDataGuia.AplicaContrapago
      },
      origenCreacion: 1,
    };
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/preenvio/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(guiaData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.mensaje_completo ? errorData.mensaje_completo.join(' ') : errorData.mensaje;
        message.error(`Error: ${errorMessage}`);
        throw new Error('Error en la respuesta del servidor');
      }
  
      const responseData = await response.json();
      const numeroPreenvio = responseData.numeroPreenvio.toString();
  
      const pdfData = {
        transportadora: {
          pais: "colombia",
          nombre: selectedTransportadora
        },
        guia: numeroPreenvio,
        AplicaContrapago: formDataGuia.AplicaContrapago,
        origenCreacion: 1,
      };
  
      const pdfBlob = await requestPdf(pdfData, 2);
      return { numeroDocumento: `${formDataGuia['numero documento'] || 'ID'}-${index}`, pdfBlob };
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreenvioAndZip = async () => {
    const zip = new JSZip();
    setLoading(true);

    try {
      const pdfPromises = data.map((row, index) => handlePreenvio(row, index));
      const pdfResults = await Promise.all(pdfPromises);

      pdfResults.forEach(({ numeroDocumento, pdfBlob }) => {
        if (pdfBlob) {
          zip.file(`${numeroDocumento}.pdf`, pdfBlob);
        }
      });

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, 'envios.zip');
      message.success('Los archivos PDF han sido generados y comprimidos exitosamente');
    } catch (error) {
      console.error('Error al generar los PDFs y crear el ZIP:', error);
      message.error('Hubo un error al generar los PDFs o crear el archivo ZIP');
    } finally {
      setLoading(false);
    }
  };

  const handleTransportadoraChange = (value) => {
    setSelectedTransportadora(value);
  };

  const columns = [
    { title: 'APELLIDO DESTINATARIO', dataIndex: 'primer apellido', key: 'primer apellido', width: 120 },
    { title: 'NOMBRE DESTINATARIO', dataIndex: 'nombre', key: 'nombre', width: 120 },
    { title: 'IDENTIFICACION DESTINATARIO', dataIndex: 'numero documento', key: 'numero documento', width: 150 },
    { title: 'DIRECCIÓN DESTINATARIO', dataIndex: 'direccion', key: 'direccion', width: 200 },
    { title: 'CODIGO CIUDAD DESTINATARIO', dataIndex: 'codigo destino', key: 'codigo destino', width: 150 },
    { title: 'DICE CONTENER', dataIndex: 'dice contener', key: 'dice contener', width: 120 },
    { title: 'OBSERVACIONES', dataIndex: 'Observaciones', key: 'Observaciones', width: 150 },
    { title: 'PESO', dataIndex: 'Peso', key: 'Peso', width: 100 },
    { title: 'VALOR COMERCIAL', dataIndex: 'valor declarado', key: 'valor declarado', width: 150 },
    { 
      title: 'Contrapago', 
      dataIndex: 'AplicaContrapago', 
      key: 'AplicaContrapago',
      width: 100,
      render: (text) => {
        return text === true || text === 'true' || text === 1 || text === '1' ? 'Sí' : 'No';
      }
    },
    { 
      title: 'Valor Cotización', 
      key: 'cotizacion_valor', 
      width: 150,
      render: (text, record) => {
        const cotizacion = cotizacionResults[record['numero documento']];
        if (cotizacion) {
          const valorTotal = cotizacion.valor + cotizacion.valor_contrapago + cotizacion.comision_interna + cotizacion.sobreflete;
          return valorTotal;
        }
        return 'N/A';
      }
    }
  ];

  return (
    <>
      <NavbarMex />
      <div className="masivoFinal-containerMasivos" >
        <h1>Envio Masivo</h1>

        {/* Mensaje de alerta */}
        <Alert
          message="Para cotizar, debe seleccionar una transportadora y subir un archivo."
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />

        <Row gutter={16} className="masivoFinal-mb-3">
          <Col span={6}>
            <Form.Item label="Seleccionar Transportadora" style={{ marginBottom: 8 }}>
              <Select value={selectedTransportadora} onChange={handleTransportadoraChange} size="small">
                <Option value="">Seleccionar</Option>
                {transportadoras.map(t => (
                  <Option key={t} value={t}>{t}</Option>
                ))}
              </Select>
            </Form.Item>
            <a href={sampleFile} download>
              <button style={{ marginTop: '1%', maxHeight:'80%', marginLeft:'8%', background:'#4CAF50' }} type="button">
                Descargar plantilla
              </button>
            </a>
          </Col>
          
          <Col span={10}>
            <Form.Item label="Seleccionar archivo" style={{ marginBottom: 8 }}>
              <Upload style={{marginBottom: 9}} beforeUpload={() => false} onChange={handleFileUpload} showUploadList={false}>
                <Button  icon={<UploadOutlined />} size="small">Seleccionar archivo</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8} style={{ alignItems: 'center' }}>
            <Button
              type="primary"
              onClick={handleCotizar}
              disabled={loading || data.length === 0 || selectedTransportadora === ''}
              className="masivoFinal-me-2"
              size="medium"
              style={{ height: '40px' }}
            >
              Cotizar
            </Button>
            {allCotizacionesDone && (
              <Button
                type="primary"
                onClick={handlePreenvioAndZip}
                disabled={loading}
                className="masivoFinal-me-2"
                size="medium"
                style={{ backgroundColor: 'green', height: '40px' }} 
              >
                Preenvío y Generar ZIP
              </Button>
            )}
            {loading && <Spin className="masivoFinal-ml-2" size="small" />}
          </Col>
        </Row>
        <div className="masivoFinal-table-container" style={{maxWidth:"100%"}}>
          <Table 
            dataSource={data} 
            columns={columns} 
            rowKey="numero documento" 
            bordered 
            size="small"
            pagination={{ pageSize: 5 }} 
            scroll={{ x: 'max-content' }}  // Ajuste de desplazamiento horizontal
          />
        </div>
      </div>
    </>
  );
};

export default ExcelUploader;
