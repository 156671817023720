import React, { useState } from 'react';
import NavbarMex from '../components/navbarMex';
import './DynamicForm.css';

const DynamicForm = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [fileErrors, setFileErrors] = useState({});
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const validateFileType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/tiff', 'application/pdf'];
        return allowedTypes.includes(file.type);
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        const file = files[0];
        if (file && !validateFileType(file)) {
            setFileErrors(prev => ({
                ...prev,
                [name]: 'Tipo de archivo no permitido. Solo se permiten JPG, PNG, JPEG, TIF y PDF.'
            }));
            event.target.value = ''; // Limpiar el input
        } else {
            setFileErrors(prev => ({...prev, [name]: ''}));
        }
    };
    
    const handleApiRequest = async (formData) => {
        try {
            const usuario_id = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/online/pqr_save/${usuario_id}`, {
                method: 'POST',
                body: formData, // Enviar el FormData directamente
            });
    
            if (!response.ok) {
                throw new Error('Error en la solicitud a la API');
            }
    
            const data = await response.json();
            console.log('Respuesta de la API:', data);
            alert(data.message);
        } catch (error) {
            console.error('Error al procesar la solicitud:', error);
            alert('Hubo un error al procesar la solicitud.');
        }
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
    
        let formData = new FormData(); // Utilizar FormData para soportar archivos binarios
    
        // Obtener todos los inputs de archivo para verificar su carga
        const fileInputs = {
            soporte_entrega: document.querySelector('input[name="soporte_entrega"]'),
            soporte_obligatorio: document.querySelector('input[name="soporte_obligatorio"]'),
            adjuntar_guia: document.querySelector('input[name="adjuntar_guia"]'),
            imagen_embalaje: document.querySelector('input[name="imagen_embalaje"]'),
            imagen_contenido: document.querySelector('input[name="imagen_contenido"]'),
            imagen_embalaje_inicial: document.querySelector('input[name="imagen_embalaje_inicial"]'),
            foto_llegada_paquete: document.querySelector('input[name="foto_llegada_paquete"]'),
            foto_paquete: document.querySelector('input[name="foto_paquete"]'),
            certificacion: document.querySelector('input[name="certificacion"]'),
            adjunto: document.querySelector('input[name="adjunto"]'),
            respuesta_logistica: document.querySelector('input[name="respuesta_logistica"]'),
            foto_recibo: document.querySelector('input[name="foto_recibo"]'), // Añadido para el caso de "Cambio de dirección o teléfono"
        };
    
        switch (selectedOption) {
            case 'Actualizar informacion de guia':
                formData.append('categoria', 'actualizar_informacion_guia');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('nuevo_telefono', document.querySelector('input[name="nuevo_telefono"]').value);
                formData.append('nueva_direccion', document.querySelector('input[name="nueva_direccion"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                if (fileInputs.foto_recibo && fileInputs.foto_recibo.files[0]) {
                    formData.append('foto_recibo', fileInputs.foto_recibo.files[0]); // Añadir archivo binario
                }
                break;
    
            case 'Cambio de dirección o teléfono':
                formData.append('categoria', 'cambio_direccion_telefono');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('nuevo_telefono', document.querySelector('input[name="nuevo_telefono"]').value);
                formData.append('nueva_direccion', document.querySelector('input[name="nueva_direccion"]').value);
                if (fileInputs.foto_recibo && fileInputs.foto_recibo.files[0]) {
                    formData.append('foto_recibo', fileInputs.foto_recibo.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Cierre logístico':
                formData.append('categoria', 'cierre_logistico');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('fecha_entrega', document.querySelector('input[name="fecha_entrega"]').value);
                if (fileInputs.soporte_entrega && fileInputs.soporte_entrega.files[0]) {
                    console.log("Hay archivo cargado",fileInputs.soporte_entrega.files[0]);
                    formData.append('soporte_entrega', fileInputs.soporte_entrega.files[0]); // Añadir archivo binario
                }else{
                    console.log("No hay archivo cargado");
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Demora en tiempo de entrega':
                formData.append('categoria', 'demora_tiempo_entrega');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('fecha_envio', document.querySelector('input[name="fecha_envio"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Envio ya entregado':
                formData.append('categoria', 'envio_ya_entregado');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('fecha_entrega', document.querySelector('input[name="fecha_entrega"]').value);
                if (fileInputs.soporte_entrega && fileInputs.soporte_entrega.files[0]) {
                    formData.append('soporte_entrega', fileInputs.soporte_entrega.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Inconformidad en servicio logístico':
                formData.append('categoria', 'inconformidad_servicio_logistico');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                if (fileInputs.soporte_obligatorio && fileInputs.soporte_obligatorio.files[0]) {
                    formData.append('soporte_obligatorio', fileInputs.soporte_obligatorio.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Nuevo intento de entrega':
                formData.append('categoria', 'nuevo_intento_entrega');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Posible pérdida':
                formData.append('categoria', 'posible_perdida');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                if (fileInputs.adjuntar_guia && fileInputs.adjuntar_guia.files[0]) {
                    formData.append('adjuntar_guia', fileInputs.adjuntar_guia.files[0]); // Añadir archivo binario
                }
                if (fileInputs.imagen_embalaje && fileInputs.imagen_embalaje.files[0]) {
                    formData.append('imagen_embalaje', fileInputs.imagen_embalaje.files[0]); // Añadir archivo binario
                }
                if (fileInputs.imagen_contenido && fileInputs.imagen_contenido.files[0]) {
                    formData.append('imagen_contenido', fileInputs.imagen_contenido.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Paquete averiado':
                formData.append('categoria', 'paquete_averiado');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                if (fileInputs.imagen_embalaje_inicial && fileInputs.imagen_embalaje_inicial.files[0]) {
                    formData.append('imagen_embalaje_inicial', fileInputs.imagen_embalaje_inicial.files[0]); // Añadir archivo binario
                }
                if (fileInputs.imagen_contenido && fileInputs.imagen_contenido.files[0]) {
                    formData.append('imagen_contenido', fileInputs.imagen_contenido.files[0]); // Añadir archivo binario
                }
                if (fileInputs.foto_llegada_paquete && fileInputs.foto_llegada_paquete.files[0]) {
                    formData.append('foto_llegada_paquete', fileInputs.foto_llegada_paquete.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Solicitud devolución':
                formData.append('categoria', 'solicitud_devolucion');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('motivo_devolucion', document.querySelector('select[name="motivo_devolucion"]').value);
                break;
    
            case 'Actualizar o registrar datos bancarios':
                formData.append('categoria', 'actualizar_datos_bancarios');
                formData.append('transportadora', document.querySelector('select[name="transportadora"]').value);
                formData.append('nombre_banco', document.querySelector('input[name="nombre_banco"]').value);
                formData.append('tipo_cuenta', document.querySelector('input[name="tipo_cuenta"]').value);
                formData.append('numero_cuenta', document.querySelector('input[name="numero_cuenta"]').value);
                if (fileInputs.certificacion && fileInputs.certificacion.files[0]) {
                    formData.append('certificacion', fileInputs.certificacion.files[0]); // Añadir archivo binario
                }
                break;
    
            case 'Actualización datos sucursal':
                formData.append('categoria', 'actualizacion_datos_sucursal');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('nuevo_nombre', document.querySelector('input[name="nuevo_nombre"]').value);
                formData.append('nuevo_telefono', document.querySelector('input[name="nuevo_telefono"]').value);
                formData.append('nueva_direccion', document.querySelector('input[name="nueva_direccion"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Atraso en el desembolso':
                formData.append('categoria', 'atraso_desembolso');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('fecha_desembolso', document.querySelector('input[name="fecha_desembolso"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Atraso en la recarga':
                formData.append('categoria', 'atraso_recarga');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('fecha_recarga', document.querySelector('input[name="fecha_recarga"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Cancelar guía a contrarrembolso':
                formData.append('categoria', 'cancelar_guia_contrarrembolso');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Cierre logístico PQR Cartera':
                formData.append('categoria', 'cierre_logistico_pqr_cartera');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('fecha_entrega', document.querySelector('input[name="fecha_entrega"]').value);
                if (fileInputs.soporte_entrega && fileInputs.soporte_entrega.files[0]) {
                    formData.append('soporte_entrega', fileInputs.soporte_entrega.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Devolución de flete de guía sin contrarrembolso':
                formData.append('categoria', 'devolucion_flete_guia_sin_contrarrembolso');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Envío revalorizado':
                formData.append('categoria', 'envio_revalorizado');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                if (fileInputs.foto_paquete && fileInputs.foto_paquete.files[0]) {
                    formData.append('foto_paquete', fileInputs.foto_paquete.files[0]); // Añadir archivo binario
                }
                formData.append('longitud', document.querySelector('input[name="longitud"]').value);
                formData.append('altura', document.querySelector('input[name="altura"]').value);
                formData.append('ancho', document.querySelector('input[name="ancho"]').value);
                formData.append('peso', document.querySelector('input[name="peso"]').value);
                formData.append('informacion_adicional', document.querySelector('textarea[name="informacion_adicional"]').value);
                break;
    
            case 'Facturación y verificación de pagos':
                formData.append('categoria', 'facturacion_verificacion_pagos');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                if (fileInputs.adjunto && fileInputs.adjunto.files[0]) {
                    formData.append('adjunto', fileInputs.adjunto.files[0]); // Añadir archivo binario
                }
                formData.append('informacion_detallada', document.querySelector('textarea[name="informacion_detallada"]').value);
                break;
    
            case 'Indemnización':
                formData.append('categoria', 'indemnizacion');
                formData.append('transportadora', document.querySelector('input[name="transportadora"]').value);
                formData.append('numero_guia', document.querySelector('input[name="numero_guia"]').value);
                if (fileInputs.respuesta_logistica && fileInputs.respuesta_logistica.files[0]) {
                    formData.append('respuesta_logistica', fileInputs.respuesta_logistica.files[0]); // Añadir archivo binario
                }
                break;
    
            default:
                alert('Por favor, selecciona una opción válida.');
                return; 
        }
    
       
        handleApiRequest(formData);
    };
    
    
    
    
    

    const renderFormFields = () => {

        switch (selectedOption) {
            case 'Actualizar informacion de guia':
                return (
                    <>
                        <label>Transportadora Selecciona:</label>
                        <select name="transportadora">
                            <option value="">-- Selecciona una transportadora --</option>
                            <option value="Servientrega">Servientrega</option>
                            <option value="TCC">TCC</option>
                            <option value="Coordinadora">Coordinadora</option>
                            <option value="Interrapidisimo">Interrapidisimo</option>
                        </select>

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Nuevo Teléfono:</label>
                        <input type="text" name="nuevo_telefono" placeholder="Confirme o ingrese el nuevo teléfono" />

                        <label>Nueva Dirección:</label>
                        <input type="text" name="nueva_direccion" placeholder="Confirme o ingrese la nueva dirección" />

                        <label>Adjuntar foto del recibo público o soporte de la nueva dirección (foto):</label>
                        <input type="file" name="foto_recibo" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.tif,.pdf" />
                        {fileErrors.foto_recibo && <p className="error">{fileErrors.foto_recibo}</p>}

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );

                case 'Cambio de dirección o teléfono':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                            <select name="transportadora">
                                <option value="">-- Selecciona una transportadora --</option>
                                <option value="Servientrega">Servientrega</option>
                                <option value="TCC">TCC</option>
                                <option value="Coordinadora">Coordinadora</option>
                                <option value="Interrapidisimo">Interrapidisimo</option>
                            </select>
                
                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />
                
                            <label>Nuevo Teléfono:</label>
                            <input type="text" name="nuevo_telefono" placeholder="Confirme o ingrese el nuevo teléfono" />
                
                            <label>Nueva Dirección:</label>
                            <input type="text" name="nueva_direccion" placeholder="Confirme o ingrese la nueva dirección" />
                
                            <label>Adjuntar foto del recibo público o soporte de la nueva dirección (foto):</label>
                            <input 
                                type="file" 
                                name="foto_recibo" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.foto_recibo && <p className="error">{fileErrors.foto_recibo}</p>}
                
                            <label>Información adicional:</label>
                            <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                        </>
                    );

                case 'Cierre logístico':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                            <select name="transportadora">
                                <option value="">-- Selecciona una transportadora --</option>
                                <option value="Servientrega">Servientrega</option>
                                <option value="TCC">TCC</option>
                                <option value="Coordinadora">Coordinadora</option>
                                <option value="Interrapidisimo">Interrapidisimo</option>
                            </select>
    
                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />
    
                            <label>Fecha de Entrega:</label>
                            <input type="date" name="fecha_entrega" />
    
                            <label>Soporte de entrega o verificación de cliente entrega realizada (PDF - FOTO):</label>
                            <input type="file" name="soporte_entrega" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.tif,.pdf" />
                            {fileErrors.soporte_entrega && <p className="error">{fileErrors.soporte_entrega}</p>}
    
                            <label>Información adicional:</label>
                            <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                        </>
                    );
                case 'Demora en tiempo de entrega':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                        <select name="transportadora">
                            <option value="">-- Selecciona una transportadora --</option>
                            <option value="Servientrega">Servientrega</option>
                            <option value="TCC">TCC</option>
                            <option value="Coordinadora">Coordinadora</option>
                            <option value="Interrapidisimo">Interrapidisimo</option>
                        </select>

                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />

                            <label>La fecha de envío debe superar los 3 días desde su envío:</label>
                            <input type="date" name="fecha_envio" />

                            <label>Información adicional:</label>
                            <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                        </>
                    );
                    case 'Envio ya entregado':
                        return (
                            <>
                                <label>Transportadora Selecciona:</label>
                                <select name="transportadora">
                                    <option value="">-- Selecciona una transportadora --</option>
                                    <option value="Servientrega">Servientrega</option>
                                    <option value="TCC">TCC</option>
                                    <option value="Coordinadora">Coordinadora</option>
                                    <option value="Interrapidisimo">Interrapidisimo</option>
                                </select>
                    
                                <label>Número de Guía (más de 10 dígitos):</label>
                                <input type="text" name="numero_guia" />
                    
                                <label>Fecha de Entrega:</label>
                                <input type="date" name="fecha_entrega" />
                    
                                <label>Soporte de entrega o verificación de cliente (PDF - FOTO):</label>
                                <input 
                                    type="file" 
                                    name="soporte_entrega" 
                                    onChange={handleFileChange} 
                                    accept=".jpg,.jpeg,.png,.tif,.pdf" 
                                />
                                {fileErrors.soporte_entrega && <p className="error">{fileErrors.soporte_entrega}</p>}
                    
                                <label>Información adicional:</label>
                                <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                            </>
                        );
                    
                case 'Inconformidad en servicio logístico':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                            <select name="transportadora">
                                <option value="">-- Selecciona una transportadora --</option>
                                <option value="Servientrega">Servientrega</option>
                                <option value="TCC">TCC</option>
                                <option value="Coordinadora">Coordinadora</option>
                                <option value="Interrapidisimo">Interrapidisimo</option>
                            </select>

                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />

                            <p>Añade foto de tu inconformidad, como captura de pantalla o foto que ilustre el problema presentado. Esto nos ayudará a entender y resolver tu situación más rápidamente. Gracias.</p>

                            <label>Soporte obligatorio (FOTO):</label>
                            <input 
                                type="file" 
                                name="soporte_obligatorio" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.soporte_obligatorio && <p className="error">{fileErrors.soporte_obligatorio}</p>}

                            <label>Información adicional de la inconformidad:</label>
                            <textarea 
                                name="informacion_adicional" 
                                placeholder="Amplíe la descripción"
                            ></textarea>
                        </>
                    );


                case 'Inconformidad en servicio logístico':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                            <select name="transportadora">
                                <option value="">-- Selecciona una transportadora --</option>
                                <option value="Servientrega">Servientrega</option>
                                <option value="TCC">TCC</option>
                                <option value="Coordinadora">Coordinadora</option>
                                <option value="Interrapidisimo">Interrapidisimo</option>
                            </select>
                
                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />
                
                            <p>Añade foto de tu inconformidad, como captura de pantalla o foto que ilustre el problema presentado. Esto nos ayudará a entender y resolver tu situación más rápidamente. Gracias.</p>
                
                            <label>Soporte obligatorio (FOTO):</label>
                            <input 
                                type="file" 
                                name="soporte_obligatorio" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.soporte_obligatorio && <p className="error">{fileErrors.soporte_obligatorio}</p>}
                
                            <label>Información adicional de la inconformidad:</label>
                            <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                        </>
                    );
                
            case 'Posible pérdida':
                return (
                    <>
                        <label>Transportadora Selecciona:</label>
                        <select name="transportadora">
                            <option value="">-- Selecciona una transportadora --</option>
                            <option value="Servientrega">Servientrega</option>
                            <option value="TCC">TCC</option>
                            <option value="Coordinadora">Coordinadora</option>
                            <option value="Interrapidisimo">Interrapidisimo</option>
                        </select>

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Adjuntar guía (PDF - FOTO):</label>
                        <input type="file" name="adjuntar_guia" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.tif,.pdf" />
                        {fileErrors.adjuntar_guia && <p className="error">{fileErrors.adjuntar_guia}</p>}

                        <label>Imagen del embalaje (Foto):</label>
                        <input type="file" name="imagen_embalaje" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.tif,.pdf" />
                        {fileErrors.imagen_embalaje && <p className="error">{fileErrors.imagen_embalaje}</p>}

                        <label>Imagen del contenido (Foto):</label>
                        <input type="file" name="imagen_contenido" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.tif,.pdf" />
                        {fileErrors.imagen_contenido && <p className="error">{fileErrors.imagen_contenido}</p>}

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
                case 'Paquete averiado':
                    return (
                        <>
                            <label>Transportadora Selecciona:</label>
                            <select name="transportadora">
                                <option value="">-- Selecciona una transportadora --</option>
                                <option value="Servientrega">Servientrega</option>
                                <option value="TCC">TCC</option>
                                <option value="Coordinadora">Coordinadora</option>
                                <option value="Interrapidisimo">Interrapidisimo</option>
                            </select>
                
                            <label>Número de Guía (más de 10 dígitos):</label>
                            <input type="text" name="numero_guia" />
                
                            <label>Imagen del embalaje inicial (Foto):</label>
                            <input 
                                type="file" 
                                name="imagen_embalaje_inicial" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.imagen_embalaje_inicial && <p className="error">{fileErrors.imagen_embalaje_inicial}</p>}
                
                            <label>Imagen del contenido (Foto):</label>
                            <input 
                                type="file" 
                                name="imagen_contenido" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.imagen_contenido && <p className="error">{fileErrors.imagen_contenido}</p>}
                
                            <label>Foto de cómo llegó tu paquete (Foto):</label>
                            <input 
                                type="file" 
                                name="foto_llegada_paquete" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.foto_llegada_paquete && <p className="error">{fileErrors.foto_llegada_paquete}</p>}
                
                            <label>Información adicional:</label>
                            <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                        </>
                    );
                
            case 'Solicitud devolución':
                return (
                    <>
                        <label>Transportadora Selecciona:</label>
                    <select name="transportadora">
                        <option value="">-- Selecciona una transportadora --</option>
                        <option value="Servientrega">Servientrega</option>
                        <option value="TCC">TCC</option>
                        <option value="Coordinadora">Coordinadora</option>
                        <option value="Interrapidisimo">Interrapidisimo</option>
                    </select>

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Motivo de Devolución:</label>
                        <select name="motivo_devolucion">
                            <option value="">-- Selecciona --</option>
                            <option value="Motivo 1">Motivo 1</option>
                            <option value="Motivo 2">Motivo 2</option>
                            <option value="Motivo 3">Motivo 3</option>
                        </select>
                    </>
                );
                case 'Actualizar o registrar datos bancarios':
                    return (
                        <>
                            <input 
                                type="text" 
                                name="transportadora" 
                                value="99envios Administrativo" 
                                readOnly 
                            />
                
                            <label>Nombre banco y tipo de cuenta:</label>
                            <input 
                                type="text" 
                                name="nombre_banco" 
                                placeholder="Seleccione su banco" 
                            />
                
                            <label>Tipo de Cuenta:</label>
                            <input 
                                type="text" 
                                name="tipo_cuenta" 
                                placeholder="Ahorros" 
                            />
                
                            <label>Número de cuenta:</label>
                            <input 
                                type="text" 
                                name="numero_cuenta"/>
                
                            <label>Adjuntar certificación (PDF, foto, jpg):</label>
                            <input 
                                type="file" 
                                name="certificacion" 
                                onChange={handleFileChange} 
                                accept=".jpg,.jpeg,.png,.tif,.pdf" 
                            />
                            {fileErrors.certificacion && <p className="error">{fileErrors.certificacion}</p>}
                
                            <p>La certificación debe obligatoriamente coincidir con los datos proporcionados durante el registro del usuario.</p>
                        </>
                    );
            case 'Actualización datos sucursal':
                return (
                    <>
                        <input type="text" name="transportadora" value="99envios Administrativo" readonly />

                        <label>Nuevo Nombre:</label>
                        <input type="text" name="nuevo_nombre" placeholder="Confirme o ingrese el nuevo nombre" />

                        <label>Nuevo Teléfono:</label>
                        <input type="text" name="nuevo_telefono" placeholder="Confirme o ingrese el nuevo teléfono" />

                        <label>Nueva Dirección:</label>
                        <input type="text" name="nueva_direccion" placeholder="Confirme o ingrese la nueva dirección" />

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Atraso en el desembolso':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />


                        <label>Fecha que solicitó el desembolso:</label>
                        <input type="date" name="fecha_desembolso" />

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Atraso en la recarga':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Fecha en que hizo la recarga:</label>
                        <input type="date" name="fecha_recarga" />

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Cancelar guía a contrarrembolso':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <p>Si el estado del envío ya muestra alguna de las siguientes condiciones: "entregada", "entrega exitosa", "devolución ratificada" "digitalizada" o "archivada", lamentablemente no podemos procesar su solicitud.</p>

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Cierre logístico PQR Cartera':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Fecha de Entrega:</label>
                        <input type="date" name="fecha_entrega" />

                        <label>Soporte de entrega o verificación de cliente entrega realizada (PDF - FOTO):</label>
                        <input type="file" name="soporte_entrega" />

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Devolución de flete de guía sin contrarrembolso':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <p>Si el estado del envío ya muestra alguna de las siguientes condiciones: "entregada", "entrega exitosa", "devolución ratificada" "digitalizada" o "archivada", lamentablemente no podemos procesar su solicitud.</p>

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
            case 'Envío revalorizado':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Foto del paquete (FOTO):</label>
                        <input type="file" name="foto_paquete" />

                        <label>Longitud (cm):</label>
                        <input type="text" name="longitud" />

                        <label>Altura (cm):</label>
                        <input type="text" name="altura" />

                        <label>Ancho (cm):</label>
                        <input type="text" name="ancho" />

                        <label>Peso:</label>
                        <input type="text" name="peso" />

                        <p>Recuerda que si el envío se realizó para una vereda y no escogiste la opción vereda en las opciones de envío, el precio de la guía está sujeto a cambio de la transportadora y la solicitud de revalorización será desestimada</p>

                        <label>Información adicional:</label>
                        <textarea name="informacion_adicional" placeholder="Amplíe la descripción"></textarea>
                    </>
                );
                case 'Facturación y verificación de pagos':
                    return (
                        <>
                            <input 
                                type="text" 
                                name="transportadora" 
                                value="Cartera" 
                                readOnly 
                            />
                
                            <p>Por favor especifique lo más detalladamente la solicitud:</p>
                
                            <label>Adjunto (xls, pdf):</label>
                            <input 
                                type="file" 
                                name="adjunto" 
                                onChange={handleFileChange} 
                                accept=".xls,.pdf" 
                            />
                            {fileErrors.adjunto && <p className="error">{fileErrors.adjunto}</p>}
                
                            <label>Información detallada:</label>
                            <textarea 
                                name="informacion_detallada" 
                                placeholder="Amplíe la descripción"
                            ></textarea>
                        </>
                    );
                
            case 'Indemnización':
                return (
                    <>
                        <input type="text" name="transportadora" value="Cartera" readonly />

                        <label>Número de Guía (más de 10 dígitos):</label>
                        <input type="text" name="numero_guia" />

                        <label>Adjuntar respuesta de Logística (PDF - jpg):</label>
                        <input type="file" name="respuesta_logistica" />
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <NavbarMex title={'PQR'} />
            <div className="cardPQ">
                <h1>Modulo de Usuario PQR formulario dinámico</h1>
                <form onSubmit={handleSubmit}>
                    <label>
                        Categoría o motivo:
                        <select value={selectedOption} onChange={handleOptionChange}>
                            <option value="">------ PQR Transportadoras ------</option>
                            <option value="Actualizar informacion de guia">Actualizar información de guía</option>
                            <option value="Cambio de dirección o teléfono">Cambio de dirección o teléfono</option>
                            <option value="Cierre logístico">Cierre logístico</option>
                            <option value="Demora en tiempo de entrega">Demora en tiempo de entrega</option>
                            <option value="Envio ya entregado">Envío ya entregado</option>
                            <option value="Inconformidad en servicio logístico">Inconformidad en servicio logístico</option>
                            <option value="Nuevo intento de entrega">Nuevo intento de entrega</option>
                            <option value="Posible pérdida">Posible pérdida</option>
                            <option value="Paquete averiado">Paquete averiado</option>
                            <option value="Solicitud devolución">Solicitud devolución</option>
                            <option value="">------ PQR Administrativos ------</option>
                            <option value="Actualizar o registrar datos bancarios">Actualizar o registrar datos bancarios</option>
                            <option value="Actualización datos sucursal">Actualización datos sucursal</option>
                            <option value="">------ PQR Cartera ------</option>
                            <option value="Atraso en el desembolso">Atraso en el desembolso</option>
                            <option value="Atraso en la recarga">Atraso en la recarga</option>
                            <option value="Cancelar guía a contrarrembolso">Cancelar guía a contrarrembolso</option>
                            <option value="Cierre logístico PQR Cartera">Cierre logístico</option>
                            <option value="Devolución de flete de guía sin contrarrembolso">Devolución de flete de guía sin contrarrembolso</option>
                            <option value="Envío revalorizado">Envio Revalorizado</option>
                            <option value="Facturación y verificación de pagos">Facturación y verificación de pagos</option>
                            <option value="Indemnización">Indemnización</option>
                        </select>
                    </label>
                    {renderFormFields()}
                    <button type="submit">Enviar</button>
                </form>

                </div>
                <div className="office-buttons">
                    <h3>Haz clic para encontrar las oficinas de Servientrega o interrapidisimo más cerca de ti</h3>
                    <div className="buttons-container">
                        <button
                            className="office-button interrapidisimo office-button-common"
                            onClick={() => window.open('https://www3.interrapidisimo.com/SitioOficinaMasCercana/', '_blank')}
                        >
                            Oficinas Interrapidisimo
                        </button>
                        <button
                            className="office-button servientrega office-button-common"
                            onClick={() => window.open('https://www.servientrega.com/wps/portal/nuestra-red/!ut/p/z1/lVFdT8IwFP0r8rDHcbtPim8zJIIRDBCE9YWUrhtV1o6uMP33FuTFxC3at9ucc-_5AAIbIJKeRUGNUJIe7JySeBvhSThGoffyOBuFaL548KczL5kEixjWVwBqeQkC0s6fL8MbvwNAuu-_AgFSMZFBijCNKfWZGw3zyA0zHruY-p7LMAvyKBtiNswvaCZNZfaQ1lyfBZdG84K6DSsdJKRgQjlInnhtNL3TPOsWeFFAuv2vLydbN_jJ1L8BOnY8ASkOavfdRyJ3AS6AaJ5zzXX_pO333piqvneQg5qm6RdKFQfeZ8p6-o2yV7WBzU8kpNbooNXowIe1TauBlVS6tEqW_wx-fLXRHaWVKt6OR5LYkpQ0_MOq_FtLVblalTj4dN_zqYl2UVFuR89Jr_cFZZaxJg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/', '_blank')}
                        >
                            Oficinas Servientrega
                        </button>
                        {/* TCC */}
                        <button
                            className="office-button tcc office-button-common"
                            onClick={() => window.open('https://www.tcc.com.co/servicios/oficinashttps://tcc.com.co/corporativo/puntos-de-logistica-y-servicios/', '_blank')}
                        >
                            Oficinas TCC
                        </button>
                        {/* Coordinadora */}
                        <button
                            className="office-button coordinadora office-button-common"
                            onClick={() => window.open('https://coordinadora.com/puntos-droop/visitanos/', '_blank')}
                        >
                            Oficinas Coordinadora
                        </button>

                    </div>
            </div>
            <div className="office-buttons">
                <h3>Haz clic para encontrar las oficinas de Servientrega o interrapidisimo más cerca de ti</h3>
                <div className="buttons-container">
                    <button
                        className="office-button interrapidisimo"
                        onClick={() => window.open('https://www3.interrapidisimo.com/SitioOficinaMasCercana/', '_blank')}
                    >
                        Oficinas Interrapidisimo
                    </button>
                    <button
                        className="office-button servientrega"
                        onClick={() => window.open('https://www.servientrega.com/wps/portal/nuestra-red/!ut/p/z1/lVFdT8IwFP0r8rDHcbtPim8zJIIRDBCE9YWUrhtV1o6uMP33FuTFxC3at9ucc-_5AAIbIJKeRUGNUJIe7JySeBvhSThGoffyOBuFaL548KczL5kEixjWVwBqeQkC0s6fL8MbvwNAuu-_AgFSMZFBijCNKfWZGw3zyA0zHruY-p7LMAvyKBtiNswvaCZNZfaQ1lyfBZdG84K6DSsdJKRgQjlInnhtNL3TPOsWeFFAuv2vLydbN_jJ1L8BOnY8ASkOavfdRyJ3AS6AaJ5zzXX_pO333piqvneQg5qm6RdKFQfeZ8p6-o2yV7WBzU8kpNbooNXowIe1TauBlVS6tEqW_wx-fLXRHaWVKt6OR5LYkpQ0_MOq_FtLVblalTj4dN_zqYl2UVFuR89Jr_cFZZaxJg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/', '_blank')}
                    >
                        Oficinas Servientrega
                    </button>
                </div>
            </div>



        </>
    );
};

export default DynamicForm;
