export const API_MESSAGES = {
    NO_SUSCRIPTOR_FOUND: 'No se encontró ningún suscriptor relacionado con esta sucursal.',
    SUCCESS_CREATE_SUSCRIPTOR: 'Suscriptor creado exitosamente.',
    SUCCESS_UPDATE_SUSCRIPTOR: 'Suscriptor actualizado correctamente.',
    ERROR_SUCURSAL_NOT_FOUND: 'Sucursal no encontrada.',
    ERROR_UNAUTHORIZED: 'No autorizado para realizar esta acción.',
    ERROR_SERVER: 'Error en el servidor, por favor intente más tarde.',
    INVALID_REQUEST: 'La solicitud es inválida, por favor revise los datos enviados.',
    NOT_FOUND: 'El recurso solicitado no fue encontrado.',
    SUCCESS_DELETE: 'Recurso eliminado exitosamente.',
  };
  