import React, { useState } from 'react';
import { Button, Modal, Table, message } from 'antd';
import { API_ROUTES } from '../../constants/apiRoutes';
import './agregarProductoShopify.css';
import axios from 'axios';

const ListarProductos = ({ userId }) => {
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const fetchProducts = async () => {
        try {
            const response = await axios.get(API_ROUTES.INVENTARIOS(userId));
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
            message.error('Error fetching products.');
        }
    };

    const handleShowProductsModal = () => {
        fetchProducts();
        setIsNewProductModalVisible(true);
    };

    const handleSynchronizeProduct = async (product) => {
        try {
            await axios.post(API_ROUTES.SYNC_PRODUCT_SHOPIFY, product);
    
            message.success(`Producto ${product.nombre_producto} sincronizado con éxito`);
        } catch (error) {
            console.error('Error synchronizing product:', error);
            message.error(`Error synchronizing ${product.nombre_producto}`);
        }
    };

    const toggleDescription = (key) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id_producto',
            key: 'id_producto',
            width: '5%',
        },
        {
            title: 'Nombre Producto',
            dataIndex: 'nombre_producto',
            key: 'nombre_producto',
            width: '5%',
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion_producto',
            key: 'descripcion_producto',
            width: '20%',
            render: (text, record) => {
                const isExpanded = expandedDescriptions[record.id_producto];
                return (
                    <div className="custom-details">
                        {isExpanded ? text : `${text.substring(0, 80)}...`}
                        <Button type="link" onClick={() => toggleDescription(record.id_producto)}>
                            {isExpanded ? 'Ver menos' : 'Ver más'}
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Cantidad Disponible',
            dataIndex: 'cantidad_disponible',
            key: 'cantidad_disponible',
            width: '1%',
        },
        {
            title: 'Peso',
            dataIndex: 'peso_producto',
            key: 'peso_producto',
            width: '1%',
        },
        {
            title: 'Precio Sugerido',
            dataIndex: 'precio_sugerido',
            key: 'precio_sugerido',
            width: '5%',
        },
        {
            title: 'Variaciones',
            key: 'variaciones',
            width: '15%',
            render: (_, product) => {
                const productoVariaciones = product.variaciones;
                return (
                    productoVariaciones && productoVariaciones.length > 0 ? (
                        productoVariaciones.map((variacion, index) => (
                            <div
                                key={variacion.id}
                                style={{
                                    borderBottom: index < productoVariaciones.length - 1 ? '1px solid #ccc' : 'none',
                                    padding: '5px 0',
                                }}
                            >
                                {`Código SKU: ${variacion.sku}, Talla: ${variacion.talla}`}
                                <br />
                                {`Color: ${variacion.color}, Cantidad Disponible: ${variacion.cantidad_disponible}`}
                            </div>
                        ))
                    ) : (
                        'Sin variaciones'
                    )
                );
            },
        },
        {
            title: 'Acciones',
            key: 'acciones',
            width: '5%',
            render: (_, product) => (
                <Button onClick={() => handleSynchronizeProduct(product)} type="primary">
                    Sincronizar
                </Button>
            ),
        },
    ];

    return (
        <>
            <Button
                type="primary"
                onClick={handleShowProductsModal}
                style={{
                    width: '160px',
                    height: '44px',
                    marginBottom: '10px',
                    backgroundColor: '#6F31F5',
                    borderColor: '#6F31F5',
                }}
            >
                Mis Productos
            </Button>

            <Modal
                title="Mis Productos"
                visible={isNewProductModalVisible}
                onCancel={() => setIsNewProductModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsNewProductModalVisible(false)}>
                        Cerrar
                    </Button>,
                ]}
                centered
                width={1100}
            >
                <Table
                    dataSource={products}
                    columns={columns}
                    rowKey="id_producto"
                    className="custom-table"
                />
            </Modal>
        </>
    );
};

export default ListarProductos;
