import React from 'react';
import { Link } from 'react-router-dom';
import './preLogin.css';

const PreLogin = () => {
  return (
    <div className="prelogin-wrapper">
      <div className="prelogin-container">
        <div className="prelogin-header">
          <h1 className="prelogin-title">Seleccione su portal de acceso</h1>
        </div>
        <div className="prelogin-options">
          <div className="prelogin-option">
            <h1 className="prelogin-option-title-ultima-milla">Ingreso a Ultima Milla</h1>
            <p className="prelogin-option-description">Acceda a la página Ultima Milla desde aquí</p>
            <Link to="/login" className="prelogin-button prelogin-button-ultima-milla">
              Ultima Milla
            </Link>
          </div>
          <div className="prelogin-option">
            <h1 className="prelogin-option-title-top">Ingreso a 99 Envios</h1>
            <p className="prelogin-option-description">Acceda a la página de 99 Envios desde aquí</p>
            <Link to="/login-top" className="prelogin-button prelogin-button-top">
              99 Envios
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLogin;