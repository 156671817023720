import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import './LandingDrop.css';
import SendClientPage from "../SendClientPage/SendClientPage";
import CloseIcon from '@mui/icons-material/Close';

const LandingDrop = () => {
  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [name, setName] = useState(null);
  const [stock, setStock] = useState(null);
  const [prodID, setProdID] = useState(null);
  const [products, setProducts] = useState([]); // Todos los productos
  const [filteredProducts, setFilteredProducts] = useState([]); // Productos filtrados
  const [categories, setCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda

  // Almacena todos los productos en la primera carga
  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('https://api.99envios.app/api/inventarios');
      const productPromises = data.map(async (product) => {
        const detailResponse = await axios.get(`https://api.99envios.app/api/inventarios_producto/${product.id_producto}`);
        const detailData = detailResponse.data;
        return {
          id: product.id_producto,
          name: product.nombre_producto,
          category: product.categoria?.nombre_categoria || 'Sin categoría',
          stock: product.cantidad_disponible,
          provider: detailData.sucursal.nombre_sucursal || 'Cargando...',
          supplierPrice: parseFloat(product.precio_proveedor),
          suggestedPrice: parseFloat(product.precio_sugerido),
          images: product.imagenes.map(img => img.url_imagen) || ['/logo.png'],
          link: `/product/${product.id_producto}`
        };
      });
      const mappedProducts = await Promise.all(productPromises);
      setProducts(mappedProducts); // Guardar todos los productos en el estado
      setFilteredProducts(mappedProducts); // Inicialmente, todos los productos están filtrados
      const uniqueCategories = [...new Set(mappedProducts.map(product => product.category))];
      const uniqueProviders = [...new Set(mappedProducts.map(product => product.provider))];
      setCategories(uniqueCategories);
      setProviders(uniqueProviders);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts(); // Obtener los productos en el montaje inicial
  }, [fetchProducts]);

  // Función de filtrado
  useEffect(() => {
    const filterProducts = () => {
      const filtered = products.filter(product => {
        const categoryMatch = selectedCategory ? product.category === selectedCategory : true;
        const providerMatch = selectedProvider ? product.provider === selectedProvider : true;
        const searchTermMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase()); // Filtrar por nombre de producto
        return categoryMatch && providerMatch && searchTermMatch;
      });
      setFilteredProducts(filtered);
    };

    filterProducts(); // Ejecutar el filtro cuando cambien los términos
  }, [selectedCategory, selectedProvider, searchTerm, products]); // Depender de los valores que afectan el filtro

  // Manejo del botón para abrir la modal
  const handleButtonClick = (suggestedPrice, stock, name, prodID) => {
    setMostrarEnvios(true);
    setSuggestedPrice(suggestedPrice);
    setStock(stock);
    setName(name);
    setProdID(prodID);
  };

  const handleCloseModal = () => {
    setMostrarEnvios(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: 'ondemand',
  };

  const ProductCard = React.memo(({ product }) => (
    <div className="product-cardL">
      <div className="product-carousel">
        {product.images.length > 1 ? (
          <Slider {...settings}>
            {product.images.map((image, index) => (
              <div key={index}>
                <Link to={product.link}>
                  <img src={image} alt={product.name} loading="lazy" />
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <Link to={product.link}>
            <img src={product.images[0] || "/logo.png"} alt={product.name} loading="lazy" />
          </Link>
        )}
      </div>
      <div className="product-details">
        {/* Aquí se muestra la categoría y el id del producto */}
        <p className="category">{product.category} - id: {product.id}</p>
        <p className="stock">Stock <span>{product.stock}</span></p>
        <h3>{product.name}</h3>
        <p className="provider">Proveedor: <span>{product.provider}</span></p>
        <p className="supplier-price">Precio proveedor: ${product.supplierPrice.toLocaleString()}</p>
        <p className="suggested-price">Precio sugerido: <strong>${product.suggestedPrice.toLocaleString()}</strong></p>
      </div>
      <button className="add-to-cart-btn" onClick={() => handleButtonClick(product.suggestedPrice, product.stock, product.name, product.id)}>Enviar al cliente</button>
    </div>
  ));
  

  return (
    <>
      <NavbarDropshipping title="Dropshipping General" />
      <div className="landing-drop-container">
        <h1>Productos</h1>

        <div className="filters">
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Todas las categorías</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>

          <select value={selectedProvider} onChange={(e) => setSelectedProvider(e.target.value)}>
            <option value="">Todos los proveedores</option>
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </select>

          {/* Búsqueda por nombre de producto */}
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar por nombre"
            className="search-input"
          />
        </div>

        <div className="product-grid">
          {filteredProducts.map((product, index) => (
            <ProductCard
              key={product.id}
              product={product}
            />
          ))}
        </div>
        {isLoading && <p>Cargando productos...</p>}
      </div>
      {mostrarEnvios && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SendClientPage suggestedPrice={suggestedPrice} stock={stock} name={name} prodID={prodID} />
            <button className="close-modal-btn" onClick={handleCloseModal}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingDrop;
