import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './navbarDropshipping.css';

const NavbarDropshipping = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [userId, setUserId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const menuRef = useRef(null);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }

    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      const mobileView = window.innerWidth < 768;
      setIsMobile(mobileView);
      
      // Si la pantalla es mayor a 768px (vista normal), abrimos automáticamente el menú
      if (!mobileView) {
        setMenuOpen(true);
      }
    };

    // Detectar clic fuera de la barra de navegación solo en pantallas móviles
    const handleClickOutside = (event) => {
      if (isMobile && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Añadir el evento de clic fuera y redimensionamiento de la ventana
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    // Limpiar eventos al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  const nombre = localStorage.getItem('nombre');
  const rol_id = localStorage.getItem('rol_id');

  return (
    <>
      <div className="NavbarDropshipping-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="NavbarDropshipping-tittle">
          <p>{title}</p>
        </div>
        <div className="NavbarDropshipping-userInfo">
          <p>Hola, {nombre}</p>
          <p className="NavbarDropshipping-panelLink">99Panel</p>
          <div className="NavbarDropshipping-userAvatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`NavbarDropshipping-container ${isMenuOpen ? 'show' : 'hide'}`} ref={menuRef}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="NavbarDropshipping-title">
          <img src="/Images/download.png" alt="Download" className="NavbarDropshipping-logo" style={{ marginTop: '-5%' }} />
        </div>
        <div className="NavbarDropshipping-menu">
          <ul>
            <li>
              <NavLink to="/dashboardMex/" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">dashboard</span>Volver a Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/dropshippingGlobal" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">public</span>Dropshipping General
              </NavLink>
            </li>
            <li>
              <NavLink to="/masivoDrop" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">local_shipping</span>Pedidos Masivos
              </NavLink>
            </li>
            <li>
              <NavLink to="/misPedidos" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">shopping_cart</span>Envíos Dropshipping
              </NavLink>
            </li>
            <li>
              <NavLink to="/DevolucionForm" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">undo</span>Devolución
              </NavLink>
            </li>
            <li>
              <NavLink to="/sucursalPedidos/" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons">business</span>Órdenes y Envíos Bodega
              </NavLink>
            </li>
            {rol_id === '1' && (
              <li>
                <NavLink exact to="/dashboardAdmin" activeClassName="active">
                  <span className="material-icons">AdminPanelSettings</span>Dashboard Admin
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'NavbarDropshipping-active' : '')}>
                <span className="material-icons" onClick={handleLogout}>exit_to_app</span>Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavbarDropshipping;
